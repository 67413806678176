import React from 'react'
import {
  IconButton, Menu, MenuItem 
} from '@material-ui/core'
import { FlagIcon } from 'react-flag-kit'
import i18n, { languages } from '../i18n'
import { withNamespaces } from 'react-i18next'

const LanguageSelect = withNamespaces()( ({ t }) => {

  const [anchorEl, setAnchorEl] = React.useState(null)
  const menuOpen = Boolean(anchorEl)
  const currentLang = languages.find((lang) => lang.key === i18n.language)


  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const selectLang = (lang) => {
    i18n.changeLanguage(lang)
    localStorage.setItem('lang', lang)
    handleClose()
    
  }

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <FlagIcon
          code={currentLang.icon}
          size={36} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        {languages.map((lang) => {
          return (
            <MenuItem
              onClick={() => selectLang(lang.key)}
              key={lang.key}>
              <FlagIcon
                code={lang.icon}
                style={{ marginRight: 10 }}
                size={36} />
              {t('lang_' + lang.key)}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
})

export default LanguageSelect
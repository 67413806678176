import React, { useRef } from 'react'
import {
  inject, observer 
} from 'mobx-react'
import {
  Box, Typography, IconButton, makeStyles 
} from '@material-ui/core'
import {
  Facebook, LinkedIn, Instagram, GetApp, PlayCircleFilled, ArrowDownward 
} from '@material-ui/icons'
import {
  withNamespaces, Trans 
} from 'react-i18next'
import ActivityTypeIcon from '../components/ActivityTypeIcon'

const useStyles = makeStyles((theme) => ({
  backgroundImage1: {
    // height: '100vh',
    minHeight: '100vh',
    backgroundSize: 'cover',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundPosition: 'center',
    backgroundImage: 'url(' + require('../assets/walking.jpg') + ')'
  },
  background1Color: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: { backgroundColor: 'rgba(256,256,256,0.3)' } 
  },
  background1Container: { 
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row' ,
    flex: 1,
    padding: '0 10%',
    marginTop: 100,
  },
  bannerBackground: { 
    display: 'flex', 
    alignItems: 'center',
    flexDirection: 'column',
    padding: '60px 20px',
    backgroundColor: 'rgb(224, 224, 224)'
  },
  bannerTitle: {
    marginBottom: 60, 
    [theme.breakpoints.down('sm')]: {
      fontSize: '3em', marginBottom: 40 
    } 
  },
  bannerText: {
    textAlign: 'center', fontSize: '1.5em'
  },
  bannerColumn: {
    // width: '20%', 
    maxWidth: 300,
    display: 'flex', 
    flexDirection: 'column',
    alignItems: 'center'
  },
  evenRow: {
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column', alignItems: 'center' 
    }
  },
  centerColumn: {
    display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 3
  },
  centerRow: { 
    display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap'
  },
  imageTextBox: {
    maxWidth: 400, display: 'flex', flexDirection: 'column', justifyContent: 'center'
  },
  iphoneImage: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '50vh',
    width: 'auto',
    height: 'auto'
  },
  appStore: {
    width:185, height: 80, display: 'block', padding: 12
  },
  playStore: {
    width:200, height: 80, display: 'block' 
  },
}))

var Home = withNamespaces() ( inject('auth')(observer(({ t }) => {

  const classes = useStyles()
  const part1 = useRef(null)

  const goDown = () => {
    if (part1.current) {
      part1.current.scrollIntoView({
        behavior: 'smooth', block:'start' 
      })
    }
  }

  return(
    <Box>
      <Box
        className={classes.backgroundImage1}>
        <Box className={classes.background1Color}>
          <Box
            className={classes.background1Container}>
            <Box
              style={{ 
                flexDirection: 'column', justifyContent: 'center', display: 'flex', flex: 1
              }}>
              <Box
                style={{
                  maxWidth: 600, backgroundColor: '#ffffffcc', padding: 20, borderRadius: 10,
                }}>
                <Typography
                  variant="h3"
                  style={{ marginBottom: 30 }}>
                  {t('marketing_title')}
                </Typography>
                <Typography
                  variant="h5"
                  style={{ marginBottom: 20 }}>
                  <Trans i18nKey="marketing_subtitle">
                    <i>
                      App name
                    </i>
                    no text
                  </Trans>
                </Typography>
                <Box
                  style={{ 
                    display: 'flex', flexDirection: 'row', flexWrap: 'wrap'
                  }}>
                  <a
                    href="https://apps.apple.com/app/id1501330079"
                    className={classes.appStore}>
                    <img
                      src={require('../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg')}
                      style={{ width: '100%' }}/>
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.medotemic.medosport&hl=en"
                    className={classes.playStore}>
                    <img
                      src={require('../assets/google-play-badge.png')}
                      style={{ width: '100%' }} />
                  </a>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            style={{ 
              display: 'flex', flexDirection: 'row', justifyContent: 'center' 
            }}>
            <Box
              style={{ 
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 50, cursor: 'pointer'
              }}
              onClick={goDown}>
              <Typography
                variant="h5"
                style={{ marginBottom: 10 }}>
                {t('marketing_readMore')}
              </Typography>
              <ArrowDownward fontSize="large" />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        ref={part1}
        className={classes.bannerBackground}>
        <Typography
          variant="h2"
          className={classes.bannerTitle}>
          {t('marketing_step1_title')}
        </Typography>
        <Box
          className={classes.evenRow}>
          <Box
            className={classes.bannerColumn}>
            <GetApp
              style={{
                fontSize: '4em', marginBottom: 25
              }}/>
            <Typography
              className={classes.bannerText}>
              {t('marketing_step1_body_1')}
            </Typography>
          </Box>
          <Box
            className={classes.bannerColumn}>
            <PlayCircleFilled
              style={{
                fontSize: '4em', marginBottom: 25 
              }} />
            <Typography
              className={classes.bannerText}>
              {t('marketing_step1_body_2')}
            </Typography>
          </Box>
          <Box
            className={classes.bannerColumn}>
            {/* <DirectionsRun fontSize="large" /> */}
            <Box
              style={{
                float: 'left', alignSelf: 'center' 
              }}>
              <ActivityTypeIcon
                type="WALK"
                size={75} />
              <ActivityTypeIcon
                type="RUN"
                size={75} />
            </Box>
            <Typography className={classes.bannerText}>
              {t('marketing_step1_body_3')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        style={{ 
          // height: '100vh' ,
          backgroundImage: 'url(' + require('../assets/runner-small.jpg') + ')', 
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          display: 'flex',
          flexDirection: 'row'
        }} >
        <Box
          style={{ 
          // height: '100vh' ,
            flex: 1,
            // backgroundImage: 'url(' + require('../assets/stock-image-Runner-blur.jpg') + ')', 
            // backgroundSize: 'cover',
            display: 'flex',
            flexDirection: 'row',
            // backgroundColor: 'rgba(16, 60, 214,0.9)'
            // background: rgba(16,60,214, 0.9),
            background: 'linear-gradient(45deg, rgba(16,60,214,0.8) 0%, rgba(105,16,213,0.8) 100%)'

          }}>
          <Box
            className={classes.centerColumn}>
            <Box
              className={classes.centerRow}>
              <Box
                className={classes.imageTextBox}>
                <Box
                  style={{ padding: 30, }}>
                  <Typography
                    variant="h3"
                    style={{
                      marginBottom: 10, color: 'white' 
                    }}>
                    {t('marketing_step1_imageTitle')}
                  </Typography>
                  <Typography style={{ color: 'white' }}>
                    
                    <Trans i18nKey="marketing_step1_imageText">
                      part0
                      <br/>
                      <br/>
                      part 3
                    </Trans>
                  </Typography>
                </Box>
              </Box>
              <img
                className={classes.iphoneImage}
                src={require('../assets/iphone1.png')}/>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        className={classes.bannerBackground}>
        <Typography
          variant="h2"
          className={classes.bannerTitle}>
          {t('marketing_step2_title')}
        </Typography>
        <Box
          className={classes.evenRow}>
          <Box 
            className={classes.bannerColumn}>
            <Typography  className={classes.bannerText}>
              {t('marketing_step2_body_1')}
            </Typography>
          </Box>
          <Box
            className={classes.bannerColumn}>
            <Typography  className={classes.bannerText}>
              {t('marketing_step2_body_2')}
            </Typography>
          </Box>
          <Box
            className={classes.bannerColumn}>
            <Typography  className={classes.bannerText}>
              {t('marketing_step2_body_3')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        style={{ 
          backgroundImage: 'url(' + require('../assets/3-walking-small.jpg') + ')', 
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          flexDirection: 'row'
        }} >
        <Box
          style={{ 
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            background: 'linear-gradient(90deg, rgba(70,166,103,0.8) 0%, rgba(69,164,165,0.8) 100%)'
          }}>
          <Box
            className={classes.centerColumn}>
            <Box
              className={classes.centerRow}>
              <img
                className={classes.iphoneImage}
                src={require('../assets/iphone2.png')}/>
              <Box
                className={classes.imageTextBox}>
                <Box
                  style={{ padding: 30, }}>
                  <Typography
                    variant="h3"
                    style={{
                      marginBottom: 10, color: 'white' 
                    }}>
                    {t('marketing_step2_imageTitle1')}
                  </Typography>
                  <Typography style={{ color: 'white' }}>
                    <Trans i18nKey="marketing_step2_imageText1">
                      part0
                      <br/>
                      <br/>
                      part3
                      <br/>
                      <br/>
                      part6
                    </Trans>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              className={classes.centerRow}>
              <Box
                className={classes.imageTextBox}>
                <Box
                  style={{ padding: 30, }}>
                  <Typography
                    variant="h3"
                    style={{
                      marginBottom: 10, color: 'white' 
                    }}>
                    {t('marketing_step2_imageTitle2')}
                  </Typography>
                  <Typography style={{ color: 'white' }}>
                    <Trans i18nKey="marketing_step2_imageText2">
                      part0
                      <br/>
                      <br/>
                      part3
                    </Trans>
                  </Typography>
                </Box>
              </Box>
              <img
                className={classes.iphoneImage}
                src={require('../assets/iphone3.png')}/>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Footer */}
      <Box
        style={{ 
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '2% 5%', flexWrap: 'wrap'
        }}>
        <Box style={{ marginBottom: 20 }}>
          <Trans i18nKey="marketing_address">
            <Typography variant="h6">
              part0
            </Typography>
            <Typography>
              part1
            </Typography>
            <Typography>
              part2
            </Typography>
            <Typography>
              part3
            </Typography>
            <Typography>
              part4
            </Typography>
          </Trans>
        </Box>
        <Box style={{ marginBottom: 20 }}>
          <Trans i18nKey="marketing_contact">
            <Typography variant="h6">
              part0
            </Typography>
            <Typography>
              part1
            </Typography>
          </Trans>
        </Box>
        <Box>
          <IconButton href="https://www.facebook.com/Medotemic/">
            <Facebook fontSize="large" />
          </IconButton>
          <IconButton href="https://www.linkedin.com/company/medotemic-ab/">
            <LinkedIn fontSize="large"  />
          </IconButton>
          <IconButton href="https://www.instagram.com/medotemic/">
            <Instagram fontSize="large"  />
          </IconButton>
        </Box>
      </Box>
    </Box>
    // </BackgroundImage>
  )
})))

export default Home
import React from 'react'
import {
  inject, observer 
} from 'mobx-react'
import {
  Box, Typography, Container, Paper 
} from '@material-ui/core'
import { withNamespaces } from 'react-i18next'
import BackgroundImage from '../components/BackgroundImage'

const PrivacyPolicy = withNamespaces()(inject('config')(observer(({
  config, t 
}) => {


  config.fetchPrivacyPolicy().then(() => {
  })
  const renderPrivacy = () => {
    if (!config.privacyPolicy || config.privacyPolicy === '') {
      return null
    }
    return config.privacyPolicy.split('\n').map((item, i) => {
      return <p key={i}>
        {item}
      </p>
    })
  }


  return (
    <BackgroundImage>
      <Container maxWidth="md">
        <Paper
          style={{
            overflow: 'scroll', marginTop: 100, marginBottom: 50, padding: 20
          }}>
          <Box
            mb={2}
            textAlign="center"
            fontWeight="fontWeightBold">
            <Typography variant="h3" >
              {t('menu_terms')}
            </Typography>
          </Box>
          {renderPrivacy()}
        </Paper>
      </Container>
    </BackgroundImage>
  )
})))

export default PrivacyPolicy
import {
  observable, computed
} from 'mobx'
import auth from '../firebase'


export class AuthStore {
    @observable user = null;

    @observable email = ''
    @observable name = ''
    @observable created = null
    @observable photo = ''
    @observable authMethods = []

    @observable id = -1;
    @observable idToken = -1;
    @observable loading = true

    constructor() {
      // firebase.auth()
      auth().onAuthStateChanged(async (user) => {


        if (user) {
          // console.log(user)
          // console.log()

          // if (!user.emailVerified) {
          //   console.log('Email not verified')
          //   return
          // }


          // if (this.user) {
          //   if (this.user.uid !== user.uid) {
          //     // update



          //   }
          // }
          // console.log(user.uid)
          this.idToken = await auth().currentUser.getIdToken()
          this.email = user.email
          this.name = user.displayName
          this.created = user.metadata.creationTime
          this.photo = user.photoURL
          this.authMethods = await auth().fetchSignInMethodsForEmail(user.email)
          // this.idToken = ''
          await this.loginOnServer(this.idToken)
          // console.log(this.idToken)
        }

        this.user = user
        this.loading = false

        // console.log(userAuth.id)
        // if (!this.user || userAuth.id !== this.user.id) {
        //   //
        // }
        // this.user = userAuth
      })
      // auth().onIdTokenChanged(async (user) => {
      //   this.idToken = await user.getIdToken()
      // })
    }

    async getIdToken() {
      if (auth().currentUser) {
        return auth().currentUser.getIdToken()
      }
      return this.idToken
    }

    @computed get isLoggedIn() {
      return this.user !== null
    }

    @computed get isEmailVerified() {
      console.log(this.isLoggedIn)
      if (this.isLoggedIn) {
        console.log(this.user)
        console.log(this.isLoggedIn && this.user.emailVerified)
      }
      return this.isLoggedIn && this.user.emailVerified
    }

    async sendEmailVerification() {
      return this.user.sendEmailVerification()
    }

    async loginWithGoogle() {
      this.loading = true
      var provider = new auth.GoogleAuthProvider()
      return auth().signInWithPopup(provider).then(async (res) => {
        // this.loading = false
        return res
        // test login function f
        // this.loginOnServer()

      })
    }
    async loginWithApple() {
      var provider = new auth.OAuthProvider('apple.com')
      provider.addScope('email')
      provider.addScope('name')
      this.loading = true
      return auth().signInWithPopup(provider).then(async (res) => {
        // console.log(res)
        // this.loading = false
        return res

        // test login function f
        // this.loginOnServer()

      })
    }
    async loginWithEmailAndPassword(email, password) {
      this.loading = true
      return auth().signInWithEmailAndPassword(email, password).then(async (res) => {
        // console.log(res)
        // this.loading = false
        return res


        // test login function f
        // this.loginOnServer()

      })
    }

    async createEmailAndPasswordAccount(email, password) {
      this.loading = true
      const userCredentials = await auth().createUserWithEmailAndPassword(email, password)
      await auth().currentUser.sendEmailVerification()
      return userCredentials
    }

    resetPassword(email) {
      return auth().sendPasswordResetEmail(email).then((res) => {
        console.log(res)
        return res

      })
    }

    async loginOnServer(idToken) {
      const headers = {
        headers: {
        // authentication: await firebase.auth().currentUser.getIdToken(),
          language: 'sv',
          product: 'MedoSport',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ firebaseIdToken: idToken }),
        mode: 'cors'
      }
      // console.log('idToken', idToken)

      return fetch(`${process.env.REACT_APP_SERVER_URL}user/login`, headers).then( async (res) => { // eslint-disable-line no-undef
        let data = await res.json()
        // console.log('idToken', idToken, 'userID', data.id)

        this.id = data.id
        // this.id = 36
        // console.log(data.id)

        // this.id = 8
        return data.id
      })
    }

    signOut() {
      auth().signOut().then(() => {
        this.id = null
      })
    }

    changePassword(oldPassword, newPassword) {
      let user = auth().currentUser

      const credential = auth.EmailAuthProvider.credential(
        user.email,
        oldPassword
      )

      return user.reauthenticateWithCredential(credential).then(() => {
        user.updatePassword(newPassword)
      }).then(() => {
        return true
      })
    }

  // async linkWithApple() {
  //   const prevUser = auth().currentUser

  //   const newUser = await this.loginWithApple()
  //   // console.log(newUser)

  //   const idToken = await auth().currentUser.getIdToken()
  //   // const idToken = newUser.credential.idToken
  //   // console.log(idToken)

  //   const userID = await this.loginOnServer(idToken)
  //   const headers = { headers: { authentication: idToken, } }
  //   const fetchResult = await fetch(`${process.env.REACT_APP_SERVER_URL}/patient/${userID}/trainings`, headers)  // eslint-disable-line no-undef
  //   const trainings = await fetchResult.json()

  //   if (trainings.length <= 0) {
  //     // the apple account has no data, link them.
  //     console.log('the accounts can be linked.', prevUser, newUser)

  //     const res = await  newUser.user.linkWithCredential(prevUser.credential)
  //     console.log('linked res', res)


  //   } else {
  //     console.log('the accounts can NOT be linked.', prevUser, newUser)
  //   }
  //   // return this.loginWithApple((res) => {
  //   //   // console.log(res);
  //   //   // console.log(res.getIdToken());
  //   //   return res.getIdToken()
  //   // }).then((idToken) => {
  //   //   return this.loginOnServer(idToken)
  //   // }).then(() => {
  //   //   // try to fetch the trainings
  //   //   const headers = { headers: { authentication: this.authStore.idToken, } }

  //   // })
  // }

}

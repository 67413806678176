const { Paper } = require('@material-ui/core')
import React, {
  useEffect,
  useRef, useState 
} from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import exporting from 'highcharts/modules/exporting'
exporting(Highcharts)

import { formatDistance } from '../helper/formatting'

const findClosest = (distanceOverTime, targetTime) => {
  if (distanceOverTime[0][0] !== 0) {
    distanceOverTime.push([0,0])
  }
  for (let index = 1; index < distanceOverTime.length; index++) {
    // if (targetTime === 60) {
    // console.log(distanceOverTime[index][0], targetTime, distanceOverTime[index][0] === targetTime)
    // }
    let [t1, d1] = distanceOverTime[index]
    if (t1 > targetTime) {
      let [t0, d0] = distanceOverTime[index-1]
      return d0 + (targetTime - t0) * ((d1-d0)/(t1-t0))
    } else if (t1 === targetTime) {
      return d1
    }
  }
  return 0
}

const calculateSeries =  (series, isDistance, distanceOverTime) => {
  return series.map((serie) => {
    return serie.map((item) => {
      if (isDistance) {
        let closestItem = findClosest(distanceOverTime, item[0])
        if (closestItem) {
          return [closestItem, item[1]]
        } else {
        // console.log('undefined', config.distanceOverTime, item[0])
          return [undefined, undefined]
        }
      } else {
        return item
      }
    }).filter((item) => item[0] >= 0)
  })
}

const Graph = ({
  series, config, prepareForPrint
}) =>  {
  if (series.length <= 0 || !series[0]) {
    return null
  }

  // let source = ''
  const [source, setsource] = useState('')
  const chartRef = useRef(null)

  useEffect(() => {
    if (chartRef) {
      afterChartCreated()
    }
    // return () => {
    //   cleanup
    // }
  }, [prepareForPrint])


  const afterChartCreated = () => {
    // if(loaded) {
    //   return
    // }
    // setloaded(true)
    // console.log(chartRef.current.chart.width)
    // console.log('is loaded??', chartRef.current.chart)
    if (chartRef.current.chart?.chartWidth) {
      var svg = chartRef.current.chart.getSVG()
      // this.internalChart = chart;
      // console.log('after..')
      // var svg = chart.getSVG()
      var canvas = document.createElement('canvas')
      canvas.width = chartRef.current.chart.width
      canvas.height = chartRef.current.chart.height
      var ctx = canvas.getContext('2d')

      var img = document.createElement('img')

      img.onload = function() {
        ctx.drawImage(img, 0, 0)
        // callback(canvas.toDataURL('image/png'))
        setsource('data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svg))))
      }

      setsource('data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svg))))
      img.setAttribute('src', 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svg))))
    }
  }


  
  const [storedSeries, setStoredSeries] = useState(calculateSeries(series, config.distance, config.distanceOverTime) )
  useEffect(() => {
    setStoredSeries(calculateSeries(series, config.distance, config.distanceOverTime) )
  }, [series, config?.distance, config?.distanceOverTime])


  // find ymin and ymax
  const ymax = storedSeries.reduce((reducer, item) => {
    if (!item) return reducer
    
    return Math.max(reducer, item.reduce((r, i) => {
      if (!i[1]) return r
      return Math.max(r, i[1])
    }, 0))
  }, 0)

  const ymin = storedSeries.reduce((reducer, item) => {
    if (!item) return reducer
    return Math.min(reducer, item.reduce((r, i) => {
      if (!i[1]) return r
      return Math.min(r, i[1])
    }, 9999999))
  }, 9999999)

  // find xmax
  const xmax = storedSeries.reduce((reducer, item) => {
    if (!item) return reducer
    if (item.length < 2) return reducer
    // console.log(item[item.length-1], reducer)
    return Math.max(item.reduce((r, i) => {
      if (!i[0]) return r
      return Math.max(r, i[0])
    }, 0), reducer)
  }, 0)

  let yAxisFormatter = null
  let pointFormatter = null
  if (config.valueFormatter) {
    yAxisFormatter = function() {
      return config.valueFormatter(this.value)
    }
    pointFormatter = function() {
      return `<br/><span style="color:${this.series.color}">●</span> ${this.series.name}: <b>${config.valueFormatter(this.y)}</b></br>`
    }
  }

  const durationFormatter = ({ value }) => {
    // return '0min'
    if (value < 60) {
      return `${value}s`
    } else {
      const sec = Math.round(value%60)
      return `${Math.round(value/60)}:${sec >= 10 ? sec : '0' + sec}`
    }
  }

  const xAxisFormatter = config.distance ? ({ value }) => formatDistance(value, 'METRIC') : durationFormatter

  // let colors = ['rgb(114, 147, 203)', 'rgb(225, 151, 76)', 'rgb(132, 186, 91)', 'rgb(211, 94, 96)', 'rgb(128, 133, 133)', 'rgb(144, 103, 167)', 'rgb(171, 104, 87)', 'rgb(204, 194, 16)']
  let colors = ['rgb(57, 106, 177)', 'rgb(218, 124, 48)', 'rgb(62, 150, 81)', 'rgb(204, 37, 41)', 'rgb(83, 81, 84)', 'rgb(107, 76, 154)', 'rgb(146, 36, 40)', 'rgb(148, 139, 61)']
  if (config.colors) {
    colors = config.colors
  } 

  // console.log(xmax, series)


  return (
    <Paper
      style={{
        overflow: 'hidden',
        marginBottom: 20 
      }}>
      <HighchartsReact 
        ref={chartRef}
        highcharts={Highcharts}
        // callback={ afterChartCreated }
        options={{
          exporting: { enabled: false },
          title: { text: config.label },
          yAxis: {
            title: { text: config.yAxis },
            max: ymax,
            min: ymin,
            labels: { formatter: yAxisFormatter },
            minTickInterval: config.tickInterval || null,
          },
          xAxis: {
            title: { text: config.xAxis },
            min: 0,
            max: xmax,
            labels: { formatter: xAxisFormatter },
          },
          series: 
          storedSeries.map((serie, i) => {
            return {
              data: serie,
              color: colors[i%colors.length],
              name: config.labels && i < config.labels.length ? config.labels[i] : i,
              tooltip: { pointFormatter: pointFormatter },
                
            }}
          )
          ,
          legend: { enabled: storedSeries.length > 1 },
          chart: {
            zoomType: 'x',
            panning: true,
            tooltip: { shared: true },
            // backgroundColor:  {
            //   linearGradient: {
            //     x1: 0, x2: 0, y1: 0, y2: 1 
            //   },
            //   stops: [
            //     [0, '#ffffff'], // start
            //     [1, '#d6e0ee'],
            //     // [1, '#3366AA'] // end
            //     // [0, '#003399'], // start
            //     // [0.5, '#ffffff'], // middle
            //     // [1, '#3366AA'] // end
            //   ]
            // }
          },
          tooltip: {
            shared: true,
            positioner: function(lw, lh, point) {
              if (point.plotX < this.chart.chartWidth/2) {
                return {
                  x: this.chart.chartWidth-lw-50,
                  y: 10 
                }
              }
              return {
                x: 100,
                y: 10 
              }
            },
            // skipAnchor: true
            shape: 'square'
          },
          credits: false,
          plotOptions: { line: { marker: { enabled: false } } }
        }}
        containerProps={{ className: 'no-print' }}
      />
      <img
        src={source}
        style={{
          // backgroundColor: 'red', width: 200, height: 200 
          objectFit: 'contain',
          maxWidth: '100%',
          display: 'none'
        }}
        className="only-print"
      />
      {/* <Button onClick={afterChartCreated}>
        Image
      </Button> */}
    </Paper>
  )
}

export default Graph
// profile store is responsible for fetching and storing the users profile. THe profile could be extended in the future with edit functionality.

import {
  observable, action, runInAction 
} from 'mobx'

import i18next from '../i18n'

export class ConfigStore {
    @observable termsOfUse = '';
    @observable privacyPolicy = '';

    termsState = 0;
    privacyState = 0;

    constructor() {
      i18next.on('languageChanged', (lng) => {
        this.termsState = 0
        this.privacyState = 0
        this.fetchTerms(lng)
        this.fetchPrivacyPolicy(lng)
        console.log('fetch lang', lng)
      })
    }

    @action async fetchTerms(lang) {
      if (this.termsState !== 0) {
        return
      }
      this.termsState = 1

      if (!lang) {
        lang = i18next.language
      }
      
      console.log(lang)
      fetch(`${process.env.REACT_APP_SERVER_URL}config/termsOfUse/${lang}`).then(async (res) => { // eslint-disable-line no-undef
        // console.log(res)
        
        if (res.status < 300) {
          // console.log(await res.text())
          try {
            let data = await res.json()
            // console.log(data)
            runInAction(() => {
              this.termsOfUse = data.termsOfUse
              this.termsState = 2
            })
          } catch(e) {
            console.log('error parsing json for profile', e)
          }
        }
        
      })
    }

    @action async fetchPrivacyPolicy(lang) {
      if (this.privacyState !== 0) {
        return
      }
      this.privacyState = 1

      if (!lang) {
        lang = i18next.language
      }
      
      fetch(`${process.env.REACT_APP_SERVER_URL}config/privacyPolicy/${lang}`).then(async (res) => { // eslint-disable-line no-undef
        // console.log(res)
        
        if (res.status < 300) {
          // console.log(await res.text())
          try {
            let data = await res.json()
            // console.log(data)
            runInAction(() => {
              this.privacyPolicy = data.privacyPolicy
              this.privacyState = 2
            })
          } catch(e) {
            console.log('error parsing json for profile', e)
          }
        }
        
      })
    }
}
import { ProfileStore } from './profile'
import { TrainigStore } from './training'
import { ConfigStore } from './config'
import { GoalStore } from './goal'
import { FriendsStore } from './friends'
import { GroupsStore } from './groups'

const { AuthStore } = require('./auth')

class RootStore {
  constructor() {
    this.authStore = new AuthStore()
    this.profileStore = new ProfileStore(this.authStore)
    this.trainingStore = new TrainigStore(this.authStore, this.profileStore)
    this.goalStore = new GoalStore(this.authStore)
    this.friendsStore = new FriendsStore(this.authStore, this.profileStore)
    this.groupsStore = new GroupsStore(this.authStore)
    this.configStore = new ConfigStore()
  }
}

const stores = new RootStore()

export default {
  auth: stores.authStore,
  profile: stores.profileStore,
  trainings: stores.trainingStore,
  config: stores.configStore,
  goal: stores.goalStore,
  friends: stores.friendsStore,
  groups: stores.groupsStore,
}
// profile store is responsible for fetching and storing the users profile. THe profile could be extended in the future with edit functionality.

import JSZip from 'jszip'
import {
  computed,
  observable, observe, runInAction
} from 'mobx'
import { getZipData } from '../helper/training'

export class GroupsStore {
    @observable list = []
    @observable requests = []
    @observable feed = []

    @observable selectedTab = 0

    authStore = null
    hasFetchedFeed = false;

    constructor(authStore) {
      this.authStore = authStore

      observe(this.authStore, 'id', () => {
        // this.goals = {}
        // this.state = 0
        console.log('authstore change groups', this.authStore.id)
        if (this.authStore.id) {
          this.getGroups()
          this.getRequests()
        } else {
          this.hasFetchedFeed = false
          this.list = []
          this.requests = []
          this.feed = []
        }
      })

      setInterval(() => {
        if (this.authStore.id) {
          this.getRequests()
        }
      }, 20000) // 20 seconds
    }

    @computed get group() {
      return (groupId) => {
        const group = this.list.find((item) => item?.group?.id == groupId)
        if (group) {
          return group
        } else {
          // TODO: fetch group
          return {}
        }
      }
    }

    @computed get requestToAnswer() {
      return this.requests.filter((request) => request.patient.user.id === this.authStore.id)
    }

    @computed get relation() {
      return (groupId, userId) => {
        const group = this.group(groupId)
        // console.log(toJS(group))
        if (!group?.group?.members) {
          return {}
        }
        const relation = group.group.members.find((item) => item?.patient?.user?.id == userId)
        if (!relation) {
          return {}
        }
        return relation
      }
    }

    @computed get training() {
      return (groupId, trainingId) => {
        if (this.hasFetchedFeed == groupId) {
          return this.feed.find((item) => item.id == trainingId)
        } else {
          this.getFeed(groupId)
          return {}
        }
      }
    }

    async invite(groupId, email) {
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/group/${groupId}/invite`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: email || 'no_email'
      }).then(async (res) => {
        if (res.status < 300) {
          let data = await res.json()
          // this.requests = [...this.requests, data]
          // find group and add request
          const group = this.group(groupId)?.group
          if (group) {
            group.requests.push(data)
          }
        } else {
          if (res.text) {
            return res.text().then((value) => {
              throw value
            })
          }
        }
        return true
        
      }) 
    }

    async getRequests() {
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/group/invites`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'GET',
      }).then(async (res) => {
        let data = await res.json()
        console.log('getRequests response', data)
        this.requests = data
        return true
        
      }).catch((e) => {
        console.log('invites error', e)
      })
    }

    async createGroup(name, shareTrainings, shareLocation, shareReports) {
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/group/create`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({ 
          name, shareTrainings, shareLocation, shareReports
        })
      }).then(async (res) => {
        let data = await res.json()
        console.log('createGroup response', data)
        // this.requests = data
        this.list = [...this.list, data]
        this.getGroups()
        return true
        
      }).catch((e) => {
        console.log('invites error', e)
      })
    }

    async getGroups() {
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/group/all`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'GET',
      }).then(async (res) => {
        let data = await res.json()
        console.log('getGroups response', data)
        // this.requests = data
        // this.getGroup(data[2].group.id)
        this.list = data
        return true
        
      }).catch((e) => {
        console.log('invites error', e)
      })
    }

    async getGroup(groupId) {
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/group/${groupId}`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'GET',
      }).then(async (res) => {
        let data = await res.json()
        console.log('getGroup response', data)
        // this.requests = data
        // this.getGroup(data[0].group.id)
        // this.list = data
        return true
        
      }).catch((e) => {
        console.log('invites error', e)
      })
    }



    async getFeed(groupId) {
      if (this.hasFetchedFeed === groupId) {
        return
      }
      this.hasFetchedFeed = groupId
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/group/${groupId}/feed`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'GET',
      }).then(async (res) => {
        let data = await res.json()
        console.log('feed', data)
        this.feed = data.filter((item) => {
          return (item.fileStatus !== 'NOT_UPLOADED' && item.fileStatus !== 'FILE_NOT_FOUND')
        }).sort((a, b) => {
          if (a.time < b.time) {
            return 1
          }
          if (a.time > b.time) {
            return -1
          }
          // a must be equal to b
          return 0
        })
        // console.log(toJS(this.feed))
        // this.trainings = {}
        // this.feed.forEach((item) => {
        //   this.trainings[item.id] = {
        //     ...item,
        //     zipStatus: 0
        //   },
        //   this.getTraining(item.id).then(() => {
        //     console.log('training fetched')
        //   })
        // })
        return true
        
      }).catch((e) => {
        console.log('friends', e)
      })
    }

    async accept(inviteId, shareTrainings, shareLocation, shareReports) {
      console.log(inviteId, shareTrainings, shareLocation, shareReports)
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/group/${inviteId}/accept`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
          shareLocation: shareLocation,
          shareTrainings: shareTrainings,
          shareReports,
        })
      }).then(async (res) => {
        let data = await res.json()
        console.log('accept response', data)
        // this.requests = this.requests.filter((item) => item.id !== inviteId)
        this.list = [...this.list, data]
        this.requests = this.requests.filter((item) => item.id !== inviteId)
        return true
        
      }).catch((e) => {
        console.log('invites error', e)
      })
    }

    async decline(inviteId, groupId) {
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/group/${inviteId}/decline`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'POST',
      }).then(async (res) => {
        console.log('decline response', res)
        this.requests = this.requests.filter((item) => item.id !== inviteId)
        const group = this.group(groupId)?.group
        if (group) {
          group.requests = group.requests.filter((item) => item.id !== inviteId)
        }
        return true
        
      }).catch((e) => {
        console.log('invites error', e)
      })
    }

    async leave(groupId) {
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/group/${groupId}/leave`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'POST',
      }).then(async (res) => {
        console.log('decline response', res)
        this.list = this.list.filter((item) => item.group?.id != groupId)
        return true
        
      }).catch((e) => {
        console.log('invites error', e)
      })
    }

    async delete(groupId) {
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/group/${groupId}/delete`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'DELETE',
      }).then(async (res) => {
        console.log('delete response', res)
        this.list = this.list.filter((item) => item.group?.id != groupId)
        return true
        
      }).catch((e) => {
        console.log('invites error', e)
      })
    }

    async removeMember(groupId, memberId) {
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/group/${groupId}/removeMember/${memberId}`, { // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }).then(async (res) => {
        console.log('decline response', res)
        // this.list = this.list.filter((item) => item.group?.id != groupId);
        this.getGroups()
        return true
      }).catch((e) => {
        console.log('invites error', e)
      })
    }

    async rename(groupId, name) {
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/group/${groupId}/rename`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: name,
      }).then(async () => {
        this.list = [...this.list.map((item) => {
          if (item.group?.id == groupId) {
            return {
              ...item,
              group: {
                ...item.group,
                name: name
              }
            }
          }
          return item
        })]
        return true
        
      }).catch((e) => {
        console.log('invites error', e)
      })
    }

    async updateShare(relationId, shareTrainings, shareLocation, shareReports) {
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/group/${relationId}/share`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'PUT',
        body: JSON.stringify({
          shareLocation,
          shareTrainings,
          shareReports
        })
      }).then(async (res) => {
        console.log('share update response', res)
        let data = await res.json()
        this.list = this.list.map((item) => {
          if (item.id == relationId) {
            return data
          }
          return item
        })

        return true
        
      }).catch((e) => {
        console.log('invites error', e)
      })
    }

    async fetchTrainingData(groupId, trainingId) {

      const training = this.feed.find((item) => item.id == trainingId)
        
      if (!training) {
        await this.getFeed(groupId)
        if (!this.feed.find((item) => item.id == trainingId)) {
          return
        }
      }
      if (training.zipStatus > 0) {
        return
      }
  
      // training.zipStatus = 1
      this.feed = this.feed.map((item) => {
        if (item.id == trainingId) {
          return {
            ...item,
            zipStatus: 1,
          }
        }
        return item
      })

      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/group/${groupId}/training/${trainingId}`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'GET',
      })

      if (res.status >= 300) {
        return
      }

      const zipBlob = await res.blob()
      const zip = await JSZip.loadAsync(zipBlob)
  
      // training.zipStatus = 2
      this.feed = this.feed.map((item) => {
        if (item.id == trainingId) {
          return {
            ...item,
            zipStatus: 2,
          }
        }
        return item
      })
  
      const trainingZipData = await getZipData(training, zip, this.profileStore)
      // console.log(toJS(trainingZipData))
  
      runInAction(() => {
        // for(let result of results) {
        //   for (let item of result) {
        //     training[item.key] = item.data
        //   }
        // }
        // training.zipStatus = 3
        this.feed = this.feed.map((item) => {
          if (item.id == trainingId) {
            return {
              ...trainingZipData,
              zipStatus: 3,
            }
          }
          return item
        })
      })
      return this.feed.find((item) => item.id == trainingId)
    }
}

import React, { useState } from 'react'
import {
  inject, observer 
} from 'mobx-react'
import {
  Button, Tabs, Paper, Table, TableBody, TableRow, TableCell, Box, IconButton, Typography, TableContainer,
  Modal,
  darken,
  makeStyles,
  CircularProgress
} from '@material-ui/core'
// import { Button } from '@material-ui/core'
import TabPanel from '../components/TabPanel'
import {
  Email, Edit 
} from '@material-ui/icons'
import Alert from '@material-ui/lab/Alert'
import { withNamespaces } from 'react-i18next'
import InputField from '../components/InputField'
// import SelectButtons from '../components/SelectButtons'
import {
  formatDistance, fromatWeight, formatDate 
} from '../helper/formatting'
import StyledTab from '../components/StyledTab'
import EditProfileModal from '../components/EditProfileModal'
import * as Sentry from '@sentry/browser'

const TABS = {
  profile: 0,
  account: 1,
}

const useStyles = makeStyles(theme => ({
  deleteButton: {
    'margin-right': 10,
    'margin-left': 10,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': { backgroundColor: darken(theme.palette.error.main, 0.2), },
  }, 
  deleteLoading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    columnGap: 10,
  },
  deleteAlert: { marginBottom: 10, }
}))

var Profile =  withNamespaces()( inject('auth', 'profile')(observer(({
  profile, auth, t
}) => {

  const [tab, setTab] = useState(0)

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [oldPasswordError, setOldPasswordError] = useState('')
  const [newPasswordError, setNewPasswordError] = useState('')
  const [repeatPasswordError, setRepeatPasswordError] = useState('')
  const [editProfile, setEditProfile] = useState(false)
  const [success, setSuccess] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [deleteStatus, setDeleteStatus] = useState('') // loading, success, error

  const classes = useStyles()

  // request profile from store
  profile.fetchProfile().then(() => {
    // console.log(res)
    
  })


  const handleTabChange = (event, value) => {
    setTab(value)
  }

  const handleOldPassword = (event) => {
    setOldPassword(event.target.value)
  }

  const handleNewPassword = (event) => {
    setNewPassword(event.target.value)
  }

  const handleRepeatPassword = (event) => {
    setRepeatPassword(event.target.value)
  }

  const handleChangePassword = () => {
    setOldPasswordError('')
    setNewPasswordError('')
    setRepeatPasswordError('')
    setSuccess(false)

    if (oldPassword === '') {
      setOldPasswordError('Please enter your password')
      return
    }

    if (newPassword.length <= 6) {
      setNewPasswordError('too short')
      return
    }

    if (newPassword !== repeatPassword) {
      setNewPasswordError('Password missmatch')
      setRepeatPasswordError('Password missmatch')

      return
    }

    auth.changePassword(oldPassword, newPassword).then(() => {
      // console.log('success')
      setSuccess(true)
      // setTimeout(() => {
      //   setSuccess(false)
      // }, 6000)
      setOldPassword('')
      setNewPassword('')
      setRepeatPassword('')
      
        
    }).catch((e) => {
      console.log('error', e)
      if (e.code === 'auth/wrong-password') {
        setOldPasswordError(t('login_wrongPassword'))
      } else if (e.code === 'auth/too-many-requests') {
        setOldPasswordError(t('login_tooManyTries'))
      }
    })
  }

  // const linkWithApple = () => {
  // const prevUser = auth.user
  // auth.signInWithApple() {
  //   //success
  //   //check if there are any trainings. If there are, show warning and sign back in to the previous user
  // }
  //   auth.linkWithApple().then((res) => {
  //     console.log('res', res)
      
  //   }).catch((e) => {
  //     console.log('e', e)
  //   })
  // }

  const getProfileHeader = () => {
    if (auth.name && auth.name !== '') {
      return t('profile_headerWithName') + ' ' + auth.name
    }
    return t('profile_header')
  }

  const handleOpenEdit = () => {
    setEditProfile(true)
  }

  const getGenderString = () => {
    if (!profile.gender) {
      return 'Unknown'
    } else if (profile.gender === 'M') {
      return t('profile_male')
    } else if (profile.gender === 'F') {
      return t('profile_female')
    } else if (profile.gender === 'N') {
      return t('profile_otherGender')
    }
  }
  const getUnitsString = () => {
    if (profile.units === 'IMPERIAL') {
      return t('profile_imperial')
    }
    return t('profile_metric')
  }
  const getTimeFormatString = () => {
    if (profile.timeFormat === 'HOUR12') {
      return t('profile_12hour')
    }
    return t('profile_24hour')
  }
  const getDateFormatString = () => {
    if (profile.dateFormat === 'DMY') {
      return '31-12-2020' 
    } else if (profile.dateFormat === 'MDY') {
      return '12-31-2020'
    }
    return '2020-12-31'
  }

  const removeAccount = async () => {
    setDeleteStatus('loading')
    fetch(`${process.env.REACT_APP_SERVER_URL}patient/${auth.id}`, {
      headers: {
        authentication: await auth.getIdToken(),
        'Content-Type': 'application/json'
      },
      method: 'DELETE'
    },).then(() => {
      setDeleteStatus('success')
      setTimeout(() => {
        auth.signOut()
      }, 3000)
    }).catch((e) => {
      Sentry.captureException(e)
      setDeleteStatus('error')
    })
  }
  
  return(
    <>
      <Paper
        style={{
          marginTop: 20, marginBottom: 20 
        }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          aria-label="simple tabs example">
          <StyledTab
            label={t('menu_profile')}/>
          <StyledTab
            label={t('menu_account')}/>
          {/* <StyledTab
            label={t('menu_connections')}/> */}
        </Tabs>
      </Paper>
      <Paper style={{ padding: 20 }}>
        <TabPanel
          value={tab}
          index={0}>
          <h1>
            {getProfileHeader()}
            <IconButton onClick={handleOpenEdit}>
              <Edit />
            </IconButton>
          </h1>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>
                    {t('Profile_nameinreport')}
                  </TableCell>
                  {!!profile.name && <TableCell>
                    {profile.name}
                  </TableCell>}
                  {!profile.name && <TableCell>
                    <p
                      style={{
                        fontStyle: 'italic', padding: 0, margin: 0, color: '#777'
                      }}>
                      {t('Profile_nameinreport')}
                    </p>
                  </TableCell>}
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t('profile_length')}
                  </TableCell>
                  <TableCell>
                    {formatDistance( profile.length/100, profile.units)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t('profile_weight')}
                  </TableCell>
                  <TableCell>
                    {fromatWeight( profile.weight || 0, profile.units)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t('profile_birthday')}
                  </TableCell>
                  <TableCell>
                    {formatDate( profile.birthday, profile.dateFormat, profile.timeFormat, true)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t('profile_gender')}
                  </TableCell>
                  <TableCell>
                    {getGenderString()}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t('profile_email')}
                  </TableCell>
                  <TableCell>
                    {auth.email}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t('profile_units')}
                  </TableCell>
                  <TableCell>
                    {getUnitsString()}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t('profile_timeFormat')}
                  </TableCell>
                  <TableCell>
                    {getTimeFormatString()}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t('profile_dateFormat')}
                  </TableCell>
                  <TableCell>
                    {getDateFormatString()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel 
          value={tab}
          index={1}>

          <h1>
            {t('menu_account')}
          </h1>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>
                  {t('profile_authMethods')}
                </TableCell>
                <TableCell>
                  {auth.authMethods.map((item) => {
                    if (item === 'apple.com') {
                      return (
                        <img
                          key="apple"
                          src={require('../assets/apple_signin/2x/White Logo Square@2x.png')}
                          style={{ height: 50 }}/>
                      )
                    } else if (item === 'google.com') {
                      return (
                        <img
                          key="google"
                          src={require('../assets/google signin/btn_google_light_normal_ios-01.png')}
                          style={{ height: 50 }}/>
                      )
                    }
                    return <Email
                      style={{
                        height: 50, marginLeft: 10, marginRight: 10 
                      }}
                      key="email" />
                  })}
                </TableCell>
              </TableRow>
              
              {/* <TableRow>
                <TableCell>
                  Link accounts
                </TableCell>
                <TableCell style={{ padding: 20 }}>
                  {!auth.authMethods.includes('apple.com') && 
                  <AppleButton onClick={linkWithApple} />}
                  {!auth.authMethods.includes('google.com') && 
                  <GoogleButton />}
                  {!auth.authMethods.includes('password') && 
                  <Button variant="contained">
                    <Email key="email" />
                  </Button>}
                </TableCell>
              </TableRow> */}
            </TableBody>
          </Table>
          {auth.authMethods.includes('password') && (
            <>
              {
                success && (<Alert severity="success">
                  {t('profile_passwordUpdated')}
                </Alert>)
              }
              <Box
                style={{ 
                  display: 'flex', flex: 1 , flexDirection: 'row', flexWrap: 'wrap'
                }}>
                <Box
                  style={{ 
                    display: 'flex', flex: 1 
                  }}>
                  <Typography
                    style={{ 
                      padding: 15, fontSize: '1em' 
                    }}>
                    {t('profile_changePassword')}
                  </Typography>
                </Box>
                <Box
                  style={{ 
                    display: 'flex', flex: 1, flexDirection: 'column', minWidth: 200
                  }}>
                  {/* <Box
                        flexDirection="column"
                        display="flex"> */}
                  <InputField
                    id="standard-basic"
                    label={t('profile_oldPassword')}
                    type="password"
                    placeholder={t('profile_oldPassword')}
                    small
                    errorMessage={oldPasswordError}
                    onChange={handleOldPassword}
                    value={oldPassword} />
                  <InputField
                    id="standard-basic"
                    label={t('profile_newPassword')}
                    small
                    type="password"
                    placeholder={t('profile_newPassword')}
                    onChange={handleNewPassword}
                    errorMessage={newPasswordError}
                    value={newPassword} />
                  <InputField
                    id="standard-basic"
                    label={t('profile_repeatPassword')}
                    type="password"
                    small
                    placeholder={t('profile_repeatPassword')}
                    errorMessage={repeatPasswordError}
                    onChange={handleRepeatPassword}
                    value={repeatPassword} />
                  <Button
                    onClick={handleChangePassword}
                    variant="contained"
                    style={{
                      margin: 'auto', marginTop: 10, marginBottom: 10, 
                    }}>
                    {t('profile_changePassword')}
                  </Button>
                  {/* </Box> */}
                </Box>
              </Box>
            </>
          )
          }
        </TabPanel>
        {/* <TabPanel
          value={tab}
          index={2}>

          <p>
            Share:
            {' '}
            {auth.id}
            :
            {auth.idToken}
          </p>
        </TabPanel> */}
      </Paper>
      {tab === TABS.account && (<Paper
        style={{
          padding: 20, marginTop: 20 
        }}>
        <h2>
          {t('profile_deleteAccountHeader')}
        </h2>
        <p>
          {t('profile_deleteAccountBody')}
        </p>
        <Button
          variant="contained"
          color="default"
          onClick={() => {setShowConfirmDelete(true)}}>
          {t('profile_deleteAccountButton')}
        </Button>
      </Paper>)}
      <EditProfileModal
        open={editProfile}
        onClose={() => setEditProfile(false)}
        profile={profile} />
      <Modal
        open={showConfirmDelete}
        onClose={() => setShowConfirmDelete(false)}
        style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}>
        <Paper
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 10,
          }}>
          <h2>
            {t('profile_deleteAccountModalBody', [auth.email])}
          </h2>
          {deleteStatus === 'success' && (
            <Alert
              severity="success"
              className={classes.deleteAlert}>
              {t('profile_deleteAccountSuccess')}
            </Alert>
          )}
          {deleteStatus === 'error' && (
            <Alert severity="error">
              {t('profile_deleteAccountError')}
            </Alert>
          )}
          {deleteStatus  === 'loading' && (
            <p className={classes.deleteLoading}>
              {t('profile_deleteAccountLoading')}
              <CircularProgress size="28px" />
            </p>
          )}
          {deleteStatus !== 'loading' && (<>
            <Button
              disabled={deleteStatus === 'success'}
              onClick={() => setShowConfirmDelete(false)}
              variant="contained">
              {t('profile_deleteAccountModalCancel')}
            </Button>
            <Button
              disabled={deleteStatus === 'success'}
              onClick={removeAccount}
              className={classes.deleteButton}>
              {t('profile_deleteAccountModalConfirm')}
            </Button>
          </>)}
        </Paper>
      </Modal>
    </>
  )
})))

export default Profile

import React from 'react'

const AppleButton = ({
  onClick, label, disabled
}) => {
  return (
    <div
      style={{
        width: 240,
        backgroundColor: 'black',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 5,
        justifyContent: 'center',
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
      onClick={onClick}>
      <img
        src={require('../assets/apple_signin/2x/White Logo Square@2x.png')}
        style={{ height: 50 }}/>
      <p style={{ color: 'white' }}>
        {label}
      </p>
    </div>
  )
}

export default AppleButton
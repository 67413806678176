import React, {
  useEffect, useState
} from 'react'
import {
  inject, observer
} from 'mobx-react'
import {
  Paper, TableRow, Table, TableBody, TableCell, withStyles, Box, IconButton, Typography, Tooltip, Switch, Button
} from '@material-ui/core'
import {
// useParams, useHistory
} from 'react-router-dom'
import {
  formatSpeed, formatCadence, formatForce, formatDistance, formatSteps, formatDuration, formatPercentage, formatPace, formatDistanceLowPrecision, formatDate
} from '../helper/formatting'
import { SpeedFormats } from '../helper/constants'
import ActivityTypeIcon from '../components/ActivityTypeIcon'
import MoodIcon from '../components/MoodIcon'
import { withNamespaces } from 'react-i18next'
import {
  GoogleMap, LoadScript , Polyline
} from '@react-google-maps/api'
import PaceTable from '../components/PaceTable'
import {
  ChevronLeft, Delete, HelpRounded, Print
} from '@material-ui/icons'
import RunningGraphs from '../components/graphs/running'
import WalkingGraphs from '../components/graphs/walking'
import { toJS } from 'mobx'
import GenerateReportModal from './GenerateReportModal'

const containerStyle = {
  // maxWidth: '400px',
  // maxHeight: '400px'
  width: '100%',
  height: '400px',
  minWidth: 250
}

// const center = {
//   lat: -3.745,
//   lng: -38.523
// }

var TrainingComponent = withNamespaces()(inject('profile')(observer(({
  training, t, profile, deleteTraining, goBack, userId='', groupId='', reportEnabled=false,
}) => {


  const StyledTableRow = withStyles((theme) => ({ root: { '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover, }, }, }))(TableRow)
  // const history = useHistory()
  const [isDistance, setisDistance] = useState(false)
  const [showReport, setShowReport] = useState(false)

  useEffect(() => {
    console.log(toJS(training))

  }, [training])


  let stanceTimeLeft = training.stanceTimeLeft / (training.stanceTimeLeft + training.stanceTimeRight)
  let swingTimeLeft = training.swingTimeLeft / (training.swingTimeLeft + training.swingTimeRight)
  let strideTimeLeft = training.strideTimeLeft / (training.strideTimeLeft + training.strideTimeRight)
  let stepTimeLeft = training.stepTimeLeft / (training.stepTimeLeft + training.stepTimeRight)

  let assymmetriSum = Math.abs(0.5-stanceTimeLeft) + Math.abs(0.5-swingTimeLeft) + Math.abs(0.5-stepTimeLeft)
  // console.log(Math.abs(0.5-stanceTimeLeft), Math.abs(0.5-swingTimeLeft),  Math.abs(0.5-stepTimeLeft), assymmetriSum)

  const tableDataWalking = [
    ['ID#', training.id.toString()],
    [t('training_mood'), (<MoodIcon
      key="mood"
      width={25}
      mood={training.mood} />)],
    [t('training_activeTime'), formatDuration( training.activeTime)],
    [t('training_steps'), formatSteps( training.steps)],
    [t('training_distance'), formatDistance(training.totalDistance, profile.units)],
    [t('training_cadence'), formatCadence(training.cadence)],
    [t('training_avgSpeed'), formatSpeed(training.avgSpeed, SpeedFormats.KILOMETER_HOUR, profile.units)],
    [t('training_ascentDescent'), `${formatDistanceLowPrecision(training.altUp, profile.units)}/${formatDistanceLowPrecision(training.altDown, profile.units)}`],
    [t('training_averageStepLength'), formatDistance( training.avgStepLength, profile.units)],
    // missing steplength left right
    [t('training_stanceTime'), `${formatDuration((training.stanceTimeLeft + training.stanceTimeRight)/2)}`],
    [t('training_stanceTimeLeftRight'), `${formatPercentage(stanceTimeLeft, true)}/${formatPercentage(1 - stanceTimeLeft, true)}`],
    [t('training_swingTime'), `${formatDuration((training.swingTimeLeft + training.swingTimeRight)/2)}`],
    [t('training_swingTimeLeftRight'), `${formatPercentage(swingTimeLeft, true)}/${formatPercentage(1 - swingTimeLeft, true)}`],
    [t('training_stepTime'), `${formatDuration((training.stepTimeLeft + training.stepTimeRight) /2)}`],
    [t('training_stepTimeLeftRight'), `${formatPercentage(stepTimeLeft, true)}/${formatPercentage(1 - stepTimeLeft, true)}`],
    [t('training_strideTime'), formatDuration(training.strideTime)],
    [t('training_strideTimeLeftRight'), `${formatPercentage(strideTimeLeft, true)}/${formatPercentage(1 - strideTimeLeft, true)}`],
    // missing stride length
    [t('training_strideLength'), formatDistance((training.totalDistance/training.steps) * 2, profile.units)],
    [t('training_averageMaxForce'), formatForce(training.maxForce)],
    [t('training_6min'), training.best6min ? formatDistance(training.best6min) : t('training_no6min')],
    // ['Assymmetri stegtid', formatPercentage(Math.abs(0.5-stepTimeLeft) * 2, true)],
    ['Assymmetri summa', formatPercentage(assymmetriSum, true)],
  ]

  const tableDataRunning = [
    [t('training_mood'), (<MoodIcon
      key="mood"
      width={25}
      mood={training.mood} />)],
    [t('training_activeTime'), formatDuration( training.activeTime)],
    [t('training_steps'), formatSteps( training.steps)],
    [t('training_distance'), formatDistance(training.totalDistance, profile.units)],
    [t('training_cadence'), formatCadence(training.cadence)],
    [t('training_averagePace'), formatPace(training.activeTime/training.totalDistance, profile.units)],
    [t('training_avgSpeed'), formatSpeed(training.avgSpeed, SpeedFormats.KILOMETER_HOUR, profile.units)],
    [t('training_ascentDescent'), `${formatDistanceLowPrecision(training.altUp, profile.units)}/${formatDistanceLowPrecision(training.altDown, profile.units)}`],
    [t('training_averageStepLength'), formatDistance( training.avgStepLength, profile.units)],
    [t('training_timeInAirAverage'), formatDuration(training.averageTimeInAir)],
    [t('training_timeOnGround'), formatDuration(training.averageGroundContactTime)],
    [t('training_averageMaxForce'), formatForce(training.maxForce)],
    [t('training_cooperTest'), training.cooperValue ? `${training.cooperDistance.toFixed(0)}m/${formatSteps(training.cooperValue)}/${t(training.cooperRes)}` : t('training_noCooper'), t('training_tooltip_cooper')],
  ]

  const tableData = training.activityType === 'WALK' ? tableDataWalking : tableDataRunning


  const onLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds()
    if (training.posData) {
      training.posData.map((result) => {
        bounds.extend(new window.google.maps.LatLng(
          result.lat,
          result.lng
        ))
      })
      map.fitBounds(bounds)
    }
  }

  const generateReport = () => {
    setShowReport(true)
  }

  return(
    <>
      <div >
        <Box
          style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
          }}>
          <Box
            style={{
              display: 'flex', flexDirection: 'row', alignItems: 'center'
            }}>
            <IconButton
              onClick={goBack}
              // variant="contained"
              size="medium"
              style={{
                position: 'relative', top: 0, left: 0
              }}>
              <ChevronLeft />
            </IconButton>
            <h1 style={{ marginRight: 10 }}>
              {formatDate(training.time, profile.dateFormat, profile.timeFormat)}
            </h1>
            <ActivityTypeIcon
              type={training.activityType}/>
          </Box>

          <div>
            <Tooltip
              title={reportEnabled ? '' : t('report_notShared')}>
              <span style={{ display: 'inline-block' }}>
                <Button
                  onClick={generateReport}
                  variant="contained"
                  disabled={!reportEnabled}
                  color="primary">
                  {t('report_button')}
                </Button>
              </span>
            </Tooltip>
            <IconButton onClick={() => window.print()}>
              <Print fontSize="large" />
            </IconButton>
            {!!deleteTraining && <IconButton onClick={deleteTraining}>
              <Delete fontSize="large" />
            </IconButton>}
          </div>
        </Box>
        <Paper style={{ marginBottom: 20,  }}>
          <Table size="small">
            <TableBody>
              {
                tableData.map((item, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell>
                        <Typography
                          style={{
                            float:'left', fontSize: '0.875rem'
                          }}>
                          {item[0]}
                        </Typography>
                        { item.length > 2 &&
                          <Tooltip
                            arrow
                            title={item[2]}
                            placement="bottom-start">
                            <HelpRounded
                              style={{
                                float:'left', marginLeft: 10 , fontSize: '1.25rem', cursor: 'pointer'
                              }} />
                          </Tooltip>
                        }

                      </TableCell>

                      <TableCell>
                        {item[1]}
                      </TableCell>
                    </StyledTableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </Paper>

        <Box
          display="flex"
          flexDirection="row"
          style={{
            marginBottom: 20, marginTop: 20, flexWrap: 'wrap'
          }}>
          { training.posData !== null && training.posData !== undefined &&
          <Box
            style={{
              flex: 1, marginRight: 20
            }}>
            <LoadScript
              googleMapsApiKey="AIzaSyDnR2KcXaLkB57PA5xh9fYibZyuoEUCU8s"
            >
              <GoogleMap
                mapContainerStyle={containerStyle}
                onLoad={onLoad}
              >
                <>
                  <Polyline
                    path={training.posData} />
                </>
              </GoogleMap>
            </LoadScript>
          </Box>
          }
          <Box
            style={{
              display: 'flex', flex: 1, maxWidth: '100%'
            }}>
            <PaceTable
              kmData={training.kmData}
              t={t}
              units={profile.units} />
          </Box>


        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between">
          <Typography style={{ marginBottom: 10 }}>
            {t('training_hoverOnGraph')}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center">
            <Typography style={{ marginBottom: 0 }}>
              {t('graph_time')}
            </Typography>
            <Switch
              value={isDistance}
              onChange={(event) => setisDistance(event.target.checked)} />
            <Typography style={{ marginBottom: 0 }}>
              {t('training_distance')}
            </Typography>
          </Box>
        </Box>
        <div>
          <RunningGraphs
            isDistance={isDistance}
            trainings={[training]}
            profile={profile}
            t={t} />
          <WalkingGraphs
            isDistance={isDistance}
            trainings={[training]}
            profile={profile}
            t={t} />
        </div>
      </div>
      <GenerateReportModal
        open={showReport}
        onClose={() => setShowReport(false)}
        userId={userId}
        groupId={groupId} />
    </>
  )
})))

export default TrainingComponent

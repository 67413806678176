import { formatDate } from './formatting'


// const days =  ['Monday', 'Tuseday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

export function reducePerDay(trainingsByDay, reducer) {
  if (!trainingsByDay) {
    return
  }
  const today = new Date(formatDate(new Date(), 'YMD', '', true))
  today.setHours(2,0,0,0)
  let res = []
  for (let i = 0; i < 7; i++) {
    let trainings = []
    let day = new Date(new Date().setDate(today.getDate()-i))
    day.setHours(2,0,0,0)
    
    if (trainingsByDay[i]) {
      trainings.push(...trainingsByDay[i])
    }

    // let val = trainings.reduce(reducer, startVal)
    let val = reducer(trainings)
    // console.log(day, val)
    
    res.push([day.getDay(), val, day])
  }
  
  return res.reverse()
}

export function reducePerWeek(trainingsByDay, reducer) {
  if (!trainingsByDay) {
    return
  }

  let res = []
  for (let i = 0; i < 4; i++) {
    let trainings = []
    let day = new Date(new Date().setDate(new Date().getDate()-(i*7)))
    let startDay = formatDate(day, 'YMD', '', true)
    day.setHours(12,0,0,0)
    for (let j = 0; j < 7; j++) {
      day = new Date(new Date().setDate(new Date().getDate()-(i*7 + j)))
      day.setHours(12,0,0,0)
      if (trainingsByDay[i*7+j]) {
        trainings.push(...trainingsByDay[i*7+j])
      }
    }

    // let val = trainings.reduce(reducer, startVal)
    let val = reducer(trainings)
    res.push([startDay, val])
  }
  return res.reverse()
}
export function reducePerMonth(trainingsByDay, reducer, numberOfMonths = 6) {
  if (!trainingsByDay) {
    return
  }
  let res = []
  let daysPast = 0
  let day = new Date(new Date().setDate(new Date().getDate()-daysPast))
  let pastMonths = 0
  let currentMonth = day.getMonth()
  let trainings = []
  let startDay = formatDate(day, 'YMD', '', true).substr(0, 7)

  while(pastMonths < numberOfMonths) {
    day = new Date(new Date().setDate(new Date().getDate()-daysPast))
    if (day.getMonth() === currentMonth) {
      // check if i should add
      if (trainingsByDay[daysPast]) {
        trainings.push(...trainingsByDay[daysPast])
      }
    } else {
      let val = reducer(trainings)
      currentMonth = day.getMonth()
      res.push([startDay, val])
      trainings = []
      if (trainingsByDay[daysPast]) {
        trainings.push(...trainingsByDay[daysPast])
      }
      startDay = formatDate(day, 'YMD', '', true).substr(0, 7)
      pastMonths += 1
    }
    daysPast += 1
  }
  
  return res.reverse()
}

export function getMonthReducer(numberOfMonths) {
  return (trainingsByDay, reducer) => reducePerMonth(trainingsByDay, reducer, numberOfMonths)
}

export const trainingsReducer = (trainings) => {
  return trainings.length
}

export const activeTimeReducer = (trainings) => {
  return trainings.reduce((reducer, item) => {
    return reducer + item.activeTime
  }, 0) * 1000
}

export const speedReducer = (trainings) => {
  return trainings.reduce((reducer, item) => {
    return reducer + item.avgSpeed
  }, 0)/trainings.length * 3.6
}
export const stepsReducer = (trainings) => {
  return trainings.reduce((reducer, item) => {
    return reducer + item.steps
  }, 0)
}

export const stanceTimeLeftReducer = (trainings) => {

  trainings = trainings.filter((item) => item.stanceTimeLeft !== 0)
  return trainings.reduce((reducer, item) => {
    return reducer + (item.stanceTimeLeft/(item.stanceTimeLeft + item.stanceTimeRight))
  }, 0) / trainings.length
}

export const swingTimeLeftReducer = (trainings) => {

  trainings = trainings.filter((item) => item.swingTimeLeft !== 0)
  return trainings.reduce((reducer, item) => {
    return reducer + (item.swingTimeLeft/(item.swingTimeLeft + item.swingTimeRight))
  }, 0) / trainings.length
}

export const asymmetryReducer = (trainings) => {
  trainings = trainings.filter((item) => item.asymmetry !== 0)
  return trainings.reduce((reducer, item) => {
    const stanceTimeLeft = item.stanceTimeLeft / (item.stanceTimeLeft + item.stanceTimeRight)
    const swingTimeLeft = item.swingTimeLeft / (item.swingTimeLeft + item.swingTimeRight)
    const stepTimeLeft = item.stepTimeLeft / (item.stepTimeLeft + item.stepTimeRight)

    let asymmetry = Math.abs(0.5-stanceTimeLeft) + Math.abs(0.5-swingTimeLeft) + Math.abs(0.5-stepTimeLeft)

    return reducer + asymmetry
  }, 0) / trainings.length
}

export const best6Reducer = (trainings) => {
  trainings = trainings.filter((item) => item.best6 !== 0)
  return trainings.reduce((reducer, item) => {
    return reducer + item.best6
  }, 0) / trainings.length
}

export const trainingCountReducer = (trainings) => {
  return trainings.length
}
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell, TableContainer, TableHead, TableRow, TextField, Typography 
} from '@material-ui/core'
import {
  Assessment,
  Clear, Delete, List, LocationOn
} from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import {
  inject, observer 
} from 'mobx-react'
import React, { useState } from 'react'
import { withNamespaces } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ConfirmModal from '../../components/ConfirmModal'
import Permissions from '../../components/Permissions'
import { getName } from '../../stores/friends'

const Users = withNamespaces()( inject('groups', 'auth', 'friends')(observer(({
  groups, t
}) => {

  const [email, setemail] = useState('')
  const [selectedRequest, setSelectedRequest] = useState(null)
  const [confirmDecline, setConfirmDecline] = useState(false)
  const [confirmRemoveMember, setConfirmRemoveMember] = useState(false)
  const [selectedMember, setSelectedMember] = useState(null)
  const [memberRemoved, setMemberRemoved] = useState(false)
  const [inviteRemoved, setInviteRemoved] = useState(false)
  const [inviteError, setInviteError] = useState('')
  const [inviteSent, setInviteSent] = useState(false)
  // const [shareTrainings, setShareTrainings] = useState(true)
  // const [sharePosition, setSharePosition] = useState(false)


  const { groupId } = useParams()
  const groupRelation = groups.group(groupId)
  const group = groupRelation?.group
  const isAdmin = groupRelation.isAdmin

  const declineRequest = () => {
    // if (window.confirm(t('decline_request'))) {
    setConfirmDecline(false)
    groups.decline(selectedRequest.id, groupId).then(() => {
      setInviteRemoved(true)
      setTimeout(() => {
        setInviteRemoved(false)
      }, 3000)
    })
    // }
  }

  const removeMemberFromGroup = () => {
    setConfirmRemoveMember(false)
    groups.removeMember(groupId, selectedMember?.patient?.user?.id).then(() => {
      setMemberRemoved(true)
      setTimeout(() => {
        setMemberRemoved(false)
      }, 3000)
    })
  }

  const handleEmailChange = (event) => {
    setemail(event.target.value)
  }

  const invite = () => {
    groups.invite(groupId, email).then(() => {
      setInviteSent(true)
      setemail('')
      setTimeout(() => {
        setInviteSent(false)
      }, 3000)
    }).catch((e) => {
      // console.log('invite error', e)
      if (e === 'NOT_FOUND') {
        setInviteError(t('friend_notFound'))
      } else if (e === 'ALREADY_MEMBER') {
        setInviteError(t('friend_alreadyMember'))
      } else if (e === 'ALREADY_INVITED') {
        setInviteError(t('friend_alreadyInvited'))
      }
    })
  }

  const handleLocationChange = () => {
    groups.updateShare(groupRelation.id, groupRelation.shareTrainings, !groupRelation.shareLocation, groupRelation.shareReports)
  }

  const handleTrainingsChange = () => {
    groups.updateShare(groupRelation.id, !groupRelation.shareTrainings, groupRelation.shareLocation, groupRelation.shareReports)
  }
  
  const handleShareReportsChange = () => {
    groups.updateShare(groupRelation.id, groupRelation.shareTrainings, groupRelation.shareLocation, !groupRelation.shareReports)
  }

  const renderRequests = () => {
    if (group.requests?.length <= 0 || !group?.requests) {
      return null
    }

    return <>
      <h2>
        {t('friend_requests')}
      </h2>

      {inviteRemoved && <Alert severity="success">
        {t('friend_inviteRemoved')}
      </Alert>}
      <ConfirmModal
        isOpen={confirmDecline}
        onClose={() => setConfirmDecline(false)}
        onAccept={() => declineRequest()}
        acceptLabel={t('friend_confirm')}
        label={t('wantRemoveInvite', { email: getName(selectedRequest?.patient) }) } />
      <TableContainer style={{ marginBottom: 20 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                {t('friend_name')}
              </TableCell>
              <TableCell
                align="right">
                {t('friend_accept')}
              </TableCell>
            </TableRow>

          </TableHead>
          <TableBody>
            {(group?.requests || []).map((request) => {
              return (
                <TableRow
                  key={request.id}>
                  <TableCell>
                    {getName(request.patient)}
                  </TableCell>
                  <TableCell
                    style={{ 
                      fontWeight: 'bold', fontSize: '1.3em' 
                    }}
                    align="right">
                    <IconButton
                      onClick={() => {
                        setConfirmDecline(true)
                        setSelectedRequest(request)
                        // declineRequest(request)
                      }}>
                      <Clear fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  }

  const renderWhatIShare = () => {
    
    return (
      <Paper
        style={{
          padding: 20, marginBottom: 20 
        }}>
        <Typography
          variant="h5"
          style={{ fontWeight: 'bold' }}>
          {t('friend_iShare')}
        </Typography>
        <FormControlLabel
          control={
            <>
              <Checkbox
                checked={groupRelation.shareTrainings || false}
                onChange={handleTrainingsChange}
                name="checkedB"
                color="primary"
              />
              <List />
            </>
          }
          label={t('friend_shareTrainings')}
        />
        <FormControlLabel
          control={
            <>
              <Checkbox
                checked={groupRelation.shareLocation || false}
                onChange={handleLocationChange}
                name="checkedB"
                color="primary"
              />
              <LocationOn />
            </>
          }
          label={t('friend_shareLocation')}
        />
        <FormControlLabel
          control={
            <>
              <Checkbox
                checked={groupRelation.shareReports || false}
                onChange={handleShareReportsChange}
                name="checkedB"
                color="primary"
              />
              <Assessment />
            </>
          }
          label={t('friend_shareReports')}
        />
      
      </Paper>
    )
  }

  const renderInvite = () => {
    if(!isAdmin) {
      return null
    }
    return (

      <Paper
        style={{
          padding: 20,
          display: 'flex',
          flexDirection: 'column' 
        }}>
        {renderRequests()}
        <h2 style={{ margin: 0 }}>
          {t('friend_add')}
        </h2>
        {inviteError && <Alert severity="error">
          {inviteError}
        </Alert>}
        {inviteSent && <Alert severity="success">
          {t('friend_inviteSent')}
        </Alert>}
        <div
          style={{ 
            alignItems: 'center', display: 'flex', flexWrap: 'wrap'
          }}>
          <TextField
            label={t('friend_email')}
            value={email}
            onChange={handleEmailChange}
            style={{ flex: 1 }} />
          <Button
            variant="contained"
            color="primary"
            onClick={invite}>
            {t('friend_sendInvite')}
          </Button>
        </div>
      
      </Paper>
    )
  }

  return (
    <>
      <Paper
        style={{
          padding: 20,
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 20
        }}>
        <h1 style={{ margin: 0 }}>
          Members
        </h1>
        {memberRemoved && <Alert severity="success">
          {t('friend_userHasBeenRemoved')}
        
        </Alert>}
        <ConfirmModal
          isOpen={confirmRemoveMember}
          onClose={() => setConfirmRemoveMember(false)}
          onAccept={() => removeMemberFromGroup()}
          acceptLabel={t('friend_confirm')}
          label={t('friend_removeMember', { email: getName(selectedMember?.patient) })}/>
        {group?.members?.length > 0 && <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('friend_name')}
                </TableCell>
                <TableCell >
                  {t('friend_whatTheyShare')}
                </TableCell>
                {isAdmin && 
                <TableCell
                  align="right">
                  {t('friend_remove')}
                </TableCell>}
              </TableRow>

            </TableHead>
            <TableBody>
              {group?.members?.map((member) => {
                return (
                  <TableRow
                    key={member.id}
                    hover>
                    <TableCell>
                      {getName(member?.patient)}
                      {member.isAdmin && ` (${t('friend_admin')})` }
                    </TableCell>
                    <TableCell>
                      <Permissions
                        data={member || {}} />
                    </TableCell>
                    {isAdmin && 
                    <TableCell
                      style={{ 
                        fontWeight: 'bold', fontSize: '1.3em' 
                      }}
                      align="right">
                      { !member.isAdmin &&
                        <IconButton
                          style={{
                            padding: 10, margin: -10 
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            setConfirmRemoveMember(true)
                            setSelectedMember(member)
                          // deleteFriend(friend)
                          }}>
                          <Delete fontSize="small" />
                        </IconButton>
                      }
                    </TableCell>
                    }
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>}
      </Paper>
      {renderWhatIShare()}
      {renderInvite()}
    </>
  )
})))

export default Users


export async function getZipData(training, zip, profileStore) {
  
  const parsePosData = async (zip) => {
    const res = await parseCsvFromZip(zip, 'pos.txt')
    if (!res) return [{ 
      data: null, key: 'posData' 
    }]
    res.splice(0,1)
    return [{ 
      data: res.map((item) => {
        
        return {
          lat: parseFloat(item[1]),
          lng: parseFloat(item[2]),
        }
      }),
      key: 'posData' 
    }]
  }
  const parseStanceTimeData = async (zip) => {
    const res = await parseCsvFromZip(zip, 'stancetime.txt')
    // console.log(res)
    if (!res) return [{ 
      data: null, key: 'stanceTimeData' 
    }]
    res.splice(0,1)
    return [{ 
      data: sortAndSmoothValues(res, 1),
      key: 'stanceTimeData' 
    }, {
      data: sortAndSmoothValues(res, 2),
      key: 'stanceTimeDataLeft' 
    }, {
      data: sortAndSmoothValues(res, 3),
      key: 'stanceTimeDataRight' 
    }]
  }
  const parseSwingTimeData = async (zip) => {
    const res = await parseCsvFromZip(zip, 'swingtime.txt')
    if (!res) return [{ 
      data: null, key: 'swingTimeData' 
    }]
    res.splice(0,1)
    return [{ 
      data: sortAndSmoothValues(res, 1),
      key: 'swingTimeData' 
    }, {
      data: sortAndSmoothValues(res, 2),
      key: 'swingTimeDataLeft' 
    }, {
      data: sortAndSmoothValues(res, 3),
      key: 'swingTimeDataRight' 
    }]
  }
  const parseStepTimeData = async (zip) => {
    const res = await parseCsvFromZip(zip, 'steptime.txt')
    if (!res) return [{ 
      data: null, key: 'stepTimeData' 
    }]
    res.splice(0,1)
    return [{ 
      data: sortAndSmoothValues(res, 1),
      key: 'stepTimeData' 
    }, {
      data: sortAndSmoothValues(res, 2),
      key: 'stepTimeDataLeft' 
    }, {
      data: sortAndSmoothValues(res, 3),
      key: 'stepTimeDataRight' 
    }]
  }
  const parseAltitudeData = async (zip) => {
    const res = await parseCsvFromZip(zip, 'alt.txt')
    if (!res) return [{ 
      data: null, key: 'altitudeData' 
    }]
    res.splice(0,1)
    // find first value that is not null
    let diff = 0
    for ( let i = 0; i < res.length; i++) {
      if (res[i][1] !== null && res[i][1] !== '0.0') {
        // console.log(res[i][1], res[i][1] === '0.0')
        diff = res[i][1]
        break
      }
    }
    
    return [{ 
      data: sortAndSmoothValues(res.map((item) => {
        
        return [item[0], parseFloat(item[1]) - diff]
      })),
      key: 'altitudeData' 
    }]
  }

  const standardCSV = async (zip, file, key) => {
    const res = await parseCsvFromZip(zip, file)
    if (!res) return[ { 
      data: null, key 
    }]
    res.splice(0,1)
    return [{ 
      data: sortAndSmoothValues(convertAllToNumber(res)),
      key: key 
    }]
  }

  let promises = []
  promises.push(standardCSV(zip, 'speed.txt', 'speedData'))
  // promises.push(standardCSV(zip, 'alt.txt', 'altitudeData'))
  promises.push(standardCSV(zip, 'dist.txt', 'distanceData'))
  promises.push(standardCSV(zip, 'vertical_ratio.txt', 'verticalRatioData'))
  promises.push(standardCSV(zip, 'steplenght.txt', 'stepLengthData'))
  promises.push(standardCSV(zip, 'maxforce.txt', 'maxForceData'))
  promises.push(standardCSV(zip, 'bounce.txt', 'bounceData'))
  promises.push(standardCSV(zip, 'cadence.txt', 'cadenceData'))
  promises.push(standardCSV(zip, 'brake.txt', 'brakeData'))
  promises.push(standardCSV(zip, 'time_in_air.txt', 'timeInAirData'))
  promises.push(standardCSV(zip, 'time_on_ground.txt', 'groundContactTimeData'))
  promises.push(standardCSV(zip, 'toe_off_of_cycle.txt', 'toeOffData'))
  promises.push(standardCSV(zip, 'km_stats.txt', 'kmData'))

  promises.push(parseAltitudeData(zip))
  promises.push(parsePosData(zip))
  promises.push(parseStanceTimeData(zip))
  promises.push(parseSwingTimeData(zip))
  promises.push(parseStepTimeData(zip))


  const results = await Promise.all(promises)

  for(let result of results) {
    for (let item of result) {
      training[item.key] = item.data
    }
  }

  // if ((!training.timeInAir || training.timeInAir === 0) && training.timeInAirData) {
  //   // console.log(training.timeInAirData)
  //   training.timeInAir = training.timeInAirData.reduce((reducer, item) => {
  //     return reducer + item[1]
  //   }, 0)/training.timeInAirData.length
  // }

  // if ((!training.groundContactTime || training.groundContactTime === 0) && training.groundContactTimeData) {
  //   // console.log(training.groundContactTimeData)
  //   training.groundContactTime = training.groundContactTimeData.reduce((reducer, item) => {
  //     return reducer + item[1]
  //   }, 0)/training.groundContactTimeData.length
  // }

  // medel markkontakt

  if (training.activityType === 'WALK') {
    if (training.activeTime > 360) { // training longer than 6 minutes
      // calculate walking test
      training.best6min = getLongestDistanceInTime(training.distanceData, 360)
    }
  }


  if (training.activityType === 'RUN' || !training.activityType) {
    if (training.activeTime >= 720 && profileStore?.age && profileStore?.gender) { // training longer than 12 minutes
      // calculate walking test
      training.cooperRes = cooperTable(training.cooperValue, profileStore.age, profileStore.gender)
    }
  }
  return training
}


function getLongestDistanceInTime(data, time) {

  let start = 0
  let end = 0
  let sum = 0

  let bestSum = 0

  for (var i = 0; i < data.length; i++) {
    sum += data[i][1]
    end = data[i][0]
    while (end - data[start][0] > time) {
      sum -= data[start][1]
      start += 1
    }
    if (sum > bestSum) {
      bestSum = sum
    }
  }
  return bestSum
}

function cooperTable(value, age, gender) {
  if (age < 20) {
    return 'cooper_too_young'
  }

  if (gender === 'F') {
    if (age <= 29) {
      if (value < 29) {
        return 'cooper_low'
      } else if (value <= 34) {
        return 'cooper_below_middle'
      } else if (value <= 43) {
        return 'cooper_middle'
      } else if (value <= 48) {
        return 'cooper_good'
      } else {
        return 'cooper_excellent'
      }
    } else if (age <= 39) {
      if (value < 28) {
        return 'cooper_low'
      } else if (value <= 33) {
        return 'cooper_below_middle'
      } else if (value <= 41) {
        return 'cooper_middle'
      } else if (value <= 47) {
        return 'cooper_good'
      } else {
        return 'cooper_excellent'
      }
    } else if (age <= 49) {
      if (value < 26) {
        return 'cooper_low'
      } else if (value <= 31) {
        return 'cooper_below_middle'
      } else if (value <= 40) {
        return 'cooper_middle'
      } else if (value <= 45) {
        return 'cooper_good'
      } else {
        return 'cooper_excellent'
      }
    } else {
      if (value < 22) {
        return 'cooper_low'
      } else if (value <= 28) {
        return 'cooper_below_middle'
      } else if (value <= 36) {
        return 'cooper_middle'
      } else if (value <= 41) {
        return 'cooper_good'
      } else {
        return 'cooper_excellent'
      }
    }
  } else if (gender === 'M') {
    if (age <= 29) {
      if (value < 39) {
        return 'cooper_low'
      } else if (value <= 43) {
        return 'cooper_below_middle'
      } else if (value <= 51) {
        return 'cooper_middle'
      } else if (value <= 56) {
        return 'cooper_good'
      } else {
        return 'cooper_excellent'
      }
    } else if (age <= 39) {
      if (value < 35) {
        return 'cooper_low'
      } else if (value <= 39) {
        return 'cooper_below_middle'
      } else if (value <= 47) {
        return 'cooper_middle'
      } else if (value <= 51) {
        return 'cooper_good'
      } else {
        return 'cooper_excellent'
      }
    } else if (age <= 49) {
      if (value < 31) {
        return 'cooper_low'
      } else if (value <= 35) {
        return 'cooper_below_middle'
      } else if (value <= 43) {
        return 'cooper_middle'
      } else if (value <= 47) {
        return 'cooper_good'
      } else {
        return 'cooper_excellent'
      }
    } else {
      if (value < 26) {
        return 'cooper_low'
      } else if (value <= 31) {
        return 'cooper_below_middle'
      } else if (value <= 39) {
        return 'cooper_middle'
      } else if (value <= 43) {
        return 'cooper_good'
      } else {
        return 'cooper_excellent'
      }
    }
  }
  return 'cooper_unknown_gender'

}


async function parseCsvFromZip(zip, name) {

  if (!zip?.files?.[name]) {
    return null
  }

  const speedCSV = await zip.file(name).async('string')
  var lines = speedCSV.split('\n')
  // console.log(lines)
  
  // lines.splice(0, 1)

  if (lines[lines.length-1] === '') {
    lines.splice(-1, 1)
  }

  return lines.map((line) => {
    return line.split(';').map((num) => {
      return num
    })
  })
}


function convertAllToNumber(data) {
  return data.map((row) => row.map((col) => parseFloat(col)))
}

function sortAndSmoothValues(data, index=1) { 
  const r = Object.values(data.reduce((reducer, item) => {

    let time = Math.round(item[0])
    
    if (reducer[time]) {
      reducer[time].push(item)
    } else {
      reducer[time] = [item]
    }
    return reducer
  }, {})).map((pointInTime) => {

    // console.log([pointInTime[0][0], pointInTime.reduce((r, i) => r + i[1], 0)/pointInTime.length])
    

    return [Math.round(pointInTime[0][0]), pointInTime.reduce((r, i) => r + i[index], 0)/pointInTime.length]
    
    // return [time, values/values.length]
  })
  // console.log(r)
  // return r.filter((item) => item[1] !== 0)
  // console.log('sort complete')
  
  // return r.map((item) => item.map((val) => val !== 0 ? val : null))
  // return r
  return r.filter((item) => item.reduce((reducer, val) => val !== 0 && reducer), true)
  
}
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell, TableContainer, TableHead, TableRow, Typography 
} from '@material-ui/core'
import {
  Add,
  Check, Clear
} from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import {
  inject, observer 
} from 'mobx-react'
import React, { useState } from 'react'
import { withNamespaces } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ConfirmModal from '../../components/ConfirmModal'
import InputField from '../../components/InputField'

const Groups = withNamespaces()( inject('groups', 'profile')(observer(({
  groups, t
}) => {

  const history = useHistory()
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [shareTrainings, setShareTrainings] = useState(true)
  const [sharePosition, setSharePosition] = useState(false)
  const [shareReports, setShareReports] = useState(true)
  const [groupName, setGroupName] = useState('')
  const [groupCreated, setgroupCreated] = useState(false)
  const [inviteAccepted, setInviteAccepted] = useState(false)
  const [acceptModalOpen, setAcceptModalOpen] = useState(false)
  const [selectedRequest, setSelectedRequest] = useState(null)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [confirmLeaveOpen, setConfirmLeaveOpen] = useState(false)
  const [groupLeft, setGroupLeft] = useState(false)
  const [inviteDeclined, setInviteDeclined] = useState(false)
  const [confirmDecline, setConfirmDecline] = useState(false)
  // const { groupId } = useParams()
  // const groupRelation = groups.group(groupId)
  // // const group = groupRelation?.group
  // const isAdmin = groupRelation.isAdmin

  const handleName = (event) => {
    let newName = event.target.value
    setGroupName(newName)
  }

  const selectRow = (groupRelation) => {
    history.push(`/dash/groups/${groupRelation.group.id}`)
  }

  const createGroup = () => {
    setCreateModalOpen(false)
    groups.createGroup(groupName, shareTrainings, sharePosition, shareReports).then(() => {
      setgroupCreated(true)
      setTimeout(() => {
        setgroupCreated(false)
      }, 3000)
    })
  }

  const acceptRequest = () => {
    setAcceptModalOpen(false)
    groups.accept(selectedRequest.id, shareTrainings, sharePosition, shareReports).then(() => {
      setInviteAccepted(true)
      setTimeout(() => {
        setInviteAccepted(false)
      }, 3000)
    })
    
  }

  const declineRequest = () => {
    setConfirmDecline(false)
    groups.decline(selectedRequest.id).then(() => {
      setInviteDeclined(true)
      setTimeout(() => {
        setInviteDeclined(false)
      }, 3000)
    })
  }

  const leaveGroup = () => {
    setConfirmLeaveOpen(false)
    groups.leave(selectedGroup.id).then(() => {
      setGroupLeft(true)
      setTimeout(() => {
        setGroupLeft(false)
      }, 3000)
    })
  }

  const renderEmpty = () => {
    if (groups.list.length !== 0 || groups?.requests?.length > 0) {
      return null
    }
    return (
      <>
        <p
          style={{
            alignSelf: 'center', textTransform: 'uppercase', fontWeight: 'bold' , fontSize: '1.5em', color: 'gray', margin: 0, marginTop: 30,
          }}>
          {t('friend_noGroups')}
        </p>
        <img
          style={{
            maxWidth: 400, padding: 50, alignSelf: 'center' 
          }}
          src={require('../../assets/undraw_Tree_swing_646s.svg')}/>
      </>
    )
  }

  const renderRequests = () => {
    if (groups?.requests?.length <= 0) {
      return null
    }
    return (
      <>
        <h2>
          {t('friend_requests')}
        </h2>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('friend_name')}
                </TableCell>
                <TableCell>
                  {t('friend_numberOfMembers')}
                </TableCell>
                <TableCell
                  align="right">
                  {t('friend_accept')}
                </TableCell>
              </TableRow>

            </TableHead>
            <TableBody>
              {groups.requests.map((request) => {
                return (
                  <TableRow key={request.id}>
                    <TableCell>
                      {request.group.name}
                    </TableCell>
                    <TableCell>
                      {request.group.members.length}
                    </TableCell>
                    <TableCell
                      style={{ 
                        fontWeight: 'bold', fontSize: '1.3em' 
                      }}
                      align="right">
                      <IconButton
                        onClick={() => {
                          setAcceptModalOpen(true)
                          setSelectedRequest(request)
                        }}>
                        <Check fontSize="small" />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setConfirmDecline(true)
                          setSelectedRequest(request)
                        // declineRequest(request)
                        }}>
                        <Clear fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }


  const renderAcceptModal = () => {
    return <Modal
      open={acceptModalOpen}
      onClose={() => setAcceptModalOpen(false)}
      style={{
        display:'flex',alignItems:'center',justifyContent:'center' 
      }}>

      <Paper
        style={{
          padding: 20, flexDirection: 'column', display: 'flex'
        }}>
        <h2>
          {t('friend_shareGroup')}
        </h2>
        <FormControlLabel
          control={
            <Checkbox
              checked={shareTrainings}
              onChange={() => setShareTrainings(!shareTrainings)}
              name="checkedB"
              color="primary"
            />
          }
          label={t('friend_shareTrainings')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={sharePosition}
              onChange={() => setSharePosition(!sharePosition)}
              name="checkedB"
              color="primary"
            />
          }
          label={t('friend_shareLocation')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={shareReports}
              onChange={() => setShareReports(!shareReports)}
              name="checkedB"
              color="primary"
            />
          }
          label={t('friend_shareReports')}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={acceptRequest}>
          {t('friend_acceptInvite')}
        </Button>
      </Paper>

    </Modal>
  }

  const renderCreateGroupModal = () => {
    return <Modal
      open={createModalOpen}
      onClose={() => setCreateModalOpen(false)}
      style={{
        display:'flex',alignItems:'center',justifyContent:'center' 
      }}>

      <Paper
        style={{
          padding: 20, flexDirection: 'column', display: 'flex'
        }}>
        <Typography variant="h5">
          {t('friend_createGroup')}
        </Typography>
        <InputField
          id="standard-basic"
          label={t('friend_groupName')}
          small
          placeholder={t('friend_groupName')}
          onChange={handleName}
          value={groupName}  />
        <h2>
          {t('friend_shareGroup')}
        </h2>
        <FormControlLabel
          control={
            <Checkbox
              checked={shareTrainings}
              onChange={() => setShareTrainings(!shareTrainings)}
              name="checkedB"
              color="primary"
            />
          }
          label={t('friend_shareTrainings')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={sharePosition}
              onChange={() => setSharePosition(!sharePosition)}
              name="checkedB"
              color="primary"
            />
          }
          label={t('friend_shareLocation')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={shareReports}
              onChange={() => setShareReports(!shareReports)}
              name="checkedB"
              color="primary"
            />
          }
          label={t('friend_shareReports')}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={createGroup}>
          {t('friend_createGroup')}
        </Button>
      </Paper>

    </Modal>
  }

  const renderGroups = () => {
    if (groups.list.length <= 0) {
      return null
    }
    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                {t('friend_name')}
              </TableCell>
              <TableCell>
                {t('friend_numberOfMembers')}
              </TableCell>
              <TableCell
                align="right">
                {t('friend_leave')}
              </TableCell>
            </TableRow>

          </TableHead>
          <TableBody>
            {groups.list.map((groupRelation) => {
              return (
                <TableRow
                  key={groupRelation.id}
                  hover
                  onClick={() => {selectRow(groupRelation)}}>
                  <TableCell>
                    {groupRelation?.group?.name}
                  </TableCell>
                  <TableCell>
                    {groupRelation?.group?.members?.length || 1}
                  </TableCell>
                  <TableCell
                    style={{ 
                      fontWeight: 'bold', fontSize: '1.3em' 
                    }}
                    align="right">
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation()
                        setConfirmLeaveOpen(true)
                        setSelectedGroup(groupRelation?.group)
                      }}
                      disabled={groupRelation.isAdmin}>
                      <Clear />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <Paper
      style={{
        padding: 20, display: 'flex', flexDirection: 'column' 
      }}>
      <Box
        style={{
          flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center'
        }}>
        <Typography
          variant="h4"
          style={{ fontWeight: 'bold' }}>
          {t('friend_groups')}
        </Typography>
        <IconButton onClick={() => setCreateModalOpen(true)}>
          <Add  />
        </IconButton>
        {/* <Button
        variant="contained"
        color="primary"
        onClick={() => setCreateModalOpen(true)}>
        Create group
      </Button> */}
      </Box>
      {groupCreated && <Alert severity="success">
        {t('friend_groupCreated')}
      </Alert>}
      {inviteAccepted && <Alert severity="success">
        {t('friend_inviteAccepted')}
      </Alert>}
      {groupLeft && <Alert severity="success">
        {t('friend_groupLeft')}
      </Alert>}
      {inviteDeclined && <Alert severity="success">
        {t('friend_inviteDeclined')}
      </Alert>}
      <ConfirmModal
        isOpen={confirmLeaveOpen}
        onClose={() => setConfirmLeaveOpen(false)}
        onAccept={() => leaveGroup()}
        acceptLabel={t('friend_confirm')}
        label={t('friend_leaveGroup', { name: selectedRequest?.group?.name })}/>
      <ConfirmModal
        isOpen={confirmDecline}
        onClose={() => setConfirmDecline(false)}
        onAccept={() => declineRequest()}
        acceptLabel={t('friend_confirm')}
        label={t('friend_wantDeclineInviteFrom', { email: selectedRequest?.group?.name }) } />
      {renderGroups()}

      {renderEmpty()}

      {renderRequests()}
      {renderCreateGroupModal()}
      {renderAcceptModal()}
    </Paper>
  )
})))

export default Groups
import React from 'react'
import Drawer from '../components/Drawer'
import { withNamespaces } from 'react-i18next'

var LoadingDashboard = withNamespaces() (({ t }) => {

  
  return(
    <Drawer>
      <p>
        {t('trainings_loading')}
      </p>
    </Drawer>
  )
})

export default LoadingDashboard
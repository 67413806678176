import React, {
  useState, useEffect
} from 'react'
import { useLocation } from 'react-router'
import firebase from 'firebase'
import {
  Box, Button, CssBaseline, Typography
} from '@material-ui/core'
import InputField from '../../components/InputField'
import { Lock } from '@material-ui/icons'
import LoginContainer from '../../components/LoginContainer'
import { withNamespaces } from 'react-i18next'
import {useHistory} from "react-router-dom";

const AuthActions = withNamespaces()(({ t }) => {
  const [oobCodeValid, setOobCodeValid] = useState(true)
  const [email, setEmail] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPassword2, setNewPassword2] = useState('')
  const [newPasswordError, setNewPasswordError] = useState('')
  const [newPassword2Error, setNewPassword2Error] = useState('')
  const [confirmError, setConfirmError] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const oobCode = queryParams.get('oobCode')
  const mode = queryParams.get('mode')

  const history = useHistory()

  useEffect(() => {
    if (mode === 'resetPassword') {
      firebase
        .auth()
        .verifyPasswordResetCode(oobCode)
        .then((oobCodeEmail) => {
          setEmail(oobCodeEmail)
        })
        .catch(() => {
          setOobCodeValid(false)
        })
    } else if (mode === 'verifyEmail') {
      firebase
        .auth()
        .checkActionCode(oobCode)
        .then((info) => {
          setOobCodeValid(true)
          setEmail(info['data']['email'])

          firebase
            .auth()
            .applyActionCode(oobCode)
            .then(() => setSuccess(true))
            .catch((error) => {
              console.log('Failed to verify email:', error)
              setSuccess(false)
            })
        })
        .catch((error) => {
          console.log('Action code invalid:', error)
          setOobCodeValid(false)
        })
    }
  }, [])

  const validate = () => {
    let passwordError = ''
    let password2Error = ''

    if (newPassword.length < 6) {
      passwordError = t('authAction_passwordErrorShort')
    }

    if (newPassword !== newPassword2) {
      password2Error = t('authAction_passwordErrorMismatch')
    }

    setNewPasswordError(passwordError)
    setNewPassword2Error(password2Error)

    return !passwordError && !password2Error
  }

  const handlePasswordChange = () => {
    if (validate()) {
      setLoading(true)
      setConfirmError('')

      firebase
        .auth()
        .confirmPasswordReset(oobCode, newPassword)
        .then(() => {
          setSuccess(true)
        })
        .catch((error) => {
          if (error.code === 'auth/weak-password') {
            setConfirmError(t('authAction_passwordErrorWeak'))
          } else {
            setConfirmError(t('authAction_unknownError'))
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const renderInvalidOobCode = () => (
    <>
      <Box>
        <Typography variant="h3">
          Mystep
          {' - '}
          {mode === 'resetPassword'
            ? t('authAction_passwordReset')
            : t('authAction_emailVerification')}
        </Typography>
      </Box>
      <Box
        mt={2}
        mb={2}>
        <Typography>
          {t('authAction_invalidLink')}
        </Typography>
      </Box>
    </>
  )

  const renderSuccess = () => (
    <Typography>
      {t('authAction_passwordSuccess')}
    </Typography>
  )

  const renderVerified = () => (
    <>
      <Box>
        <Typography variant="h3">
          Mystep -
          {' '}
          {t('authAction_emailVerification')}
        </Typography>
      </Box>
      <Box
        mt={2}
        mb={2}>
        <Typography>
          {success
            ? t('authAction_verificationSuccess')
            : t('authAction_unknownError')}
        </Typography>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{
            textTransform: 'none',
            marginTop: 20,
            width: '100%',
          }}
          onClick={() => {
            history.push('/dash/')
          }}
        >
          {t('login_signin')}
        </Button>
      </Box>
    </>
  )

  const renderPasswordResetForm = () => (
    <Box
      mb={4}
      mt={4}>
      <InputField
        label={t('authAction_formPassword')}
        id="newPassword"
        type="password"
        onChange={(evt) => setNewPassword(evt.target.value)}
        value={newPassword}
        errorMessage={newPasswordError}
        icon={<Lock color="disabled" />}
      />
      <InputField
        label={t('authAction_formPasswordRepeat')}
        id="newPassword2"
        type="password"
        onChange={(evt) => setNewPassword2(evt.target.value)}
        value={newPassword2}
        errorMessage={newPassword2Error}
        icon={<Lock color="disabled" />}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        style={{
          textTransform: 'none',
          marginTop: 20,
          width: '100%',
        }}
        onClick={handlePasswordChange}
      >
        {t('authAction_passwordConfirm')}
      </Button>
      {confirmError && (
        <Box mt={2}>
          <Typography style={{ color: 'red' }}>
            {confirmError}
          </Typography>
        </Box>
      )}
    </Box>
  )

  const renderPasswordReset = () => (
    <>
      <Box>
        <Typography variant="h3">
          Mystep -
          {' '}
          {t('authAction_passwordReset')}
        </Typography>
      </Box>
      <Box
        mt={2}
        mb={2}>
        {success ? renderSuccess() : renderPasswordResetForm()}
      </Box>
    </>
  )

  return (
    <div>
      <CssBaseline />
      <main>
        <LoginContainer>
          <Box>
            {oobCodeValid
              ? mode === 'resetPassword'
                ? renderPasswordReset()
                : renderVerified()
              : renderInvalidOobCode()}
          </Box>
        </LoginContainer>
      </main>
    </div>
  )
})

export default AuthActions

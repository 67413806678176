// profile store is responsible for fetching and storing the users profile. THe profile could be extended in the future with edit functionality.

import {
  observable, action, runInAction, observe, computed 
} from 'mobx'
import { formatDate } from '../helper/formatting'
import i18n from '../i18n'


export class ProfileStore {
    @observable length = 0;
    @observable weight = 0;
    @observable name = '';
    @observable gender = 0;
    @observable birthday = 0;
    @observable units = 'METRIC'
    @observable timeFormat = 'HOUR24'
    @observable dateFormat = 'YMD' 


    @observable state = 0;

    authStore = null

    constructor(authStore) {
      this.authStore = authStore

      observe(this.authStore, 'id', () => {
        this.state = 0
        this.fetchProfile()
      })
    }

    @computed get age() {
      const birth = new Date(this.birthday)
      const diff = new Date() - birth
      return diff/(1000*3600*24*365)
    }

    @action async fetchProfile() {
      if (this.state !== 0) {
        return
      }
      this.state = 1
      // console.log(this.authStore.id)
      
      const headers = { headers: { authentication: await this.authStore.getIdToken(), } }
      
      fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/getProfile`, headers).then(async (res) => { // eslint-disable-line no-undef
        // console.log(res)
        
        if (res.status < 300) {
          // console.log(await res.text())
          try {
            let data = await res.json()
            console.log('profile data', data)
            // console.log(data)
            runInAction(() => {
              this.length = data.length
              this.weight = data.weight
              this.name = data.name
              this.gender = data.gender
              this.birthday = data.birthday
              this.units = data.units || this.units
              this.timeFormat = data.timeFormat || this.timeFormat
              this.dateFormat = data.dateFormat || this.dateFormat
              this.state = 2

              // debug:
              // this.dateFormat = 'DMY'
            })
          } catch(e) {
            console.log('error parsing json for profile', e)
          }
        }
        
      })
    }

    @action async refreshProfile() {
      this.state = 0
      this.fetchProfile()
    }

    @action async updateProfile() {
      const body = JSON.stringify({
        length: this.length,
        weight: this.weight,
        name: this.name,
        birthday: this.birthday,
        gender: this.gender,
        units: this.units,
        timeFormat: this.timeFormat,
        dateFormat: this.dateFormat
      } )

      // set profile
      fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/setProfile`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body
      }).then(async (res) => {
        console.log(res)
        
      })
    }

    @action setUnits(units) {
      this.units = units
      this.updateProfile()
    }
    @action setTimeFormat(format) {
      this.timeFormat = format
      this.updateProfile()
    }
    @action setDateFormat(format) {
      this.dateFormat = format
      this.updateProfile()
    }

    @action async generateReport(period, userId='', groupId='') {
      const headers = {
        headers: {
          authentication: await this.authStore.getIdToken(), 
          'Content-Type': 'application/json' 
        },
        method: 'POST',
        body: JSON.stringify({
          period: period,
          activity: 'WALK',
          lang: i18n.language,
          dateFormat: this.dateFormat,
          is24Hour: this.timeFormat === 'HOUR24'
        }) 
      }

      let durationInDays = 1
      if (period === 'quarter') {
        durationInDays = 90
      } else if (period === 'week') {
        durationInDays = 7
      } else if (period === 'month') {
        durationInDays = 30
      }  else if (period === 'halfYear') {
        durationInDays = 180
      } 

      let url = `${process.env.REACT_APP_SERVER_URL}pdf/${this.authStore.id}/generate/`// eslint-disable-line no-undef
      if (groupId) {
        url = `${process.env.REACT_APP_SERVER_URL}pdf/${this.authStore.id}/generate/group/${groupId}/${userId}`// eslint-disable-line no-undef
      } else if (userId) {
        url = `${process.env.REACT_APP_SERVER_URL}pdf/${this.authStore.id}/generate/${userId}`// eslint-disable-line no-undef
      }



      return fetch(url, headers).then(async (res) => { 
        if (res.status >= 400) {
          console.log(res)
          throw res
        }
        return res.blob()
      }).then((res) => {
        var fileURL = window.URL.createObjectURL(new Blob([res]))
        var fileLink = document.createElement('a')

        var d = new Date()
        d.setDate(d.getDate()-durationInDays)

        fileLink.href = fileURL
        fileLink.setAttribute('download', 'report_'
        +formatDate(d, this.dateFormat, this.timeFormat, true, false)
        +'_'
        +formatDate(new Date(), this.dateFormat, this.timeFormat, true, false)
        +'.pdf')
        // fileLink.setAttribute('target', '_blank')
        document.body.appendChild(fileLink)

        fileLink.click()
        fileLink.parentNode.removeChild(fileLink)
        // open in new tab.
      })
    }
}
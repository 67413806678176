import React from 'react'
import {
  Tab, withStyles 
} from '@material-ui/core'


const StyledTab = withStyles(() => ({
  root: { '&$selected': { color: 'white' }, },
  selected: {
    backgroundColor: '#103cd6',
    color: 'white'
  } 
}))((props) => <Tab {...props} />)

export default StyledTab
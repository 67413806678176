import React, { useState } from 'react'
import DashboardBase from './DashboardBase'
import {
  IconButton, Table, TableHead, TableRow, TableCell, TableContainer, TableBody
} from '@material-ui/core'
import {
  ArrowForward, ArrowBack
} from '@material-ui/icons'
import {
  formatDuration, formatDistance, formatDate
} from '../../helper/formatting'
// import HighchartsReact from 'highcharts-react-official'

// import Highcharts from 'highcharts'
// import { formatDate } from '../../helper/formatting'

const DashboardTrainings = ({
  trainings, dateFormat, timeFormat, units, t, numberOfTrainingsGoal, activeTimeGoal, distanceGoal
}) => {

  const [page, setPage] = useState(0)
  const trainingsPerPage = 7

  const canShowNext = () => {
    return page < Object.keys(trainings).length/trainingsPerPage -1
  }
  const canShowPrev = () => {
    return page >= 1
  }

  const handleSetNextPage = () => {
    if (canShowNext()) {

      setPage(page+1)
    }
  }
  const handleSetPrevPage = () => {
    if (canShowPrev()) {
      setPage(page-1)
    }
  }

  const renderTrainings = () => {
    if (!trainings) {
      return null
    }

    return Object.keys(trainings).slice(trainingsPerPage * page, trainingsPerPage * (page+1)).map((key) => {
      let items = trainings[key]
      const activeTime = items.reduce((reducer, i) => reducer + i.activeTime, 0)
      const totalDistance = items.reduce((reducer, i) => reducer + i.totalDistance, 0);

      return (<TableRow
        key={key}>
        <TableCell
          style={{
            padding: 0, paddingTop: 10, paddingBottom: 10
          }}>
          {formatDate(new Date(new Date().setDate(new Date().getDate()-key)), dateFormat, timeFormat, true)}
        </TableCell>
        <TableCell
          style={{
            padding: 0,
            ...(activeTimeGoal && activeTime > (activeTimeGoal.level) ? {
              color: '#46a667', fontWeight: 'bold'
            } : {})
          }}>
          {formatDuration( activeTime)}
        </TableCell>
        <TableCell
          style={{
            padding: 0,
            ...(distanceGoal && totalDistance > (distanceGoal.level) ? {
              color: '#46a667', fontWeight: 'bold'
            } : {})
          }}>
          {formatDistance(totalDistance, units)}
        </TableCell>
        <TableCell
          style={{
            padding: 0,
            ...(numberOfTrainingsGoal && items.length >= numberOfTrainingsGoal.level ? {
              color: '#46a667', fontWeight: 'bold'
            } : {})
          }}
          align="right">
          {items.length}
        </TableCell>
      </TableRow>)
    })
  }



  return (
    <DashboardBase>
      <h1>
        {t('menu_trainings')}
      </h1>
      <TableContainer>
        <Table  size="small">
          <TableHead
            key="header">
            <TableRow>
              <TableCell
                style={{
                  padding: 0,
                  minWidth: 80
                }} >
                {t('trainings_date')}
              </TableCell>
              <TableCell
                style={{
                  padding: 0,
                  minWidth: 80
                }} >
                {t('trainings_time')}
              </TableCell>
              <TableCell
                style={{ padding: 0, }} >
                {t('trainings_distance')}
              </TableCell>
              <TableCell
                align="right"
                style={{ padding: 0, }} >
                {t('trainings_count')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderTrainings()}
          </TableBody>
        </Table>
      </TableContainer>
      <IconButton
        size="small"
        disabled={!canShowPrev()}
        onClick={handleSetPrevPage} >
        <ArrowBack />
      </IconButton>
      <IconButton
        size="small"
        disabled={!canShowNext()}
        onClick={handleSetNextPage} >
        <ArrowForward />
      </IconButton>

    </DashboardBase>
  )
}
export default DashboardTrainings

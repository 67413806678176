import React from 'react'
import {
  Paper, Table, TableHead, TableRow, TableCell, TableBody, TableContainer 
} from '@material-ui/core'
import {
  formatDistance, formatPace, formatDuration 
} from '../helper/formatting'

const PaceTable = ({
  kmData, units, t
}) => {

  if (!kmData) {
    return null
  }


  const renderPaceList = () => {
    let prevItem = [0,0]
    return kmData.map((item) => {
      let pace = formatPace((item[0] - prevItem[0])/(item[1] - prevItem[1]), units)
      prevItem = item
      return (
        <TableRow key={item[0]}>
          <TableCell>
            {formatDistance(item[1], units)}
          </TableCell>
          <TableCell>
            {formatDuration(item[0])}
          </TableCell>
          <TableCell>
            {pace}
          </TableCell>
        </TableRow>
      )
    })
  }

  return (
    <TableContainer
      component={Paper}
      style={{}}>
      <Table >
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>
              {t('training_distance')}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {t('training_time')}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {t('training_pace')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            renderPaceList()
          }
        </TableBody>
      </Table>
      {/* </Paper> */}
    </TableContainer>
  )
}

export default PaceTable
import React, {
  useState, useEffect 
} from 'react'
import {
  Modal, Paper, Button, Select, MenuItem, Box 
} from '@material-ui/core'
import InputField from './InputField'
import { withNamespaces } from 'react-i18next'
import { KeyboardDatePicker, } from '@material-ui/pickers'
import { formatDate } from '../helper/formatting'
import SelectButtons from './SelectButtons'


const EditProfileModal = withNamespaces()( ({ 
  open, onClose, t, profile
}) => {
  const [length, setLength] = useState(profile.length)
  const [weight, setWeight] = useState(profile.weight)
  const [name, setName] = useState(profile.name)
  const [birthday, setBirthday] = useState(profile.birthday)
  const [gender, setGender] = useState(profile.gender)
  const [units, setUnits] = useState(profile.units)
  const [timeFormat, setTimeFormat] = useState(profile.timeFormat)
  const [dateFormat, setDateFormat] = useState(profile.dateFormat)

  const [nameError, setnameError] = useState('')


  const handleLength = (event) => {
    setLength(event.target.value.replace(/[^0-9]/g, ''))
  }
  const handleWeight = (event) => {
    setWeight(event.target.value.replace(/[^0-9]/g, ''))
  }
  const handleName = (event) => {
    let newName = event.target.value
    if (/\d/.test(newName)) {
      newName = newName.replace(/[0-9]/g, '')
      setnameError(t('Profile_errornumbersinname'))
    } else {
      setnameError('')
    }
    setName(newName)
  }
  const handleBirthday = (date) => {
    setBirthday(formatDate(date, 'YMD', 'HOUR12', true))
  }
  const handleGender = (event) => {
    setGender(event.target.value)
  }

  useEffect(() => {setLength(profile.length)}, [profile.length])
  useEffect(() => {setWeight(profile.weight)}, [profile.weight])
  useEffect(() => {setName(profile.name)}, [profile.name])
  useEffect(() => {setBirthday(profile.birthday)}, [profile.birthday])
  useEffect(() => {setGender(profile.gender)}, [profile.gender])
  useEffect(() => {setUnits(profile.units)}, [profile.units])
  useEffect(() => {setTimeFormat(profile.timeFormat)}, [profile.timeFormat])
  useEffect(() => {setDateFormat(profile.dateFormat)}, [profile.dateFormat])

  const handleSave = () => {

    profile.length = parseFloat(length) || 0
    profile.weight = parseFloat(weight) || 0
    profile.name = name
    profile.birthday = birthday
    profile.gender = gender
    profile.units = units
    profile.timeFormat = timeFormat
    profile.dateFormat = dateFormat
    profile.updateProfile()
    if (onClose) {
      onClose()
    }
  }
  
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ 
        display:'flex',alignItems:'center',justifyContent:'center' 
      }}
    >
      <Paper
        style={{ padding: 20 }}
      >
        <h1>
          {t('profile_editProfile')}
        </h1>
        <InputField
          id="standard-basic"
          label={t('Profile_nameinreport')}
          small
          placeholder={t('Profile_nameinreport')}
          onChange={handleName}
          errorMessage={nameError}
          value={name}  />
        <InputField
          id="standard-basic"
          label={t('profile_length')}
          small
          placeholder={t('profile_length')}
          onChange={handleLength}
          value={length}  />
        <InputField
          id="standard-basic"
          label={t('profile_weight')}
          small
          placeholder={t('profile_weight')}
          onChange={handleWeight}
          value={weight}  />
        
        <div>
          <KeyboardDatePicker
            style={{ display: 'flex' }}
            clearable
            value={birthday}
            placeholder="10/10/2018"
            onChange={date => handleBirthday(date)}
            format="yyyy-MM-dd"
          />
        </div>
        <Box py={3}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={gender}
            onChange={handleGender}
            style={{ display: 'flex' }}
          >
            <MenuItem value='M'>
              {t('profile_male')}
            </MenuItem>
            <MenuItem value='F'>
              {t('profile_female')}
            </MenuItem>
            <MenuItem value='N'>
              {t('profile_otherGender')}
            </MenuItem>
          </Select>
        </Box>
        <h3 style={{ marginBottom: 5 }}>
          {t('profile_units')}
        </h3>
        <Box  pb={1}>
          <SelectButtons
            data={[{
              label: t('profile_metric'), key: 'METRIC' 
            }, {
              label: t('profile_imperial'), key: 'IMPERIAL' 
            }]}
            onChange={(units) => setUnits(units)}
            selected={units} />
        </Box>
        <h3 style={{ marginBottom: 5 }}>
          {t('profile_timeFormat')}
        </h3>
        <Box  pb={1}>
          <SelectButtons
            data={[{
              label: t('profile_24hour'), key: 'HOUR24' 
            }, {
              label: t('profile_12hour'), key: 'HOUR12' 
            }]}
            onChange={(format) => setTimeFormat(format)}
            selected={timeFormat} />
        </Box>
        <h3 style={{ marginBottom: 5 }}>
          {t('profile_dateFormat')}
        </h3>
        <Box  pb={3}>
          <SelectButtons
            data={[{
              label: '2020-12-31', key: 'YMD' 
            }, {
              label: '31-12-2020', key: 'DMY' 
            }, {
              label: '12-31-2020', key: 'MDY' 
            }]}
            onChange={(format) => setDateFormat(format)}
            selected={dateFormat} />
        </Box>
        <Button
          variant='contained'
          color="primary"
          style={{ marginRight: 10 }}
          onClick={handleSave}>
          {t('profile_save')}
        </Button>
        <Button
          variant='contained'
          onClick={onClose}>
          {t('profile_cancel')}
        </Button>
      </Paper>
    </Modal>
  )
})

export default EditProfileModal
import React from 'react'
import { MoodColors } from '../helper/constants'

var MoodIcon = ({
  mood, width=50 
}) => {

  switch (mood) {
  case 1:
    return <img
      style={{
        maxWidth: width,
        color: MoodColors.VERY_SAD 
      }}
      src={require('../assets/icons/frowning-face_2639-fe0f.png')}/>
  case 2:
    return <img
      style={{
        maxWidth: width,
        color: MoodColors.SAD 
      }}
      src={require('../assets/icons/slightly-frowning-face_1f641.png')}/>
  case 4:
    return <img
      style={{
        maxWidth: width,
        color: MoodColors.SEMI_HAPPY 
      }}
      src={require('../assets/icons/slightly-smiling-face_1f642.png')}/>
  case 5:
    return <img
      style={{
        maxWidth: width,
        color: MoodColors.HAPPY 
      }}
      src={require('../assets/icons/grinning-face_1f600.png')}/>
  
  case 3: // case 3 is same as default
  default:
    return <img
      style={{
        maxWidth: width,
        color: MoodColors.OK 
      }}
      src={require('../assets/icons/neutral-face_1f610.png')}/>
  }

}

export default MoodIcon

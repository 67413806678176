// profile store is responsible for fetching and storing the users profile. THe profile could be extended in the future with edit functionality.

import { 
  observable, 
  action, 
  runInAction, 
  computed, observe
  // toJS
} from 'mobx'
import JSZip from 'jszip'
// import {
//   reducePerDay, reducePerWeek, reducePerMonth 
// } from '../helper/reducer'
import { formatDate } from '../helper/formatting'
import { getZipData } from '../helper/training'

const MILLI_SECONDS_IN_DAY = 1000 * 3600 * 24

export class TrainigStore {
    @observable trainings = {};
    @observable state = 0;
    @observable rowsPerPage = 10
    @observable page = 0

    authStore = null
    profileStore = null

    constructor(authStore, profileStore) {
      this.authStore = authStore
      this.profileStore = profileStore

      // this.userChanged = reaction(() => {
      //   return this.authStore.user
      // }, () => {
      //   this.trainings = {}
      //   this.state = 0
      // })

      observe(this.authStore, 'id', () => {
        this.trainings = {}
        this.state = 0
      })

    }

    @computed get list() {
      return Object.values(this.trainings)
    }

    @computed get sortedList() {
      return this.list.sort((a, b) => {
        if (a.time > b.time) {
          return -1
        } else if (b.time > a.time) {
          return 1
        }
        return 0
      })
    }

    @computed get count() {
      return this.list.length
    }

    @computed get aaa() {
      return 10
    }

    @computed get training() {
      return (trainingId) => {
        if (this.trainings[trainingId]) {
          if (!this.trainings[trainingId].zipLoaded) {
            this.fetchTraining(trainingId)
          }
          return this.trainings[trainingId]
        } else {
          this.fetchTraining(trainingId)
          return {}
        }
      }
    }

    @computed get trainingsByDaysAgo() {
      const today = new Date(formatDate(new Date(), 'YMD', '', true))
      today.setHours(0,0,0,0)
      return Object.values(this.trainings).reduce((reducer, item) => {
        if (!item.time) {
          // console.log(item, item.time)
          
          return reducer
        }
        const day = item.time.substring(0, 10) // asdf TODO
        const date = new Date(day)
        date.setHours(0,0,0,0)
        
        const key = Math.floor((today.getTime() - date.getTime()) / MILLI_SECONDS_IN_DAY)
        // console.log(date, today, day, key)
        
        if (reducer[key]) {
          reducer[key].push(item)
        } else {
          reducer[key] = [item]
        }

        return reducer
      }, {})
    }

    @action async deleteTraining(trainingId) {
      // const headers = { headers: { authentication: this.authStore.idToken, } }
      // fetch(`${process.env.REACT_APP_SERVER_URL}/patient/${this.authStore.id}/trainings`, headers).then(async (res) => {  // eslint-disable-line no-undef

      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/trainings/delete/${trainingId}`, {  // eslint-disable-line no-undef
        headers: {
          authentication: this.authStore.idToken,
          'Content-Type': 'application/json'
        },
        method: 'DELETE',
      }).then(async () => {
        // console.log(res)
        // remove locally
        delete this.trainings[trainingId]
        return true
        
      }).catch((e) => {
        console.log('error', e)
      })

    }

    // @computed get trainingsPerDay() {
    //   return reducePerDay(this.trainingsByDaysAgo, (reducer, item) => reducer += item.length, 0)
    // }

    // @computed get activeTimePerDay() {
    //   return reducePerDay(this.trainingsByDaysAgo, (reducer, item) => reducer += item.reduce((reducer, item) => reducer + item.activeTime, 0) * 1000, 0)
    // }

    // @computed get speedPerDay() {
    //   return reducePerDay(this.trainingsByDaysAgo, (reducer, item) => reducer += item.reduce((reducer, item) => reducer + item.avgSpeed, 0)/item.length * 3.6, 0)
    // }

    // @computed get activeTimePerWeek() {      
    //   return reducePerWeek(this.trainingsByDaysAgo, (reducer, item) => reducer += item.reduce((reducer, item) => reducer + item.activeTime, 0) * 1000, 0)
    // }

    // @computed get activeTimePerMonth() {
    //   return reducePerMonth(this.trainingsByDaysAgo, (reducer, item) => reducer += item.reduce((reducer, item) => reducer + item.activeTime, 0) * 1000, 0)
    // }

    // @computed get stanceTimeLeftPerMonth() {
    //   return reducePerMonth(this.trainingsByDaysAgo, (reducer, item) => reducer += item.reduce((reducer, item) => reducer + item.stanceTimeLeft, 0)/item.length, 0)
    // }
    

    @action async fetchTrainings() {
      if (this.state !== 0) {
        return
      }

      let a = false
      if (a ) {
        this.state = 3
        this.trainings = {
          'id1': {
            time: '2020-07-15',
            activeTime: 1200,
            totalDistance: 1000,
            avgSpeed: 5,
            stanceTimeLeft: 1,
            stanceTimeRight: 2,
            activityType: 'RUN',
          },
          'id2': {
            time: '2020-07-14',
            activeTime: 500,
            totalDistance: 300,
            avgSpeed: 50,
            stanceTimeLeft: 2,
            stanceTimeRight: 3,
            activityType: 'WALK',
          },
          'id3': {
            time: '2020-07-14',
            activeTime: 1500,
            totalDistance: 1600,
            avgSpeed: 100,
            stanceTimeLeft: 1,
            stanceTimeRight: 4,
            activityType: 'RUN',
          },
          'null1': {},
          'null2': {},
          'null3': {},
          'null4': {},
          'null5': {},
          'null6': {},
          'id4': {
            time: '2020-07-13',
            activeTime: 1200,
            totalDistance: 1000,
            avgSpeed: 5,
            stanceTimeLeft: 1,
            stanceTimeRight: 1,
            activityType: 'WALK',
          },
          'id5': {
            time: '2020-07-12',
            activeTime: 500,
            totalDistance: 300,
            avgSpeed: 4,
            stanceTimeLeft: 1,
            stanceTimeRight: 3,
            activityType: 'RUN',
          },
          'id6': {
            time: '2020-07-11',
            activeTime: 1500,
            totalDistance: 1600,
            avgSpeed: 7,
            stanceTimeLeft: 2,
            stanceTimeRight: 3,
            activityType: 'RUN',
          },
          'id7': {
            time: '2020-07-10',
            activeTime: 1200,
            totalDistance: 1000,
            avgSpeed: 8,
            stanceTimeLeft: 1,
            stanceTimeRight: 2,
            activityType: 'WALK',
          },
          'id8': {
            time: '2020-07-09',
            activeTime: 500,
            totalDistance: 300,
            avgSpeed: 4,
            stanceTimeLeft: 1,
            stanceTimeRight: 2,
            activityType: 'RUN',
          },
          'id9': {
            time: '2020-07-08',
            activeTime: 1500,
            totalDistance: 1600,
            avgSpeed: 3,
            stanceTimeLeft: 1,
            stanceTimeRight: 2,
            activityType: 'WALK',
          },
          'id10': {
            time: '2020-07-07',
            activeTime: 1200,
            totalDistance: 1000,
            avgSpeed: 34,
            stanceTimeLeft: 1,
            stanceTimeRight: 2,
            activityType: 'RUN',
          },
          'id11': {
            time: '2020-07-06',
            activeTime: 500,
            totalDistance: 300,
            avgSpeed: 4,
            stanceTimeLeft: 1,
            stanceTimeRight: 2,
            activityType: 'RUN',
          },
          'id12': {
            time: '2020-07-05',
            activeTime: 1500,
            totalDistance: 1600,
            avgSpeed: 15,
            stanceTimeLeft: 1,
            stanceTimeRight: 2,
            activityType: 'RUN',
          },
        }
        return 
      }
      this.state = 1
      // console.log(this.authStore.id)
      const idToken = await this.authStore.getIdToken()
      
      const headers = { headers: { authentication: idToken, } }
      fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/trainings`, headers).then(async (res) => {  // eslint-disable-line no-undef

        if (res.status < 300) {
          // console.log(await res.text())
          try {
            let data = await res.json()
            // console.log(res, data)
            runInAction(() => {
              const filteredTrainings = data.filter((item) => {
                return item.fileStatus !== 'NOT_UPLOADED'
              })
              // console.log(filteredTrainings[5])
              
              this.trainings = {}
              for (let training of filteredTrainings) {
                this.trainings[training.id] = training
                this.trainings[training.id].zipStatus = 0

                // calculate extra values
                this.trainings[training.id].strideTimeLeft = training.swingTimeLeft + training.stanceTimeLeft
                this.trainings[training.id].strideTimeRight = training.swingTimeRight + training.stanceTimeRight
              }
            })
          } catch(e) {
            console.log('error parsing json for training', e)
          }
        }
        
      })

    }

    @action async fetchTraining(trainingId) {
      
      await this.fetchTrainings()
      
      if (!this.trainings[trainingId]) {
        return {}
      }
      
      if (this.trainings[trainingId].zipStatus !== 0) {
        return this.trainings[trainingId]
      }
      // console.log(trainingId)

      this.trainings[trainingId].zipStatus = 1
      const idToken = await this.authStore.getIdToken()

      const headers = { headers: { authentication: idToken, } }

      const zip = await fetch(`${process.env.REACT_APP_SERVER_URL}/patient/${this.authStore.id}/trainings/data/${trainingId}`, headers).then(async (res) => {  // eslint-disable-line no-undef
        return res.blob()
      }).then(JSZip.loadAsync)

      let training = this.trainings[trainingId]
      this.trainings[trainingId].zipStatus = 2

      const trainingZipData = await getZipData(training, zip, this.profileStore)

      runInAction(() => {
        // for(let result of results) {
        //   for (let item of result) {
        //     training[item.key] = item.data
        //   }
        // }
        // training.zipStatus = 3
        this.trainings[trainingId] = {
          ...trainingZipData,
          zipStatus: 3,
        }
      })
      return this.trainings[trainingId]
    }


    @action async getUniqueId(trainingId) {

      const idToken = await this.authStore.getIdToken()
      const headers = { headers: { authentication: idToken, } }
      fetch(`${process.env.REACT_APP_SERVER_URL}/patient/${this.authStore.id}/trainings/getUniqueId/${trainingId}`, headers).then(async (res) => {  // eslint-disable-line no-undef
        // console.log(res)
        
        
        if (res.status < 300) {
          // console.log(await res.text())
          try {
            // let data = await res.json()
            // console.log(res, data)
          } catch(e) {
            console.log('error parsing json for training', e)
          }
        }
        
      })
    }
    @action async isTrainingCalculated(trainingId) {

      const idToken = await this.authStore.getIdToken()
      const headers = { headers: { authentication: idToken, } }
      fetch(`${process.env.REACT_APP_SERVER_URL}/patient/${this.authStore.id}/trainings/isCalculated/${trainingId}/5000`, headers).then(async (res) => {  // eslint-disable-line no-undef
        // console.log(res)
        
        
        if (res.status < 300) {
          // console.log(await res.text())
          try {
            let data = await res.json()
            console.log(res, data)
          } catch(e) {
            console.log('error parsing json for training', e)
          }
        }
        
      })
    }


}
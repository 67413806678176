import {
  Paper, Tabs 
} from '@material-ui/core'
import {
  inject, observer 
} from 'mobx-react'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import StyledTab from '../../components/StyledTab'
import TabPanel from '../../components/TabPanel'
import { Feed } from './feed'
import Users from './users'

const Friends = withNamespaces()(inject('friends')(observer(({
  t, friends 
}) => {

  friends.fetchData()

  const handleTabChange = (event, value) => {
    friends.selectedTab = value
  }

  return(
    <>
      <Paper
        style={{
          marginTop: 20, marginBottom: 20 
        }}>
        <Tabs
          value={friends.selectedTab }
          onChange={handleTabChange}  
          indicatorColor="primary"
          aria-label="simple tabs example">
          {friends.list.length > 0 && <StyledTab
            label={t('friend_feed')}/>}
          <StyledTab
            label={t('friend_list')}/>
        </Tabs>
      </Paper>
      {friends.list.length > 0 && <TabPanel
        value={friends.selectedTab}
        index={0}>
        <Feed />

      </TabPanel>}
      <TabPanel 
        value={friends.selectedTab}
        index={friends.list.length > 0 ? 1 : 0}>
        <Users />
          
      </TabPanel>

    </>
  )
})))

export default Friends
import {
  Card, Typography 
} from '@material-ui/core'
import React, {
  useEffect, useState 
} from 'react'
import {
  formatDate,
  formatDistance, formatDuration, formatPercentage, formatSteps 
} from '../helper/formatting'
import ActivityTypeIcon from './ActivityTypeIcon'
import {
  MapContainer, TileLayer, Polyline
} from 'react-leaflet'
import { observer } from 'mobx-react'
import { withNamespaces } from 'react-i18next'

const TrainingItem = withNamespaces()( observer(({ 
  training, profile, t, name, onNavigate
}) => {

  const [map, setMap] = useState(null)
  const posData = training.posData || [[45.51, -122.68],
    [37.77, -122.43],
    [34.04, -118.2]]

  const handleNavigate = () => {
    if (onNavigate) {
      onNavigate(training)
    }
  }

  const onCreate = (map) => {
    setMap(map)
    map.fitBounds(posData)
  }

  useEffect(() => {
    // console.log('traiing changed', training.posData)
    if(map) {
      map.fitBounds(posData)
    }
  }, [training])


  const renderValues = () => {
    return <div
      className="valueContainer">
      {/* <ActivityTypeIcon type={training.activityType}/> */}
  
      <p className="value">
        {formatSteps( training.steps)} 
        {' '}
        {t('training_steps')}
      </p>
      {!isNaN(assymmetriSum) && assymmetriSum && <p  className="value">
        {formatPercentage(assymmetriSum, true)}
      </p>}
      <p  className="value">
        {formatDistance(training.totalDistance, profile.units)}
      </p>
      <p  className="value">
        {formatDuration( training.activeTime)}
      </p>
    </div>
  }

  const renderMap = () => {
    if (!training?.posData) {
      return null
    }
    return  <div
      style={{
        display: 'flex', flex: 1,
      }}>
      <MapContainer
        style={{ 
          width: '100%', minHeight: 200
        }}
        // center={currentLocation}
        scrollWheelZoom={false}
        whenCreated={onCreate}
        zoomControl={false}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        />

        <Polyline
          positions={training.posData} />

      </MapContainer>
    </div>
            
  }


  let stanceTimeLeft = training.stanceTimeLeft / (training.stanceTimeLeft + training.stanceTimeRight)
  let swingTimeLeft = training.swingTimeLeft / (training.swingTimeLeft + training.swingTimeRight)
  let stepTimeLeft = training.stepTimeLeft / (training.stepTimeLeft + training.stepTimeRight)

  let assymmetriSum = Math.abs(0.5-stanceTimeLeft) + Math.abs(0.5-swingTimeLeft) + Math.abs(0.5-stepTimeLeft)

  return (
    <Card
      key={training.id}
      className="trainingItem">
      {/* <Link to={`/dash/friends/${training.id}`}> */}
      <div
        className="row">
        <div
          className="textContainer"
          onClick={handleNavigate}>
          <div
            className={`topRow ${training?.posData ? 'topRowBorder' : ''}`}> 
            <div>
              <ActivityTypeIcon type={training.activityType} />
            </div>
            <div
              className="column">
              <Typography variant="h6">
                {name}
              </Typography>
              <Typography variant="caption">
                {/* {training.time} */}
                {formatDate(training.time, profile.dateFormat, profile.timeFormat)}
              </Typography>
            </div>
              
          </div>
          {!!training?.posData && renderValues()}
            
        </div>
        {renderMap()}
        {!training?.posData && renderValues()}
      </div>
      {/* <CardHeader
            avatar={
              <ActivityTypeIcon type={training.activityType}/>
            }
            title={friend?.fromPatient?.user?.email || 'Me'}
            subheader={training.time}
            action={
              <p>
                {formatDistance(training.totalDistance, profile.units)}
              </p>
            }
          /> */}
      {/* {renderMap()} */}
      {/* <CardContent>
                <p>
                  {formatDistance(item.distance, profile.units)}
                </p>
              </CardContent> */}
      {/* </Link> */}
    </Card>
  )
}))

export default TrainingItem

// profile store is responsible for fetching and storing the users profile. THe profile could be extended in the future with edit functionality.

import { 
  observable, 
  action, 
  runInAction, 
  computed, observe
} from 'mobx'

export class GoalStore {
    @observable goals = {};
    @observable state = 0;
    @observable rowsPerPage = 10
    @observable page = 0

    authStore = null

    constructor(authStore) {
      this.authStore = authStore

      observe(this.authStore, 'id', () => {
        this.goals = {}
        this.state = 0
      })
    }

    @computed get list() {
      return Object.values(this.goals)
    }

    @computed get sortedList() {
      return this.list.sort((a, b) => {
        if (a.time > b.time) {
          return -1
        } else if (b.time > a.time) {
          return 1
        }
        return 0
      })
    }

    @computed get count() {
      return this.list.length
    }

    @computed get goal() {
      return (trainingId) => {
        if (this.trainings[trainingId]) {
          if (!this.trainings[trainingId].zipLoaded) {
            this.fetchTraining(trainingId)
          }
          return this.trainings[trainingId]
        } else {
          this.fetchTraining(trainingId)
          return {}
        }
      }
    }

    getGoal(variable, period) {
      
      return this.list.find((item) => item.variable === variable && item.period === period)

    }

    getGoals(variable, period='ALL') {
      if (period !== 'ALL') {
        return this.ist.filter((item) => item.variable === variable && item.period === period)
      }
      return this.list.filter((item) => item.variable === variable)
    }

    @action async addGoal(variable, level, period) {

      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/goal/`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({ 
          variable, level, period 
        })
      }).then(async (res) => {
        let data = await res.json()
        console.log(data)
        this.goals[data.id] = data
        // this.trainings[]
        // console.log(res)
        // remove locally
        // delete this.trainings[trainingId]
        return true
        
      }).catch((e) => {
        console.log('error', e)
      })
    }


    @action async deleteGoal(goal) {

      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/goal/${goal.id}`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'DELETE',
      }).then(async (res) => {
        console.log(res)
        // remove locally
        delete this.goals[goal.id]
        return true
        
      }).catch((e) => {
        console.log('error', e)
      })

    }

    @action async updateGoal(goal, level) {
      this.goals[goal.id].level = level
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/goal/${goal.id}`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({ 
          ...goal, level
        })
      }).then(async (res) => {
        let data = await res.json()
        console.log(data)
        
        // remove locally
        // delete this.trainings[trainingId]
        return true
        
      }).catch((e) => {
        console.log('error', e)
      })
    }

    

    @action async fetchGoals() {
      if (this.state !== 0) {
        return
      }
      this.state = 1
      // console.log(this.authStore.id)

      // this.goals = {
      //   '1': {
      //     level: 3000,
      //     variable: 'steps',
      //     period: 'DAY'
      //   },
      //   '2': {
      //     level: 2000,
      //     variable: 'steps',
      //     period: 'WEEK'
      //   },
      //   '3': {
      //     level: 4000,
      //     variable: 'steps',
      //     period: 'MONTH'
      //   }, 
      //   '4': {
      //     level: 2,
      //     variable: 'numberOfTrainings',
      //     period: 'DAY'
      //   }, 
      //   '5': {
      //     level: 20*60, // 20 minuter
      //     variable: 'activeTime',
      //     period: 'DAY'
      //   }

      // }
      // return // TODO: remove for release
      
      // eslint-disable-next-line no-unreachable
      const headers = { headers: { authentication: await this.authStore.getIdToken(), } }
      fetch(`${process.env.REACT_APP_SERVER_URL}/patient/${this.authStore.id}/goal/`, headers).then(async (res) => {  // eslint-disable-line no-undef

        if (res.status < 300) {
          // console.log(await res.text())
          try {
            let data = await res.json()
            // console.log(res, data)
            runInAction(() => {
              console.log(data)
              this.goals = {}
              data.forEach(item => {
                this.goals[item.id] = item
              })
              // const filteredTrainings = data.filter((item) => {
              //   return item.fileStatus !== 'NOT_UPLOADED'
              // })
              // console.log(filteredTrainings[5])
              
              // this.trainings = {}
              // for (let training of filteredTrainings) {
              //   this.trainings[training.id] = training
              //   this.trainings[training.id].zipStatus = 0

              //   // calculate extra values
              //   this.trainings[training.id].strideTimeLeft = training.swingTimeLeft + training.stanceTimeLeft
              //   this.trainings[training.id].strideTimeRight = training.swingTimeRight + training.stanceTimeRight
              // }
            })
          } catch(e) {
            console.log('error parsing json for training', e)
          }
        }
        
      })

    }

    @action async fetchGoal(goalId) {
      
      
      if (!this.goals[goalId]) {
        return {}
      }
      

    }
}
import React from 'react'
import Graph from '../Graph'
import {
  formatSpeed, formatDistanceLowPrecision, formatForce, formatCadence, formatDistance, formatDuration 
} from '../../helper/formatting'
import { SpeedFormats } from '../../helper/constants'

const WalkingGraphs = ({ 
  trainings, profile, t, isDistance
}) => {
  if (!trainings || trainings.length === 0 || trainings[0].activityType !== 'WALK' ) {
    return null
  }
  // console.log(training.stanceTimeData)

  const distanceOverTime = []
  if (trainings[0].distanceData) {
    let sum = 0
    for (var i = 0; i < trainings[0].distanceData.length; i++) {
      const a = trainings[0].distanceData[i]
      sum += a[1]
      distanceOverTime.push([a[0], sum])
    }

  }

  // console.log(trainings.map((item) => (item.speedData || []).map(speed => [speed[0], speed[1]*3.6]) ))

  const distance = isDistance
  const xAxisLabel = distance ? t('training_distance') : t('graph_time')

  // console.log(distanceOverTime, trainings[0].distanceData, trainings[0].cadenceData)
  let single = trainings.length === 1
  
  return (
    <>

      <Graph
        // series={[training.cadenceData]}
        series={trainings.map((item) => item.cadenceData)}
        config={{
          label: t('graph_title_cadence'),
          xAxis: xAxisLabel,
          // yAxis: ''
          tickInterval: 1,
          valueFormatter: (value) => {
            return formatCadence(value)
          },
          labels: single ? [t('graph_title_cadence')] : trainings.map((item) => item.time),
          distance,
          distanceOverTime: distanceOverTime
        }} />
      <Graph
        // series={[training.speedData]}
        series={trainings.map((item) => (item.speedData || []).map(speed => [speed[0], speed[1]*3.6]) )}
        config={{
          label: t('graph_title_speed'),
          xAxis: xAxisLabel,
          // yAxis: '(m/s)',
          tickInterval: 1,
          valueFormatter: (value) => {
            return formatSpeed(value/3.6, SpeedFormats.KILOMETER_HOUR, profile.units)
          },
          // labels: [t('graph_title_speed')]
          labels: single ? [t('graph_title_speed')] : trainings.map((item) => item.time),
          distance,
          distanceOverTime: distanceOverTime
        }} />
      <Graph
        // series={[training.altitudeData]}
        series={trainings.map((item) => item.altitudeData)}
        config={{
          label: t('graph_title_altitude'),
          xAxis: xAxisLabel,
          // yAxis: '(m)',
          tickInterval: 1,
          valueFormatter: (value) => {
            return formatDistanceLowPrecision(value, profile.units)
          },
          // labels: [t('graph_title_altitude')]
          labels: single ? [t('graph_title_altitude')] : trainings.map((item) => item.time),
          distance,
          distanceOverTime: distanceOverTime
        }} />
      <Graph
        // series={[training.stepLengthData]}
        series={trainings.map((item) => item.stepLengthData)}
        config={{
          label: t('graph_title_stepLength'),
          xAxis: xAxisLabel,
          // yAxis: '(m)'
          tickInterval: 0.01,
          valueFormatter: (value) => {
            return formatDistance(value, profile.units)
          },
          // labels: [t('graph_title_stepLength')]
          labels: single ? [t('graph_title_stepLength')] : trainings.map((item) => item.time),
          distance,
          distanceOverTime: distanceOverTime
        }} />

      {/* Markkontakttid */}
      <Graph
        // series={[training.stanceTimeData]}
        series={trainings.map((item) => item.stanceTimeData)}
        config={{
          label: t('graph_title_stanceTime'),
          xAxis: xAxisLabel,
          // yAxis: ''
          tickInterval: 0.01,
          valueFormatter: (value) => {
            return formatDuration(value)
          },
          // labels: [t('graph_title_stanceTimeAverage')]
          labels: single ? [t('graph_title_stanceTime')] : trainings.map((item) => item.time),
          distance,
          distanceOverTime: distanceOverTime
        }} />
      <Graph
        // series={[training.stanceTimeDataLeft, training.stanceTimeDataRight]}
        series={single ? [trainings[0].stanceTimeDataLeft, trainings[0].stanceTimeDataRight] : trainings.reduce((reducer, item) => [...reducer, item.stanceTimeDataLeft], [])}
        config={{
          label: t('graph_title_stanceTimeLeftRight'),
          xAxis: xAxisLabel,
          // yAxis: ''
          tickInterval: 0.01,
          valueFormatter: (value) => {
            return formatDuration(value)
          },
          colors: [ '#bd0850', '#103cd6'],
          labels: single ? 
            [t('graph_title_stanceTimeLeft'), t('graph_title_stanceTimeRight')] : 
            trainings.reduce((reducer, item) => [...reducer, `${item.time} - ${t('graph_left')}`], []),
          distance,
          distanceOverTime: distanceOverTime
        }} />

      {/* Fot i luften (Svingtid) */}
      <Graph
        // series={[training.swingTimeData]}
        series={trainings.map((item) => item.swingTimeData)}
        config={{
          label: t('graph_title_swingTimeAverage'),
          xAxis: xAxisLabel,
          // yAxis: ''
          tickInterval: 0.01,
          valueFormatter: (value) => {
            return formatDuration(value)
          },
          // labels: [t('graph_title_swingTimeAverage')]
          labels: single ? [t('graph_title_swingTimeAverage')] : trainings.map((item) => item.time),
          distance,
          distanceOverTime: distanceOverTime
        }} />
      <Graph
        // series={[training.swingTimeDataLeft, training.swingTimeDataRight]}
        // series={trainings.map((item) => item.swingTimeDataLeft)}
        series={single ? [trainings[0].swingTimeDataLeft, trainings[0].swingTimeDataRight] : trainings.reduce((reducer, item) => [...reducer, item.swingTimeDataLeft], [])}
        config={{
          label: t('graph_title_swingTimeLeftRight'),
          xAxis: xAxisLabel,
          // yAxis: ''
          tickInterval: 0.01,
          valueFormatter: (value) => {
            return formatDuration(value)
          },
          colors: [ '#bd0850', '#103cd6'],
          // labels: [t('graph_title_swingTimeLeft'), t('graph_title_swingTimeRight')]
          labels: single ? 
            [t('graph_title_swingTimeLeft'), t('graph_title_swingTimeRight')] : 
            trainings.reduce((reducer, item) => [...reducer, `${item.time} - ${t('graph_left')}`], []),
          distance,
          distanceOverTime: distanceOverTime
        }} />

      {/* Stegtid */}
      <Graph
        // series={[training.stepTimeData]}
        series={trainings.map((item) => item.stepTimeData)}
        config={{
          label: t('graph_title_stepTimeAverage'),
          xAxis: xAxisLabel,
          // yAxis: ''
          tickInterval: 0.01,
          valueFormatter: (value) => {
            return formatDuration(value)
          },
          // labels: [t('graph_title_stepTimeAverage')]
          labels: single ? [t('graph_title_stepTimeAverage')] : trainings.map((item) => item.time),
          distance,
          distanceOverTime: distanceOverTime
        }} />
      <Graph
        // series={[training.stepTimeDataLeft, training.stepTimeDataRight]}
        // series={trainings.map((item) => item.stepTimeDataLeft)}
        series={single ? [trainings[0].stepTimeDataLeft, trainings[0].stepTimeDataRight] : trainings.reduce((reducer, item) => [...reducer, item.stepTimeDataLeft], [])}
        config={{
          label: t('graph_title_stepTimeLeftRight'),
          xAxis: xAxisLabel,
          // yAxis: ''
          valueFormatter: (value) => {
            return formatDuration(value)
          },
          tickInterval: 0.01,
          colors: [ '#bd0850', '#103cd6'],
          // labels: [t('graph_title_stepTimeLeft'), t('graph_title_stepTimeRight')]
          labels: single ? 
            [t('graph_title_stepTimeLeft'), t('graph_title_stepTimeRight')] : 
            trainings.reduce((reducer, item) => [...reducer, `${item.time} - ${t('graph_left')}`], []),
          distance,
          distanceOverTime: distanceOverTime
        }} />
      <Graph
        // series={[training.strideTimeData]}
        series={trainings.map((item) => item.strideTimeData)}
        config={{
          label: t('graph_title_strideTime'),
          xAxis: xAxisLabel,
          // yAxis: '(N)'
          tickInterval: 0.01,
          valueFormatter: (value) => {
            return formatDuration(value)
          },
          // labels: [t('graph_title_strideTime')]
          labels: single ? [t('graph_title_strideTime')] : trainings.map((item) => item.time),
          distance,
          distanceOverTime: distanceOverTime
        }} />
      <Graph
        // series={[training.strideTimeDataLeft, training.strideTimeDataRight]}
        // series={trainings.map((item) => item.strideTimeDataLeft)}
        series={single ? [trainings[0].strideTimeDataLeft, trainings[0].strideTimeDataRight] : trainings.reduce((reducer, item) => [...reducer, item.strideTimeDataLeft], [])}
        config={{
          label: t('graph_title_strideTimeLeftRight'),
          xAxis: xAxisLabel,
          // yAxis: ''
          tickInterval: 0.01,
          valueFormatter: (value) => {
            return formatDuration(value)
          },
          colors: [ '#bd0850', '#103cd6'],
          // labels: [t('graph_title_strideTimeLeft'), t('graph_title_strideTimeRight')]
          labels: single ? 
            [t('graph_title_strideTimeLeft'), t('graph_title_strideTimeRight')] : 
            trainings.reduce((reducer, item) => [...reducer, `${item.time} - ${t('graph_left')}`], []),
          distance,
          distanceOverTime: distanceOverTime
        }} />
      <Graph
        // series={[training.maxForceData]}
        series={trainings.map((item) => item.maxForceData)}
        config={{
          label: t('graph_title_maxForce'),
          xAxis: xAxisLabel,
          // yAxis: '(N)'
          tickInterval: 10,
          valueFormatter: (value) => {
            return formatForce(value)
          },
          // labels: [t('graph_title_maxForce')]
          labels: single ? [t('graph_title_maxForce')] : trainings.map((item) => item.time),
          distance,
          distanceOverTime: distanceOverTime
        }} />
    </>
  )
}

export default WalkingGraphs
import React, { useState } from 'react'
import {
  Button, Box, Typography, InputAdornment, IconButton 
} from '@material-ui/core'
import {
  inject, observer 
} from 'mobx-react'
import {
  Link, useHistory 
} from 'react-router-dom'
import {
  Email, Lock, Visibility, VisibilityOff 
} from '@material-ui/icons'
import GoogleButton from 'react-google-button'
import AppleButton from '../components/AppleButton'
import LoginContainer from '../components/LoginContainer'
import InputField from '../components/InputField'
import { withNamespaces } from 'react-i18next'

var Login = withNamespaces()( inject('auth')(observer(({ 
  auth, t 
}) => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const history = useHistory()

  const loginWithGoogle = () => {
    auth.loginWithGoogle().then(() => {
      history.push('/dash/')
    })
  }
  const loginWithApple = () => {
    auth.loginWithApple().then(() => {
      history.push('/dash/')
      
    }).catch((e) => {
      console.log(e)
      
    })
  }
  const loginWithEmailAndPassword = (event) => {
    if (event.preventDefault) {
      event.preventDefault()
    }
    setEmailError('')
    setPasswordError('')
    auth.loginWithEmailAndPassword(email, password).then(() => {
      // redirect to dash
      history.push('/dash/')

      
    }).catch((e) => {
      console.log('error: ', e)
      if (e.code === 'auth/invalid-email') {
        setEmailError(t('login_invalidEmail'))
      } else if (e.code === 'auth/wrong-password') {
        setPasswordError(t('login_wrongPassword'))
      } else if (e.code === 'auth/too-many-requests') {
        setPasswordError(t('login_tooManyTries'))
      } else if (e.code === 'auth/user-not-found') {
        setEmailError(t('login_userNotFound'))
      }
    })
  }

  const handleEmailChange = (event) => {
    event.persist()
    setEmail(event.target.value)
  }
  const handlePasswordChange = (event) => {
    event.persist()
    setPassword(event.target.value)
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return(
    <LoginContainer>
      <Box
        mb={2}
        textAlign="center"
        fontWeight="fontWeightBold">
        <Typography variant="h3" >
          {t('login_title')}
        </Typography>
      </Box>
      <form onSubmit={loginWithEmailAndPassword} >
        <InputField
          label={t('login_email')}
          id="email" 
          type="email" 
          onChange={handleEmailChange} 
          value={email} 
          errorMessage={emailError} 
          placeholder={t('login_emailPlaceholder')}
          icon={<Email  color="disabled"/>}
        />

        <InputField
          label={t('login_password')}
          id="password" 
          type={showPassword ? 'text' : 'password'}
          onChange={handlePasswordChange} 
          value={password} 
          errorMessage={passwordError} 
          placeholder={t('login_passwordPlaceholder')}
          icon={<Lock  color="disabled"/>}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleClickShowPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {passwordError !== '' && 
        <Link to="/forgot">
          {t('login_forgotPassword')}
        </Link> 
        }

        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{
            textTransform: 'none',
            marginTop: 20,
            width: '100%' 
          }}
          onClick={loginWithEmailAndPassword}>
          {t('login_loginButton')}
        </Button>
      </form>

      <Box
        textAlign="center"
        my={2}>
        <Typography style={{ color: 'gray' }}>
          {t('login_orUse')}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center" >
        <GoogleButton
          onClick={loginWithGoogle}
          style={{ marginBottom: 10 }}
          label={t('login_signinGoogle')} />
        <AppleButton
          onClick={loginWithApple}
          label={t('login_signinApple')} />
      </Box>
      <Box
        textAlign="center"
        mt={2}>
        <Typography style={{ color: 'gray' }}>
          {t('login_notMember')}
          <Link
            style={{
              color: 'gray',
              fontWeight: 'bold',
              marginLeft: 5 
            }}
            to="/create">
            {t('login_signup')}
          </Link>
        </Typography>
      </Box>
    </LoginContainer>
  )
})))

export default Login

// old service id com.medotemic.medosport.test
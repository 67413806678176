import React, { useState } from 'react'
import {
  inject, observer 
} from 'mobx-react'
import {
  TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, TablePagination, Checkbox, Button, Typography, Box 
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import {
  formatDuration, formatDate, formatDistanceLowPrecision, formatSteps
} from '../helper/formatting'
import ActivityTypeIcon from '../components/ActivityTypeIcon'
import { withNamespaces } from 'react-i18next'
import WalkingGraphs from '../components/graphs/walking'
import RunningGraphs from '../components/graphs/running'
import GenerateReportModal from '../components/GenerateReportModal'

function descendingComparator(a, b, orderBy) {
  // console.log(a[orderBy], b[orderBy], b[orderBy] < a[orderBy])
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
  // return b[orderBy]-a[orderBy]
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

var Trainings = withNamespaces()(inject('auth', 'trainings', 'profile')(observer(({
  trainings, t, profile
}) => {


  const [orderBy, setOrderBy] = useState('time')
  const [order, setOrder] = useState('desc')
  const [checked, setChecked] = useState([])
  const [comparing, setComparing] = useState([])
  const [showReport, setshowReport] = useState(false)
  const history = useHistory()
  

  trainings.fetchTrainings().then(() => {
    // console.log(res)
    
  })

  const handleRequestSort = (property) => {
    // console.log(property)
    
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const sortedData = () => {

    let nonNullValues = trainings.list
      .filter((item) => item[orderBy] !== null && item[orderBy] !== undefined)
    let nullValues = trainings.list
      .filter((item) => item[orderBy] === null || item[orderBy] === undefined)

    let res = nonNullValues.sort(getComparator(order, orderBy))
    
    if (order === 'desc') {
      res.push(...nullValues)
    } else {
      res.unshift(...nullValues)
    }

    // console.log(res)
    return res.slice(trainings.page * trainings.rowsPerPage, (trainings.page+1) * trainings.rowsPerPage)
    // console.log(trainings.list.sort(getComparator(order, orderBy)).map((item => item.time)))
    // console.log(trainings.list.sort(getComparator(order, orderBy)).map((item => item.time)).sort().reverse())
    // return stableSort(trainings.list, getComparator(order, orderBy))
    //   .slice(trainings.page * trainings.rowsPerPage, (trainings.page+1) * trainings.rowsPerPage)

    // console.log(null < '2020-07-14')
    // console.log(null > '2020-07-14')
    // console.log(null == '2020-07-14')
    // return trainings.list
    //   .filter((item) => item[orderBy] !== null)
    //   .sort((a, b) => {
    //     if (b[orderBy] < a[orderBy]) {
    //       return -1
    //     }
    //     if (b[orderBy] > a[orderBy]) {
    //       return 1
    //     }
    //     return 0
    //   })
    //   .slice(trainings.page * trainings.rowsPerPage, (trainings.page+1) * trainings.rowsPerPage)
  }

  const handleChangePage = (event, newPage) => {
    trainings.page = newPage
  }

  const handleChangeRowsPerPage = (event) => {
    trainings.rowsPerPage = parseInt(event.target.value, 10)
    trainings.page = 0
  }

  const selectRow = (item) => {
    history.push(`/dash/trainings/${item.id}`)
  }

  const isItemChecked = (item) => {
    return checked.includes(item.id)
  }

  const checkRow = (e, item) => {
    e.stopPropagation()
    if (isItemChecked(item)) {
      setChecked(checked.filter((id) => id !== item.id))
    } else {
      setChecked([...checked, item.id])
    }
  }

  const compare = () => {
    setComparing(checked)
  }

  const generateReport = () => {
    setshowReport(true)
  }

  const canCompare = () => {
    let hasWalk = false
    let hasRun = false
    getTrainings(checked).forEach((item) => {
      if (item.activityType === 'WALK') {
        hasWalk = true
      } else {
        hasRun = true
      }
    })
    return !(hasWalk && hasRun)
  }


  const getTrainings = (comparing) => {
    return comparing.map((id) => {
      let training = trainings.list.find((item) =>  item.id === id)
      // console.log(training)
      
      if (!training) return null

      if (training.zipStatus === 0) { // test if their data is fetched. If not, fetch it now
        // fetch speed data
        trainings.fetchTraining(training.id)
      }

      return training
    })
  }

  const renderComparisons = () => {
    if (comparing.length <= 0) return null

    let trainings = getTrainings(comparing)

    // check if all is loaded, else render loading
    
    if (trainings.some((item) => {
      // if an item is null, it is still loading
      if (!item) {
        return true
      }
      return item.zipStatus !== 3
    })) {
      return <p>
        {t('trainings_loading')}
      </p>
    }
    

    return <>

      <WalkingGraphs
        trainings={trainings}
        profile={profile}
        t={t} />
      <RunningGraphs
        trainings={trainings}
        profile={profile}
        t={t} />

    </>
  }
  

  return(
    <div>
      <div
        style={{ 
          flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center'
        }}>
        <h1>
          {t('trainings_title')}
        </h1>
        <Button
          onClick={generateReport}
          variant="contained"
          disabled={checked.length > 0}
          color="primary">
          {t('report_button')}
        </Button>
      </div>
      <Paper>
        <TableContainer>
          <Table aria-label={t('trainings_title')}>
            <TableHead>
              <TableRow>
                <TableCell
                  padding="checkbox"
                  style={{ paddingLeft: 20 }}
                  sortDirection={orderBy === 'activityType' ? order : false}>
                  <TableSortLabel 
                    onClick={() => {handleRequestSort('activityType')}} 
                    active={orderBy === 'activityType'}
                    direction={orderBy === 'activityType' ? order : 'desc'}>
                    {t('trainings_activityType')}
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'time' ? order : false}>
                  <TableSortLabel 
                    onClick={() => {handleRequestSort('time')}} 
                    active={orderBy === 'time'}
                    direction={orderBy === 'time' ? order : 'desc'}>
                    {t('trainings_date')}
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'totalDistance' ? order : false}>
                  <TableSortLabel 
                    onClick={() => {handleRequestSort('totalDistance')}} 
                    active={orderBy === 'totalDistance'}
                    direction={orderBy === 'totalDistance' ? order : 'desc'}>
                    {t('trainings_distance')}
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'activeTime' ? order : false}>
                  <TableSortLabel 
                    onClick={() => {handleRequestSort('activeTime')}} 
                    active={orderBy === 'activeTime'}
                    direction={orderBy === 'activeTime' ? order : 'desc'}>
                    {t('trainings_time')}
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'steps' ? order : false}>
                  <TableSortLabel 
                    onClick={() => {handleRequestSort('steps')}} 
                    active={orderBy === 'steps'}
                    direction={orderBy === 'steps' ? order : 'desc'}>
                    {t('training_steps')}
                  </TableSortLabel>
                </TableCell>
                <TableCell padding="checkbox"  />
              </TableRow>
            </TableHead>
            <TableBody>
              {
                sortedData().map((item) => {
                  {/* console.log(toJS(item)) */}
                  return (<TableRow
                    key={item.id}
                    hover
                    onClick={() => {selectRow(item)}}
                    style={{ cursor: 'pointer' }}>
                    
                    <TableCell
                      padding="checkbox"
                      style={{ paddingLeft: 20 }} >
                      <ActivityTypeIcon type={item.activityType}/>
                    </TableCell>
                    <TableCell >
                      {formatDate(item.time, profile.dateFormat, profile.timeFormat)}
                    </TableCell>
                    <TableCell >
                      {formatDistanceLowPrecision(item.totalDistance, profile.units)}
                    </TableCell>
                    <TableCell >
                      {formatDuration(item.activeTime)}
                    </TableCell>
                    <TableCell >
                      {formatSteps(item.steps)}
                    </TableCell>
                    <TableCell
                      padding="checkbox"
                      onClick={(e) => checkRow(e, item)}>
                      <Checkbox
                        color="default"
                        checked={isItemChecked(item)} />
                    </TableCell>
                  </TableRow>)
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination 
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={trainings.count}
          rowsPerPage={trainings.rowsPerPage}
          page={trainings.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={t('trainings_rowsPerPage')}
        />
      </Paper>
      <Box
        style={{ 
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: 20
        }}>
        <Typography>
          {t('trainings_how_to_use')}
        </Typography>
        { checked.length >= 2 &&
        <Button
          onClick={compare}
          variant="contained"
          disabled={!canCompare()}
          color="primary">
          {t('trainings_compare')}
        </Button>
        }
        { checked.length === 1 &&
        <Button
          onClick={compare}
          variant="contained"
          color="primary">
          {t('trainings_show')}
        </Button>
        }
      </Box>
      {!canCompare() && <p style={{ textAlign: 'right' }}>
        You can not compare walking and running at the same time
      </p>}

      {renderComparisons()}
      <GenerateReportModal
        open={showReport}
        onClose={() => setshowReport(false)} />
      
    </div>
  )
})))

export default Trainings



import React, { useState } from 'react'
import {
  Button, Typography, Box, FormControlLabel
} from '@material-ui/core'
import { Checkbox } from '@material-ui/core'
import {
  inject, observer
} from 'mobx-react'
import {
  Email, Lock
} from '@material-ui/icons'
import {
  Link, useHistory 
} from 'react-router-dom'
import LoginContainer from '../components/LoginContainer'
import GoogleButton from 'react-google-button'
import AppleButton from '../components/AppleButton'
import InputField from '../components/InputField'
import {
  withNamespaces, Trans
} from 'react-i18next'

var CreateAccount = withNamespaces()( inject('auth')(observer(({
  auth, t
}) => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [password2Error, setPassword2Error] = useState('')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [termsHighlighted, setTermsHighlighted] = useState(false)

  const history = useHistory()

  const loginWithGoogle = () => {
    if (!termsAccepted) {
      highLightTerms()
      return
    }
    auth.loginWithGoogle().then(() => {
      history.push('/dash/')
    })
  }
  const loginWithApple = () => {
    if (!termsAccepted) {
      highLightTerms()
      return
    }
    auth.loginWithApple().then(() => {
      history.push('/dash/')
    })
  }
  const createEmailAndPasswordAccount = (event) => {
    if (event.preventDefault) {
      event.preventDefault()
    }
    if (!termsAccepted) {
      highLightTerms()
      return
    }
    if(password && password2 && password !== password2) {
      setPassword2Error(t('login_wrongPassword2'))
      return
    }
    auth.createEmailAndPasswordAccount(email, password).then(() => {
      console.log('HISTORY', history)
      history.push('/dash/')
    }).catch((e) => {
      console.log('error: ', e)

      if (e.code === 'auth/invalid-email') {
        setEmailError(t('login_invalidEmail'))
      } else if (e.code === 'auth/wrong-password') {
        setPasswordError(t('login_wrongPassword'))
      } else if (e.code === 'auth/weak-password') {
        setPasswordError(e.message)
      } else if (e.code === 'auth/email-already-in-use') {
        setEmailError(t('login_emailAlreadyUse'))
      }
    })
  }

  const handleEmailChange = (event) => {
    event.persist()
    setEmail(event.target.value)
    setTermsHighlighted(false)
  }

  const handlePasswordChange = (event) => {
    event.persist()
    setPassword(event.target.value)
    setTermsHighlighted(false)
  }

  const handlePassword2Change = (event) => {
    event.persist()
    setPassword2(event.target.value)
    setTermsHighlighted(false)
  }

  const handleTermsChange = (event) => {
    console.log(event.target.checked)

    setTermsAccepted(event.target.checked)
    setTermsHighlighted(false)
  }

  const highLightTerms = () => {
    setTermsHighlighted(true)
  }

  return(
    <LoginContainer>
      <Box
        mb={2}
        textAlign="center"
        fontWeight="fontWeightBold">
        <Typography variant="h3" >
          {t('login_signupTitle')}
        </Typography>
      </Box>
      <form onSubmit={createEmailAndPasswordAccount}>
        <InputField
          label={t('login_email')}
          id="email"
          type="email"
          onChange={handleEmailChange}
          value={email}
          errorMessage={emailError}
          placeholder={t('login_emailPlaceholder')}
          icon={<Email  color="disabled"/>}
        />

        <InputField
          label={t('login_password')}
          id="password"
          type="password"
          onChange={handlePasswordChange}
          value={password}
          errorMessage={passwordError}
          placeholder={t('login_passwordPlaceholder')}
          icon={<Lock  color="disabled"/>}
        />

        <InputField
          label={t('login_password2')}
          id="password2"
          type="password"
          onChange={handlePassword2Change}
          value={password2}
          errorMessage={password2Error}
          placeholder={t('login_password2Placeholder')}
          icon={<Lock  color="disabled"/>}
        />
        <Box
          textAlign="center">
          <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={handleTermsChange}
                name="checkedB"
                color="primary"
              />
            }
            label={
              (
                <Typography
                  style={{
                    color: termsHighlighted ? 'red' : 'gray',
                    maxWidth:250,
                    marginLeft: 10 ,
                    textAlign: 'left'
                  }}>
                  <Trans
                    i18nKey="login_accept_terms">
                    login_accept
                    <Link
                      to="/termsOfService"
                      style={{
                        color: 'gray',
                        fontWeight: 'bold',
                        marginLeft: 5,
                        marginRight: 5
                      }}>
                      terms
                    </Link>
                    and
                    <Link
                      to="/privacyPolicy"
                      style={{
                        color: 'gray',
                        fontWeight: 'bold',
                        marginLeft: 5
                      }}>
                      privacy
                    </Link>
                  </Trans>
                </Typography>
              )
            }
          />
        </Box>


        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!termsAccepted}
          style={{
            textTransform: 'none',
            marginTop: 20,
            width: '100%',
          }}
          onClick={createEmailAndPasswordAccount}>
          {t('login_createAccountButton')}
        </Button>
      </form>


      <Box
        textAlign="center"
        my={2}>
        <Typography style={{ color: 'gray' }}>
          {t('login_orUse')}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center" >
        <GoogleButton
          onClick={loginWithGoogle}
          style={{ marginBottom: 10 }}
          disabled={!termsAccepted}
          label={t('login_signinGoogle')} />
        <AppleButton
          onClick={loginWithApple}
          disabled={!termsAccepted}
          label={t('login_signinApple')} />
      </Box>
      <Box
        textAlign="center"
        mt={2}>
        <Typography style={{ color: 'gray' }}>
          {t('login_haveAccount')}
          <Link
            style={{
              color: 'gray',
              fontWeight: 'bold',
              marginLeft: 5
            }}
            to="/login">
            {t('login_signin')}
          </Link>
        </Typography>
      </Box>
    </LoginContainer>
  )
})))

export default CreateAccount

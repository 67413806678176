import React from 'react'
import {
  ButtonGroup, Button 
} from '@material-ui/core'

const SelectButtons = ({ 
  data, selected, onChange
}) => {
  return (
    <ButtonGroup
      size="large"
      color="primary"
      aria-label="large outlined primary button group">
      {
        data.map((item) => {
          return <Button
            key={item.key}
            variant={item.key === selected ? 'contained' : null}
            onClick={() => onChange(item.key)}>
            {item.label}
          </Button>
        })
      }
    </ButtonGroup>
  )
}
export default SelectButtons
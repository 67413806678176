import React from 'react'
import {
  inject, observer
} from 'mobx-react'
import {
  useParams, useHistory 
} from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import TrainingComponent from '../components/Training'


var Training = withNamespaces()(inject('trainings', 'profile')(observer(({
  trainings, t
}) => {

  let { trainingId } = useParams()
  const history = useHistory()
  const training = trainings.training(trainingId)

  const goBack = () => {
    history.goBack()
  }

  const deleteTraining = () => {
    // verify
    if (window.confirm(t('training_deleteConfirm'))) {

      trainings.deleteTraining(trainingId).then(() => {
        //success,
        history.goBack()
      })
    }
  }

  return(
    <TrainingComponent
      training={training}
      goBack={goBack}
      deleteTraining={deleteTraining}
      reportEnabled />
  )
})))

export default Training
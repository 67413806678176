import React from 'react'
import BackgroundImage from './BackgroundImage'
import {
  Paper, Box, makeStyles 
} from '@material-ui/core'

const useStyles = makeStyles(() => ({ backgroud: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

} }))

const LoginContainer = ({ children }) => {

  const classes = useStyles()

  return(
    <BackgroundImage
      className={classes.backgroud}
      style={{
        paddingTop: 300,
        paddingBottom: 100 
      }}>
      <Paper>
        <Box
          p={5}
          pb={1} >
          {children}
        </Box>
      </Paper>
    </BackgroundImage>
  )

}
export default LoginContainer
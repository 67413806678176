import React from 'react'
import {
  Paper, Grid 
} from '@material-ui/core'

const DashboardBase = ({ children }) => {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={4}>
      <Paper
        style={{
          padding: 20, paddingBottom: 5, overflow:'hidden'
        }}>
        {children}
      </Paper>
    </Grid>
  )
}
export default DashboardBase
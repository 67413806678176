// profile store is responsible for fetching and storing the users profile. THe profile could be extended in the future with edit functionality.

import JSZip from 'jszip'
import {
  computed,
  observable, observe, runInAction, toJS, 
} from 'mobx'
import { getZipData } from '../helper/training'

export class FriendsStore {
    @observable list =[]

    @observable feed = []

    @observable trainings = {}

    @observable requests = []

    @observable selectedTab = 0;

    @observable whatIShareItems = {};

    hasFetchedFeed = false;
    hasFetchedFriends = false;
    hasFetchedRequests = false;

    authStore = null
    profileStore = null

    constructor(authStore, profileStore) {
      this.authStore = authStore
      this.profileStore = profileStore

      observe(this.authStore, 'id', () => {
        // this.goals = {}
        // this.state = 0
        console.log('authstore change', this.authStore.id)
        if (this.authStore.id) {
          this.hasFetchedFeed = false
          // this.fetchData()
          this.getRequests()
        } else {
          this.hasFetchedFeed = false
          this.hasFetchedFriends = false
          this.hasFetchedRequests = false
          this.list = []
          this.feed = []
          this.trainings = {}
          this.requests = []
          this.whatIShareItems = {}
        }
      })

      setInterval(() => {
        if (this.authStore.id) {
          this.hasFetchedRequests = false
          this.getRequests()
        }
      }, 20000) // 20 seconds
      
    }

    @computed get training() {
      return (trainingId) => {
        if (this.trainings[trainingId]) {
          if (!this.trainings[trainingId].zipLoaded) {
            this.getTraining(trainingId)
          }
          return this.trainings[trainingId]
        } else {
          this.getTraining(trainingId)
          return {}
        }
      }
    }

    @computed get friend() {
      return (friendId) => {
        const friend = this.list.find((item) => {
          return item.id == friendId}) // either one could be a string so use == to find them aswell
        if (friend) {
          return friend
        } else {
          this.getFriends()
          return {}
        }
      }
    }

    @computed get friendByUserId() {
      return (userId) => {
        // console.log(userId, toJS(this.list))
        return this.list.find((item) => item.fromPatient.user.id === userId)
      }
    }

    @computed get friendFeed() {
      return (friendUserId) => {
        return this.feed.filter((item) => item.userId === friendUserId) || []
      }
    }

    @computed get whatIShare() {
      return (friendId) => {
        if (this.whatIShareItems[friendId]) {
          return this.whatIShareItems[friendId]
        } else {
          return {}
        }
      }
    }

    @computed get requestToAnswer() {
      return this.requests.filter((item) => item.toPatient.user.id === this.authStore.id)
    }

    @computed get requestRecieved() {
      return this.requests.filter((item) => item.toPatient.user.id === this.authStore.id)
    }

    @computed get requestSent() {
      return this.requests.filter((item) => item.toPatient.user.id !== this.authStore.id)
    }

    async fetchData() {
      await this.getFriends()
      this.getFeed()
      this.getRequests()
    }


    async getFriends() {
      if (this.hasFetchedFriends) {
        return
      }
      this.hasFetchedFriends = true
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/friends/`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'GET',
      }).then(async (res) => {
        let data = await res.json()
        console.log('friends', data)
        data.forEach((item) => {
          if (item.fromPatient?.user?.id === this.authStore.id) {
            this.whatIShareItems[item.toPatient?.user?.id] = item
          }
        })
        this.list = data.filter((item) => item.toPatient?.user?.id === this.authStore.id)
        return true
        
      }).catch((e) => {
        console.log('friends', e)
      })
    }

    async getFeed() {
      if (this.hasFetchedFeed) {
        return
      }
      this.hasFetchedFeed = true
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/friends/feed`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'GET',
      }).then(async (res) => {
        let data = await res.json()
        console.log('feed', data)
        this.feed = data.filter((item) => {
          return (item.fileStatus !== 'NOT_UPLOADED' && item.fileStatus !== 'FILE_NOT_FOUND')
        }).sort((a, b) => {
          if (a.time < b.time) {
            return 1
          }
          if (a.time > b.time) {
            return -1
          }
          // a must be equal to b
          return 0
        })
        console.log(toJS(this.feed))
        this.trainings = {}
        this.feed.forEach((item) => {
          this.trainings[item.id] = {
            ...item,
            zipStatus: 0
          }
          // this.getTraining(item.id).then(() => {
          //   console.log('training fetched')
          // })
        })
        return true
        
      }).catch((e) => {
        console.log('friends', e)
      })
    }

    async getTraining(trainingId) {

      await this.getFeed()
      
      // console.log('get training', trainingId)
        
      if (!this.trainings[trainingId]) {
        return {}
      }
        
      if (this.trainings[trainingId].zipStatus !== 0) {
        return this.trainings[trainingId]
      }
      // console.log('fetching feed', this.trainings[trainingId])
  
      this.trainings[trainingId].zipStatus = 1
      const idToken = await this.authStore.getIdToken()
  
      const headers = { headers: { authentication: idToken, } }

  
      // eslint-disable-next-line no-undef
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/patient/${this.authStore.id}/friends/training/${trainingId}`, headers)

      if (res.status > 299) {
        return []
      }

      const zipBlob = await res.blob()
      // }).then(JSZip.loadAsync).catch((e) => {
      //   console.log('fetch zip error', e)
      // })
      const zip = await JSZip.loadAsync(zipBlob)

      // // console.log(zip)
  
      let training = this.trainings[trainingId]
      this.trainings[trainingId].zipStatus = 2
  
      const trainingZipData = await getZipData(training, zip, this.profileStore)
      // console.log(toJS(trainingZipData))
  
      runInAction(() => {
        // for(let result of results) {
        //   for (let item of result) {
        //     training[item.key] = item.data
        //   }
        // }
        // training.zipStatus = 3
        this.trainings[trainingId] = {
          ...trainingZipData,
          zipStatus: 3,
        }
      })
      return this.trainings[trainingId]
    }

    async invite(email, shareTrainings, shareLocation, shareReports) {
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/friends/invite`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
          email: email,
          shareTrainings: shareTrainings,
          shareLocation: shareLocation,
          shareProfile: true,
          shareReports
        })
      }).then(async (res) => {
        if (res.status < 300) {
          let data = await res.json()
          this.requests = [...this.requests, data]
        } else {
          if (res.text) {
            return res.text().then((value) => {
              throw value
            })
          }
        }
        return true
        
      }) 
    }

    async getRequests() {
      if (this.hasFetchedRequests) {
        return
      }
      this.hasFetchedRequests =true
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/friends/invites`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'GET',
      }).then(async (res) => {
        let data = await res.json()
        console.log('invites response', data)
        this.requests = data
        return true
        
      }).catch((e) => {
        console.log('invites error', e)
      })
    }

    async accept(inviteId, shareTrainings, shareLocation, shareReports) {
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/friends/${inviteId}/accept`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
          shareLocation: shareLocation,
          shareProfile: true,
          shareTrainings: shareTrainings,
          shareReports: shareReports,
        })
      }).then(async (res) => {
        let data = await res.json()
        console.log('accept response', data)
        // this.requests = this.requests.filter((item) => item.id !== inviteId)
        this.list = [...this.list, data]
        this.requests = this.requests.filter((item) => item.id !== inviteId)
        this.hasFetchedFeed = false
        return true
        
      }).catch((e) => {
        console.log('invites error', e)
      })
    }

    async decline(inviteId) {
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/friends/${inviteId}/decline`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'POST',
      }).then(async (res) => {
        console.log('decline response', res)
        this.requests = this.requests.filter((item) => item.id !== inviteId)
        return true
        
      }).catch((e) => {
        console.log('invites error', e)
      })
    }

    async removeFriend(friendUserId) {
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/friends/${friendUserId}/remove`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'DELETE',
      }).then(async (res) => {
        console.log('decline response', res)
        this.list = this.list.filter((item) => item.fromPatient?.user?.id !== friendUserId && item.toPatient?.user?.id !== friendUserId)
        this.hasFetchedFeed = false
        return true
        
      }).catch((e) => {
        console.log('invites error', e)
      })
    }

    // async getWhatIShare(friendUserId) {
    //   if (this.whatIShareItems[friendUserId]) {
    //     return
    //   }
    //   this.whatIShareItems[friendUserId] = {}
    //   // fetch what I share with userId
    //   return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/friends/${friendUserId}/share`, {  // eslint-disable-line no-undef
    //     headers: {
    //       authentication: await this.authStore.getIdToken(),
    //       'Content-Type': 'application/json'
    //     },
    //     method: 'GET',
    //   }).then(async (res) => {
    //     // console.log('decline response', res)
    //     // this.list = this.list.filter((item) => item.fromPatient?.user?.id !== friendUserId && item.toPatient?.user?.id !== friendUserId)
    //     if (res.status < 300) {
    //       let data = await res.json()
    //       console.log('res data', data)
    //       this.whatIShareItems[friendUserId] = data
    //     } else {
    //       console.log('getWhatIShare error', res.status)
    //     }
    //     return res
        
    //   }).catch((e) => {
    //     console.log('getWhatIShare error', e)
    //     return null
    //   })
    // }

    async updateWhatIShare(friendUserId, shareTrainings, shareLocation, shareReports) {
      console.log('updateWhatIShare', friendUserId, shareTrainings, shareLocation, shareReports)
      if (this.whatIShareItems[friendUserId]) {
        this.whatIShareItems[friendUserId].shareTrainings = shareTrainings
        this.whatIShareItems[friendUserId].shareLocation = shareLocation
        this.whatIShareItems[friendUserId].shareReports = shareReports
      }
      // fetch what I share with userId
      return fetch(`${process.env.REACT_APP_SERVER_URL}patient/${this.authStore.id}/friends/${friendUserId}/share`, {  // eslint-disable-line no-undef
        headers: {
          authentication: await this.authStore.getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'PUT',
        body: JSON.stringify({
          shareLocation: shareLocation,
          shareProfile: true,
          shareTrainings: shareTrainings,
          shareReports: shareReports,
        })
      }).then(async (res) => {
        console.log('updateWhatIShare response', res)
        // this.list = this.list.filter((item) => item.fromPatient?.user?.id !== friendUserId && item.toPatient?.user?.id !== friendUserId)
        return res
        
      }).catch((e) => {
        console.log('updateWhatIShare error', e)
        return null
      })
    }
}

export function getName(fromPatient) {
  return fromPatient?.profile?.name || fromPatient?.user?.email
}
import { SpeedFormats } from './constants'
import i18n from '../i18n'

// console.log(i18n)

const MILE = 5280

// ---------------------- (( Speed )) ---------------------- //

// the incoming speed should always be in m/s since it is most widely used in the system
export function formatSpeed(speed, format, units) {
  if (units === 'IMPERIAL') {
    return formatSpeedMilesHour(speed)
  }
  if (format === SpeedFormats.METER_SECOND) {
    return formatSpeedMeterSecond(speed)
  } else {
    return formatSpeedKilometerHour(speed)
  }
}

export function formatSpeedMeterSecond(speed) {
  if (!speed || isNaN(speed)) {
    return '0 m/s'
  }
  if (speed < 1) {
    return `${speed.toFixed(2)} m/s`
  } else if (speed < 10) {
    return `${speed.toFixed(1)} m/s`
  }
  return `${speed.toFixed(0)} m/s`
}
export function formatSpeedKilometerHour(speed) {
  if (!speed || isNaN(speed)) {
    return '0 km/h'
  }

  return `${(speed * 3.6).toFixed(1)} km/h`
}
export function formatSpeedMilesHour(speed) {
  if (!speed || isNaN(speed)) {
    return '0 mph'
  }
  return `${(speed * 2.23694).toFixed(1)} mph`
}


// ---------------------- (( Pace )) ---------------------- //
// input should be s/m
export function formatPace(pace, units) {
  if (units === 'IMPERIAL') {
    return formatPaceImperial(pace)
  }
  return formatPaceMetric(pace)
}

// input should be s/m
export function formatPaceMetric(pace) {
  const paceMinKm = pace / 0.06 

  let minutes = Math.floor(paceMinKm)
  let seconds = (paceMinKm - minutes)*60

  if (paceMinKm < 20) {
    return `${(minutes).toFixed(0)}:${(seconds).toFixed(0).padStart(2, '0')} min/km`
  }
  return `${(paceMinKm).toFixed(0)} min/km`
}
// input should be s/m
export function formatPaceImperial(pace) {
  const paceMinMile = (pace /0.06) * 1.609344
  if (paceMinMile < 2) {
    return `${(paceMinMile).toFixed(2)} min/mile`
  }
  return `${(paceMinMile).toFixed(1)} min/mile`
}

// ---------------------- (( Cadence )) ---------------------- //

export function formatCadence(cadence) {
  if (!cadence) {
    return ''
  } else if (isNaN(cadence)) {
    return ''
  }
  if (cadence < 20) {
    return `${cadence.toFixed(1)} ${i18n.t('training_stepsPerMin')}`
  }
  return `${cadence.toFixed(0)} ${i18n.t('training_stepsPerMin')}`
}

// ---------------------- (( Force )) ---------------------- //

export function formatForce(force) {
  if (!force) {
    return ''
  } else if (isNaN(force)) {
    return ''
  }
  if (force > 10000) {
    return `${(force/1000).toFixed(1)} kN`
  } else if (force < 100) {
    return `${force.toFixed(1)} N`
  }
  return `${force.toFixed(0)} N`
}

// ---------------------- (( Distance )) ---------------------- //

export function formatDistance(distance, units) {

  if (units === 'IMPERIAL') {
    return formatDistanceImperial(distance)
  }
  return formatDistanceMetric(distance)
  
}


// distance in meters to metric
function formatDistanceMetric(distance) {

  if (!distance && distance !== 0) {
    return '0 m'
  } else if (isNaN(distance)) {
    return '0 m'
  } else if (distance === 0) {
    return '0 m'
  }
  if (distance < 2) {
    return `${(distance*100).toFixed(0)} cm`
  } else if (distance < 25) {
    return `${distance.toFixed(1)} m`
  } else if (distance < 1000) {
    return `${distance.toFixed(0)} m`
  }
  return `${(distance/1000).toFixed(1)} km`
}

// distance in meters to imperial
function formatDistanceImperial(distance) {
  if (!distance && distance !== 0) {
    return '0 ft'
  } else if (isNaN(distance)) {
    return '0 ft'
  }

  const feets = distance * 3.2808399
  const inches = distance * 39.3700787

  if (feets < 1) {
    // inches
    return `${inches.toFixed(1)} in`
  } else if (feets < 10) {
    return `${(inches/12).toFixed(0)} ft ${(inches % 12).toFixed(0)} in`
  } else if (feets < MILE/2) {
    return `${(feets).toFixed(0)} ft`
  } 
  return `${(feets/MILE).toFixed(1)} miles`
}

// format distance in meters
export function formatDistanceLowPrecision(distance, units) {

  if (units === 'IMPERIAL') {
    return formatDistanceLowPrecisionImperial(distance)
  }
  return formatDistanceLowPrecisionMetric(distance)
}

// distance in meters to metric
function formatDistanceLowPrecisionMetric(distance) {
  if (!distance && distance !== 0) {
    return '0 m'
  } else if (isNaN(distance)) {
    return '0 m'
  }
  if (distance < 500) {
    return `${distance.toFixed(0)} m`
  }
  return `${(distance/1000).toFixed(1)} km`
}

// distance in meters to imperial
function formatDistanceLowPrecisionImperial(distance) {
  if (!distance && distance !== 0) {
    return '0 ft'
  } else if (isNaN(distance)) {
    return '0 ft'
  }

  const feets = distance * 3.2808399

  if (feets > MILE/2) {
    return `${(feets/MILE).toFixed(1)} miles`
  }

  return `${feets.toFixed(0)} ft`
}

// ---------------------- (( Steps )) ---------------------- //

export function formatSteps(steps) {
  if (!steps) {
    return ''
  } else if (isNaN(steps)) {
    return ''
  }
  return steps.toFixed(0)
}

// ---------------------- (( Percentage )) ---------------------- //

export function formatPercentage(percent, multiply=false) {
  if (!percent) {
    return ''
  } else if (isNaN(percent)) {
    return ''
  }
  let val = percent
  if (multiply) {
    val = percent * 100
  }
  return `${val.toFixed(0)}%`
  
}

// ---------------------- (( Duration )) ---------------------- //

export function formatDuration(duration) {
  if (!duration) {
    return ''
  } else if (isNaN(duration)) {
    return ''
  }
  if (duration < 2) {
    return `${duration.toFixed(2)} s`
  } else if (duration < 10) {
    return `${duration.toFixed(1)} s`
  } else if (duration < 60) {
    return `${duration.toFixed(0)} s`
  } else if (duration < 3600) { // less than an hour
    let minutes = Math.floor(duration/60)
    let seconds = duration % 60
    return `${minutes.toFixed(0).padStart(2, '0')}:${seconds.toFixed(0).padStart(2, '0')}`
  }
  let hours = Math.floor(duration/3600)
  let minutes = Math.floor((duration % 3600)/60)
  let seconds = duration % 60
  return `${hours.toFixed(0)}:${minutes.toFixed(0).padStart(2, '0')}:${seconds.toFixed(0).padStart(2, '0')}`
}

// ---------------------- (( Weight )) ---------------------- //

export function fromatWeight(weight, units) {
  if (units === 'IMPERIAL') {
    return fromatWeightImperial(weight)
  }
  return fromatWeightMetric(weight)
}

export function fromatWeightMetric(weight) {
  if (!weight) {
    return '0 kg'
  } else if (isNaN(weight)) {
    return '0 kg'
  }
  return `${weight.toFixed(0)} kg`
}

export function fromatWeightImperial(weight) {
  if (!weight) {
    return '0 lb'
  } else if (isNaN(weight)) {
    return '0 lb'
  }

  const pounds = weight/0.45359237
  return `${pounds.toFixed(0)} lb`
}



// ---------------------- (( Date )) ---------------------- //

// format a date in from string
export function formatDate(date, dateFormat, timeFormat, short=false, skipDay=false) {
  if (!date) {
    return ''
  }

  // console.log(date)
  
  if (date.getFullYear) {
    return formatDateObject(date, dateFormat, timeFormat, short, skipDay)
  }

  // console.log(date)
  
  // const dateObject = new Date(date)
  // console.log(dateObject, date, Date.parse(date))
  
  const year = date.substr(0, 4)
  const month = date.substr(5, 2)
  const day = date.substr(8, 2)

  let dateString = ''

  if (dateFormat === 'MDY') {
    if (skipDay) {
      dateString = `${month}-${year}`
    } else {
      dateString = `${month}-${day}-${year}`
    }
    
  } else if (dateFormat === 'DMY') {
    if (skipDay) {
      dateString = `${month}-${year}`
    } else {
      dateString = `${day}-${month}-${year}`
    }
  } else {
    if (skipDay) {
      dateString = `${year}-${month}`
    } else {
      dateString = `${year}-${month}-${day}`
    }
  }

  if (short) {
    return dateString
  }

  let hours = parseInt(date.substr(11, 2), 10)
  let minutes = date.substr(14, 2)

  let timeString = ''
  if (timeFormat === 'HOUR12') {
    if (hours < 12) {
      timeString = `${hours.toFixed(0).padStart(2, '0')}:${minutes} AM`
    } else if (hours < 13) {
      timeString = `${hours.toFixed(0).padStart(2, '0')}:${minutes} PM`
    } else {
      timeString = `${(hours-12).toFixed(0).padStart(2, '0')}:${minutes} PM`
    }
  } else {
    timeString = date.substr(11, 5)
  }
  return dateString + ' ' + timeString
}

function formatDateObject(date, dateFormat, timeFormat, short=false, skipDay=false) {
  if (!date) {
    return ''
  }

  const year = date.getFullYear().toFixed(0).padStart(2, '0')
  const month = (date.getMonth()+1).toFixed(0).padStart(2, '0')
  const day = date.getDate().toFixed(0).padStart(2, '0')

  let dateString = ''

  if (dateFormat === 'MDY') {
    if (skipDay) {
      dateString = `${month}-${year}`
    } else {
      dateString = `${month}-${day}-${year}`
    }
    
  } else if (dateFormat === 'DMY') {
    if (skipDay) {
      dateString = `${month}-${year}`
    } else {
      dateString = `${day}-${month}-${year}`
    }
  } else {
    if (skipDay) {
      dateString = `${year}-${month}`
    } else {
      dateString = `${year}-${month}-${day}`
    }
  }

  if (short) {
    return dateString
  }

  let hours = parseInt(date.getHours(), 10)
  let minutes = date.getMinutes()

  let timeString = ''
  if (timeFormat === 'HOUR12') {
    if (hours < 12) {
      timeString = `${hours.toFixed(0).padStart(2, '0')}:${minutes.toFixed(0).padStart(2, '0')} AM`
    } else if (hours < 13) {
      timeString = `${hours.toFixed(0).padStart(2, '0')}:${minutes.toFixed(0).padStart(2, '0')} PM`
    } else {
      timeString = `${(hours-12).toFixed(0).padStart(2, '0')}:${minutes.toFixed(0).padStart(2, '0')} PM`
    }
  } else {
    timeString = `${hours.toFixed(0).padStart(2, '0')}:${minutes.toFixed(0).padStart(2, '0')}`
  }
  return dateString + ' ' + timeString

}
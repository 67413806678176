import React, { useState } from 'react'
import {
  inject, observer 
} from 'mobx-react'
import { withNamespaces } from 'react-i18next'
import {
  Paper, Tabs, Typography 
} from '@material-ui/core'
// import ExpandableListItem from '../components/ExpandableListItem'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Accordion from '@material-ui/core/Accordion'
import { ExpandMore } from '@material-ui/icons'
import TabPanel from '../components/TabPanel'
import StyledTab from '../components/StyledTab'

var FAQ = withNamespaces()(inject('config')(observer(({
  t, config 
}) => {

  const [selectedTab, setSelectedTab] = useState(0)

  const handleTabChange = (e, newValue) => {
    setSelectedTab(newValue)
  }

  config.fetchTerms().then(() => {
  })
  const renderTerms = () => {
    if (!config.termsOfUse || config.termsOfUse === '') {
      return null
    }
    return config.termsOfUse.split('\n').map((item, i) => {
      return <p key={i}>
        {item}
      </p>
    })
  }
  config.fetchPrivacyPolicy().then(() => {
  })
  const renderPrivacyPolicy = () => {
    if (!config.termsOfUse || config.termsOfUse === '') {
      return null
    }
    return config.privacyPolicy.split('\n').map((item, i) => {
      return <p key={i}>
        {item}
      </p>
    })
  }

  // console.log(null.item)
  

  const renderQuestions = () => {
    var questions = []

    var key = 'FAQ_header_' + (questions.length+1)
    var answer_key = 'FAQ_text_' + (questions.length+1)
    
    while(t(key) !== key) {
      
      questions.push(
        <Accordion key={key}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>
              {t(key)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {t(answer_key)}
          </AccordionDetails>
        </Accordion>
      )
      key = 'FAQ_header_' + (questions.length+1)
      answer_key = 'FAQ_text_' + (questions.length+1)
    }
    return questions
  }

  const renderMeassurements = () => {
    var questions = []

    var key = 'MeasInfo_header_' + (questions.length+1)
    var answer_key = 'MeasInfo_text_' + (questions.length+1)
    
    while(t(key) !== key) {
      
      questions.push(
        <Accordion key={key}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>
              {t(key)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {t(answer_key)}
          </AccordionDetails>
        </Accordion>
      )
      key = 'MeasInfo_header_' + (questions.length+1)
      answer_key = 'MeasInfo_text_' + (questions.length+1)
    }
    return questions
  }
  
  return(
    <>
      <Paper
        style={{
          marginTop: 20, marginBottom: 20 
        }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          // centered
        >
          <StyledTab label={t('FAQ')} />
          <StyledTab label={t('MeasInfo')} />
          <StyledTab label={t('menu_terms')} />
          <StyledTab label={t('menu_privacy')} />
        </Tabs>
      </Paper>
      <TabPanel
        value={selectedTab}
        index={0}>
        {renderQuestions()}
      </TabPanel>
      <TabPanel
        value={selectedTab}
        index={1}>
        {renderMeassurements()}
      </TabPanel>
      <TabPanel
        value={selectedTab}
        index={2}>
        {renderTerms()}
      </TabPanel>
      <TabPanel
        value={selectedTab}
        index={3}>
        {renderPrivacyPolicy()}
      </TabPanel>
    </>
  )
})))

export default FAQ
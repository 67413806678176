import React from 'react'
import {
  Box, TextField, InputAdornment 
} from '@material-ui/core'

const InputField = ({
  onChange, value, errorMessage, placeholder, icon, type, id, label, small, endAdornment
}) => {


  return (
    <Box
      mb={2}
      fontWeight="fontWeightBold">
      {!small && <p>
        {label}
      </p>}
      <TextField
        // className={classes.margin}
        style={{ marginBottom: 10 }}
        id={id} 
        label={small ? label : null}
        type={type} 
        fullWidth 
        autoFocus 
        required 
        onChange={onChange} 
        value={value} 
        helperText={errorMessage} 
        error={(errorMessage && errorMessage !== '') ? true : false}
        // variant="outlined"
        placeholder={placeholder}
        InputProps={small ? { endAdornment: endAdornment } : {
          startAdornment: (
            <InputAdornment position="start">
              {icon}
            </InputAdornment>
          ),
          endAdornment: endAdornment 
        }}
      />
    </Box>
  )
}

export default InputField
import React from 'react'

const TabPanel = ({ 
  children, value, index 
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  )
}

export default TabPanel
import React from 'react'
import {
  inject, observer 
} from 'mobx-react'
// import LoginContainer from '../components/LoginContainer'
import {
  Box, Typography, Container, Paper
} from '@material-ui/core'
import { withNamespaces } from 'react-i18next'
import BackgroundImage from '../components/BackgroundImage'

const TermsOfService = withNamespaces()(inject('config')(observer(({
  config, t 
}) => {


  config.fetchTerms().then(() => {
  })
  const renderTerms = () => {
    if (!config.termsOfUse || config.termsOfUse === '') {
      return null
    }
    return config.termsOfUse.split('\n').map((item, i) => {
      return <p key={i}>
        {item}
      </p>
    })
  }


  return (
    <BackgroundImage>
      <Container maxWidth="md">
        <Paper
          style={{
            overflow: 'scroll', marginTop: 100, marginBottom: 50, padding: 20
          }}>
          <Box
            mb={2}
            textAlign="center"
            fontWeight="fontWeightBold">
            <Typography variant="h3" >
              {t('menu_terms')}
            </Typography>
          </Box>
          {renderTerms()}
        </Paper>
      </Container>
    </BackgroundImage>
  )
})))

export default TermsOfService
import React from 'react'
import Graph from '../Graph'
import {
  formatSpeed, formatDistanceLowPrecision, formatForce, formatCadence, formatPercentage, formatDistance, formatDuration 
} from '../../helper/formatting'
import { SpeedFormats } from '../../helper/constants'

const RunningGraphs = ({ 
  trainings, profile, t, isDistance
}) => {
  if (!trainings || trainings.length === 0 || trainings[0].activityType === 'WALK' ) {
    return null
  }

  let single = trainings.length === 1

  const distanceOverTime = []
  if (trainings[0].distanceData) {
    let sum = 0
    for (var i = 0; i < trainings[0].distanceData.length; i++) {
      const a = trainings[0].distanceData[i]
      sum += a[1]
      distanceOverTime.push([a[0], sum])
    }

    // console.log(distanceOverTime)
  }

  // console.log(trainings.map((item) => (item.speedData || []).map(speed => [speed[0], speed[1]*3.6]) ))

  const distance = isDistance
  const xAxisLabel = distance ? t('training_distance') : t('graph_time')

  const getSpeedData = () => {
    return trainings.map((item) => {
      if (item.speedData) {
        // (item.speedData || [])
        return item.speedData.map(speed => [speed[0], speed[1]*3.6])
      }
    } )
  }

  return (
    <>
      <Graph
        // series={[training.speedData]}
        series={getSpeedData()}
        config={{
          label: t('graph_title_speed'),
          xAxis: xAxisLabel,
          // yAxis: '(m/s)',
          tickInterval: 1,
          valueFormatter: (value) => {
            return formatSpeed(value / 3.6, SpeedFormats.KILOMETER_HOUR, profile.units)
          },
          // labels: [t('graph_title_speed')]
          labels: single ? [t('graph_title_cadence')] : trainings.map((item) => item.time),
          distance,
          distanceOverTime: distanceOverTime
        }} />
      <Graph
        series={trainings.map((item) => item.altitudeData)}
        // series={[training.altitudeData]}
        config={{
          label: t('graph_title_altitude'),
          xAxis: xAxisLabel,
          // yAxis: '(m)',
          tickInterval: 1,
          valueFormatter: (value) => {
            return formatDistanceLowPrecision(value, profile.units)
          },
          // labels: [t('graph_title_altitude')]
          labels: single ? [t('graph_title_altitude')] : trainings.map((item) => item.time),
          distance,
          distanceOverTime: distanceOverTime
        }} />
      <Graph
        series={trainings.map((item) => item.stepLengthData)}
        // series={[training.stepLengthData]}
        config={{
          label: t('graph_title_stepLength'),
          xAxis: xAxisLabel,
          // yAxis: '(m)'
          tickInterval: 0.01,
          valueFormatter: (value) => {
            return formatDistance(value, profile.units)
          },
          // labels: [t('graph_title_stepLength')]
          distance,
          labels: single ? [t('graph_title_stepLength')] : trainings.map((item) => item.time),
          distanceOverTime: distanceOverTime
        }} />
      <Graph
        series={trainings.map((item) => item.maxForceData)}
        // series={[training.maxForceData]}
        config={{
          label: t('graph_title_maxForce'),
          xAxis: xAxisLabel,
          // yAxis: '(N)'
          tickInterval: 10,
          valueFormatter: (value) => {
            return formatForce(value)
          },
          // labels: [t('graph_title_maxForce')]
          distance,
          labels: single ? [t('graph_title_maxForce')] : trainings.map((item) => item.time),
          distanceOverTime: distanceOverTime
        }} />
      <Graph
        series={trainings.map((item) => item.bounceData)}
        // series={[training.bounceData]}
        config={{
          label: t('graph_title_bounce'),
          xAxis: xAxisLabel,
          // yAxis: '(m)'
          valueFormatter: (value) => {
            return formatDistance(value, profile.units)
          },
          tickInterval: 0.01,
          // labels: [t('graph_title_bounce')]
          distance,
          labels: single ? [t('graph_title_bounce')] : trainings.map((item) => item.time),
          distanceOverTime: distanceOverTime
        }} />
      <Graph
        series={trainings.map((item) => item.cadenceData)}
        // series={[training.cadenceData]}
        config={{
          label: t('graph_title_cadence'),
          xAxis: xAxisLabel,
          // yAxis: ''
          valueFormatter: (value) => {
            return formatCadence(value)
          },
          tickInterval: 1,
          // labels: [t('graph_title_cadence')]
          distance,
          labels: single ? [t('graph_title_cadence')] : trainings.map((item) => item.time),
          distanceOverTime: distanceOverTime
        }} />
      <Graph
        series={trainings.map((item) => item.verticalRatioData)}
        // series={[training.verticalRatioData]}
        config={{
          label: t('graph_title_verticalRatio'),
          xAxis: xAxisLabel,
          // yAxis: ''
          valueFormatter: (value) => {
            return formatPercentage(value)
          },
          tickInterval: 0.01,
          // labels: [t('graph_title_verticalRatio')]
          distance,
          labels: single ? [t('graph_title_verticalRatio')] : trainings.map((item) => item.time),
          distanceOverTime: distanceOverTime
        }} />
      <Graph
        series={trainings.map((item) => item.brakeData)}
        // series={[training.brakeData]}
        config={{
          label: t('graph_title_brake'),
          xAxis: xAxisLabel,
          // yAxis: ''
          tickInterval: 0.1,
          valueFormatter: (value) => {
            return formatSpeed(value, SpeedFormats.METER_SECOND, profile.units)
          },
          // labels: [t('graph_title_brake')]
          distance,
          labels: single ? [t('graph_title_brake')] : trainings.map((item) => item.time),
          distanceOverTime: distanceOverTime
        }} />
      <Graph
        series={trainings.map((item) => item.timeInAirData)}
        // series={[training.timeInAirData]}
        config={{
          label: t('graph_title_timeInAir'),
          xAxis: xAxisLabel,
          // yAxis: ''
          tickInterval: 0.01,
          valueFormatter: (value) => {
            return formatDuration(value)
          },
          // labels: [t('graph_title_timeInAir')]
          distance,
          labels: single ? [t('graph_title_timeInAir')] : trainings.map((item) => item.time),
          distanceOverTime: distanceOverTime
        }} />
      <Graph
        series={trainings.map((item) => item.groundContactTimeData)}
        // series={[training.groundContactTimeData]}
        config={{
          label: t('graph_title_timeOnGround'),
          xAxis: xAxisLabel,
          // yAxis: ''
          valueFormatter: (value) => {
            return formatDuration(value)
          },
          tickInterval: 0.01,
          // labels: [t('graph_title_timeOnGround')]
          distance,
          labels: single ? [t('graph_title_timeOnGround')] : trainings.map((item) => item.time),
          distanceOverTime: distanceOverTime
        }} />
      <Graph
        series={trainings.map((item) => item.toeOffData)}
        // series={[training.toeOffData]}
        config={{
          label: t('graph_title_toeOffOfCycle'),
          xAxis: xAxisLabel,
          // yAxis: ''
          valueFormatter: (value) => {
            return formatPercentage(value)
          },
          tickInterval: 0.01,
          // labels: [t('graph_title_toeOffOfCycle')]
          labels: single ? [t('graph_title_toeOffOfCycle')] : trainings.map((item) => item.time),
          distance,
          distanceOverTime: distanceOverTime
        }} />
    </>
  )
}

export default RunningGraphs
export const SpeedFormats = {
  METER_SECOND: 'METER_SECOND',
  KILOMETER_HOUR: 'KILOMETER_HOUR',
  'PACE': 'PACE'
}

export const MoodColors = {
  HAPPY :'#00B050',
  SEMI_HAPPY : '#92D050',
  OK : '#FFFF00',
  SAD : '#FFC000',
  VERY_SAD : '#C00000',
}
import React, { useState } from 'react'
import DashboardBase from './DashboardBase'
import HighchartsReact from 'highcharts-react-official'

import Highcharts from 'highcharts'
import {
  Box, IconButton 
} from '@material-ui/core'
import {
  ArrowBack, ArrowForward 
} from '@material-ui/icons'
import {
  reducePerDay, reducePerWeek, getMonthReducer 
} from '../../helper/reducer'
import ActivityTypeIcon from '../ActivityTypeIcon'

const dataMode = {
  BOTH: 'both',
  RUN: 'run',
  WALK: 'walk',
}

const DashboardReport = ({
  label, t,
  trainingsByDaysAgo,
  formatter,
  allowDecimals,
  datetime,
  reducer,
  type,
  fullBar,
  labels,
  showAverage,
  walkOnly,
  runOnly,
  goals
  // minTickInterval
}) => {

  // const colors = ['rgb(57, 106, 177)', 'rgb(132, 186, 91)']
  const colors = [ '#103cd6', '#bd0850']

  const [interval, setInterval] = useState(0)
  const [mode, setMode] = useState(dataMode.BOTH)
  let min = 0
  let max = null
  let plotLines = []
  let zones = []

  const intervals = [
    {
      key: 'SevenDays',
      reducer: reducePerDay,
      period: 'DAY'
    },
    {
      key: 'Week',
      reducer: reducePerWeek,
      period: 'WEEK'
    },
    {
      key: 'Month',
      reducer: getMonthReducer(6),
      period: 'MONTH'
    },
    {
      key: 'TwelveMonths',
      reducer: getMonthReducer(12),
      period: 'MONTH',
    }
  ]

  // console.log(goals)

  const canShowPrev = () => {
    return interval > 0
  }
  const canShowNext = () => {
    return interval < intervals.length-1
  }
  const handleSetPrevPage = () => {
    if (canShowPrev()) {
      setInterval(interval-1)
    }
  }
  const handleSetNextPage = () => {
    if (canShowNext()) {
      setInterval(interval+1)
    }
  }

  const duration = () => {
    return intervals[interval].key
  }

  function xAxisFormatter() {
    if (duration() === 'Week') {
      return this.value
    } else if (['Month', 'TwelveMonths'].includes(duration())) {
      return this.value
    }
    return this.value
  }

  const handleRunToggle = () => {
    if (runOnly) {
      return
    }
    if (mode === dataMode.RUN || mode === dataMode.WALK) {
      setMode(dataMode.BOTH)
    } else {
      setMode(dataMode.WALK)
    }
  }

  const handleWalkToggle = () => {
    if (walkOnly) {
      return
    }
    if (mode === dataMode.RUN || mode === dataMode.WALK) {
      setMode(dataMode.BOTH)
    } else {
      setMode(dataMode.RUN)
    }
  }

  const formatWeek = (day) => {
    const date = new Date(day)
    return `${date.getDate()} ${t('dashboard_month_' + date.getMonth())}`
  }

  function tooltipFormatter() {

    let y = this.y

    if (fullBar) {
      y = y * 100
    }

    if (duration() === 'Week') {
      return `
      <span style="font-size: 10px">${this.x}</span><br/>
      <br/>
      <span style="color:${this.series.color}">●</span> 
      ${this.series.name}: <b>${formatter ? formatter(y) : y}</b></br>`
    } else if (duration() === 'Month') {
      return `
      <span style="font-size: 10px">${this.x}</span><br/>
      <br/>
      <span style="color:${this.series.color}">●</span> 
      ${this.series.name}: <b>${formatter ? formatter(y) : y}</b></br>`
    }
    
    // console.log(this, this.point)
    return `
      <span style="font-size: 10px">${this.x}</span><br/>
      <br/>
      <span style="color:${this.series.color}">●</span> 
      ${this.series.name}: <b>${formatter ? formatter(y) : y}</b></br>`
  }

  let trainings = trainingsByDaysAgo
  if (mode === dataMode.RUN) {
    // console.log(trainings)
    trainings = Object.keys(trainings).reduce((reducer, key) => {
      reducer[key] = trainings[key].filter((item) => {
        return item.activityType === 'RUN'
      })
      return reducer
    }, {})
    // console.log(trainings)
  } else if (mode === dataMode.WALK) {
    trainings = Object.keys(trainings).reduce((reducer, key) => {
      reducer[key] = trainings[key].filter((item) => {
        return item.activityType === 'WALK'
      })
      return reducer
    }, {})
  }

  let data = intervals[interval].reducer(trainings, reducer)

  // console.log(data)
  
  let series = [
    {
      data: data.map((item) => item[1] || null),
      name: labels && labels.length > 0 ? labels[0] : label,
      color: colors[0],
      connectNulls: true
      // tooltip: { pointFormatter: function() {
      //   return ``
      // } },
    },
  ]

  // find ymin and ymax
  max = data.reduce((reducer, item) => {
    if (!item[1]) return reducer
    return Math.max(reducer, item[1])
  }, 0)

  if (fullBar) {

    // find min/max
    min = data.reduce((reducer, item) => Math.min(reducer, item[1] || 1), 1) * 100
    max = data.reduce((reducer, item) => Math.max(reducer, item[1] || 0), 0) * 100
    min -= 5
    max += 5
    min = Math.min(min, 100-max)
    max = Math.max(max, 100-min)
    min = Math.max(min, 0)
    max = Math.min(max, 100)

    plotLines.push({
      value:50,
      color: '#46a667',
      width:2,
      zIndex:4,
      // label:{ text:'Symmetry' }
    })

    series[0].color = colors[1]

    series.push(
      {
        data: data.map((item) => 1-item[1]),
        name: labels && labels.length > 1 ? labels[1] : label,
        color: colors[0],
        
        // tooltip: { pointFormatter: function() {
        //   return ``
        // } },
      }
    )
  }

  // plot lines from goals
  // console.log(goals)
  if (goals && goals.length > 0) {
    // console.log(goals)
    const goal = goals.find((item) => 
      item.period === intervals[interval].period
    )
    if (goal) {
      // console.log(goal)
      plotLines.push({
        value:goal.level,
        color: '#46a667',
        width:2,
        zIndex:4,
        label:{ text:t('dashboard_goal') }
      })
      max = Math.max(max, goal.level * 1.1) // add another 10% ontop
      zones = [{ value: goal.level },{ color: '#46a667', }]
    }
    // console.log(plotLines)
  }
  
  let categories = data.map((item) => item[0])

  if (duration() === 'SevenDays') {
    categories = categories.map((item) => t('dashboard_shortDay_' + item))
  } else if (duration() === 'Week') {
    categories = categories.map((item) => formatWeek(item))
  } else {
    categories = categories.map((item) => t('dashboard_month_' + new Date(item).getMonth()))
  }


  // let tickInterval = 86_400_000
  // if (duration() === 'Week') {
  //   tickInterval *= 7
  // } else if (duration() === 'Month') {
  //   tickInterval *= 28
  // }

  const renderAverage = () => {

    if(!showAverage) {
      return null
    }

    let sum = data.reduce((reducer, item) => {
      if (item[1]) {
        return reducer + item[1]
      }
      return reducer
    }, 0)


    let average = sum/(data.reduce((reducer, item) => {
      if (item[1]) {
        reducer += 1
      }
      return reducer
    }, 0))

    if (formatter) {
      sum = formatter(sum)
      average = formatter(average)
    }

    return (
      <Box
        flexDirection="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <p>
          {t('graph_sum')}
          :
          {' '}
          {sum}
        </p>
        <p>
          {t('graph_average')}
          :
          {' '}
          {average}
        </p>
      </Box>
    )
  }

  const renderWalkButtons = () => {
    if (!runOnly && !walkOnly) {
      return (
        <>
          <IconButton
            size="small"
            onClick={handleWalkToggle}>
            <ActivityTypeIcon
              size={25}
              disabled={mode === dataMode.RUN}
              type='WALK'/> 
          </IconButton>
          <IconButton
            size="small"
            onClick={handleRunToggle}>
            <ActivityTypeIcon
              disabled={mode === dataMode.WALK}
              size={25}
              type='RUN'/> 
          </IconButton>
        </>
      )
    } else if (runOnly) {
      return (<ActivityTypeIcon
        size={25}
        type='RUN'/> )
    } else {
      return  (<ActivityTypeIcon
        size={25}
        type='WALK'/> )
    }
  }

  return (
    <DashboardBase>
      <Box
        style={{
          // justifyContent: 'flex-end', display: 'flex'
          // backgroundColor: 'red',
          marginTop: -10,
          marginRight: -10,
          float: 'right' 
        }}>
        {renderWalkButtons()}
      </Box>
      <Box
        style={{
          marginTop: 0, clear: 'both' 
        }}>
        <HighchartsReact 
          highcharts={Highcharts}
          options={{
            exporting: { enabled: false },
            title: { text: label },
            chart: { type: type || 'column' },
            plotOptions: { column: {
              maxPointWidth: 40,
              stacking: fullBar ? 'percent' : null,
              zones: zones
            } },
            series:series,
            xAxis: {
            // type: 'datetime',
            // labels: { format: '{value:%Y-%m-%d}', }
              labels: { formatter: xAxisFormatter },
              // tickInterval: tickInterval,
              // labels: {
              //   formatter: xAxisFormatter,
              //   rotation: -45,
              //   // x: 15,
              // }
              categories: categories 
            },
            yAxis: { 
              min: min,
              max: max,
              allowDecimals: allowDecimals,
              type: datetime ? 'datetime' : null,
              // minTickInterval: minTickInterval || null,
              title: { text: '' },
              labels: { formatter: function() {
                if (formatter) {
                  return formatter(this.value)
                }
                return this.value
              } },
              plotLines:plotLines
            },
            credits: false,
            legend: fullBar ? { layout: 'vertical' } : false,
            tooltip: { formatter: tooltipFormatter }
          
          }} />
      </Box>
      {renderAverage()}
      <Box
        flexDirection="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Box>
          <IconButton
            size="small"
            disabled={!canShowPrev()}
            onClick={handleSetPrevPage} >
            <ArrowBack />
          </IconButton>
          <IconButton
            size="small"
            disabled={!canShowNext()}
            onClick={handleSetNextPage} >
            <ArrowForward />
          </IconButton>
        </Box>
        <p style={{ margin: 0 }}>
          {t('dashboard_interval' + intervals[interval].key)}
        </p>
      </Box>
    </DashboardBase>
  )
}
export default DashboardReport
import styled from 'styled-components'

export default styled.div`
  background-image: url(${props => props.image || require('../assets/walking.jpg')});
  margin: 0;
  padding: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  background-size: cover;
  overflow: scroll;
`
import React from 'react'

import {
  List, ListItem, Drawer as MDrawer, ListItemIcon, ListItemText, AppBar, Toolbar, Divider, CssBaseline, Container, Box, Typography, Badge
} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import {
  Link, useHistory
} from 'react-router-dom'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import {
  inject, observer
} from 'mobx-react'
import {
  AccountCircle, TrackChanges, Group
} from '@material-ui/icons'
import { withNamespaces } from 'react-i18next'
import LanguageSelect from './LanguageSelect'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: { marginRight: 36, },
  hide: { display: 'none', },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: { width: theme.spacing(9) + 1, },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: { width: 'calc(100% - 60px)' },
  // logo: {
  //   width: 100,
  //   height: 50,
  //   objectFit: 'contain',
  //   [theme.breakpoints.down('sm')]: {
  //     width: 150,
  //     height: 37.5,
  //   }
  // },
  logoContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))



var Drawer = withNamespaces()( inject('auth', 'friends', 'groups')(observer(({
  children, auth, t, friends, groups
}) => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)
  const [stayOpen, setStayOpen] = React.useState(false)
  const history = useHistory()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const toggleDrawer = () => {
    setStayOpen(!stayOpen)
  }


  const signOut = () => {
    auth.signOut()
    history.push('/')
  }

  return(
    <div className={classes.root} >
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, 'no-print')}>
        <Toolbar>
          <Link
            to="/dash/"
            className="logo">
            <img
              src={require('../assets/mystep_logo/logo-nude.png')}
              className={classes.logo} />
            <p
              color="secondary"
              variant="h4" >
              Mystep
            </p>
          </Link>
          {/* <IconButton edge="start"  color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
            <MenuIcon />
          </IconButton> */}

          {/* <Button color="inherit">
            Logout
          </Button> */}
          <Box
            style={{
              marginLeft: 'auto', flexDirection: 'row', display: 'flex', alignItems: 'center'
            }}>
            {auth?.user?.email &&
              <Box>
                <Typography variant="caption">
                  {t('loggedInAs')}
                </Typography>
                <Typography variant="body2">
                  {auth.user.email}
                </Typography>
              </Box>
            }
            <LanguageSelect/>
            {/* <LanguageSelect></LanguageSelect> */}
          </Box>

        </Toolbar>
      </AppBar>
      <MDrawer
        open={open || stayOpen}
        variant="permanent"
        anchor="left"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: stayOpen,
          [classes.drawerClose]: !stayOpen,
        }, 'no-print')}
        classes={{ paper: clsx({
          [classes.drawerOpen]: open || stayOpen,
          [classes.drawerClose]: !open && !stayOpen,
        }), }}
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
      >

        <div  className={classes.toolbar}>
          {/* <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton> */}
        </div>
        <Divider />
        <List>
          <ListItem
            button
            component={Link}
            key={'dash'}
            to="/dash/">
            <ListItemIcon>
              {' '}
              {/* <DashboardIcon /> */}
              <img
                src={require('../assets/icons/home_dark@2x.png')}
                className="drawer-logo"/>
            </ListItemIcon>
            <ListItemText primary={t('menu_dashboard')} />
          </ListItem>
          <ListItem
            button
            component={Link}
            key={'trainings'}
            to="/dash/trainings">
            <ListItemIcon>
              {/* <FormatListBulleted /> */}
              <img
                src={require('../assets/icons/trainings_dark@2x.png')}
                className="drawer-logo"/>
            </ListItemIcon>
            {/* <ListItemAvatar>
              <Avatar
                src={require('../assets/shoe_icons/Run_grey.png')}
                alt="als" />
            </ListItemAvatar> */}
            <ListItemText primary={t('menu_trainings')} />
          </ListItem>
          <ListItem
            button
            component={Link }
            to="/dash/goals"
            key={'goals'}>
            <ListItemIcon>
              {' '}
              <TrackChanges />
            </ListItemIcon>
            <ListItemText primary={t('menu_goals')} />
          </ListItem>
          <ListItem
            button
            component={Link }
            to="/dash/friends"
            key={'friends'}>
            <ListItemIcon>
              {' '}
              <Badge
                badgeContent={friends.requestToAnswer.length}
                color="primary">
                <img
                  src={require('../assets/icons/friends_dark@2x.png')}
                  className="drawer-logo"/>
                {/* <EmojiPeople /> */}
              </Badge>
            </ListItemIcon>
            <ListItemText primary={t('menu_friends')} />
          </ListItem>
          <ListItem
            button
            component={Link }
            to="/dash/groups"
            key={'groups'}>
            <ListItemIcon>
              <Badge
                badgeContent={groups.requestToAnswer.length}
                color="primary">
                <Group />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={t('menu_groups')} />
          </ListItem>
          <ListItem
            button
            component={Link }
            to="/dash/profile"
            key={'profile'}>
            <ListItemIcon>
              {' '}
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary={t('menu_profile')} />
          </ListItem>
        </List>
        <Divider/>
        <List>
          <ListItem
            button
            component={Link}
            key={'faq'}
            to="/dash/faq" >
            <ListItemIcon>
              {' '}
              {/* <Help/> */}
              <img
                src={require('../assets/icons/more_dark@2x.png')}
                className="drawer-logo"/>
              {' '}
            </ListItemIcon>
            <ListItemText primary={t('menu_faq')} />
          </ListItem>
          <ListItem
            button
            key={'dash'}
            onClick={signOut}>
            <ListItemIcon>
              {' '}
              <ExitToAppIcon/>
              {' '}
            </ListItemIcon>
            <ListItemText primary={t('menu_signOut')} />
          </ListItem>
        </List>
        <Divider/>
        <List>
          <ListItem
            button
            key={'dash'}
            onClick={toggleDrawer}>
            <ListItemIcon>
              {' '}
              {stayOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </ListItemIcon>
          </ListItem>
        </List>
      </MDrawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Container>
          {children}
        </Container>
      </main>
    </div>
  )
})))

export default Drawer

import i18n from 'i18next'
import { reactI18nextModule } from 'react-i18next'

import translationEN from './locales/en.translation.json'
import translationSV from './locales/sv.translation.json'
import translationES from './locales/es.translation.json'
import translationPT from './locales/pt.translation.json'
import translationFR from './locales/fr.translation.json'
import translationDE from './locales/de.translation.json'


// import faqEN from './locales/en.faq.json'
// import faqSV from './locales/sv.faq.json'

// the translations
const resources = {
  en: { translation: { ...translationEN } },
  es: { translation: { ...translationES } },
  sv: { translation: { ...translationSV } },
  pt: { translation: { ...translationPT } },
  fr: { translation: { ...translationFR } },
  de: { translation: { ...translationDE } },
}

// get current lang

let lang = localStorage.getItem('lang') || 'en'


i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lang,
    debug: false,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: { escapeValue: false } // react already safes from xss
  })

export default i18n

export const languages = [
  { 
    key: 'sv', icon: 'SE' 
  },
  { 
    key: 'en', icon: 'GB' 
  },
  { 
    key: 'es', icon: 'ES' 
  },
  { 
    key: 'pt', icon: 'PT' 
  },
  { 
    key: 'fr', icon: 'FR' 
  },
  { 
    key: 'de', icon: 'DE' 
  }
]
import { Pagination } from '@material-ui/lab'
import {
  inject, observer 
} from 'mobx-react'
import React, { useState } from 'react'
import { withNamespaces } from 'react-i18next'
import {
  useHistory, useParams 
} from 'react-router-dom'
import TrainingItem from '../../components/TrainingItem'
import { getName } from '../../stores/friends'

const PAGE_SIZE = 25

const GroupFeed = withNamespaces()( inject('groups', 'profile')(observer(({
  groups, profile
}) => {

  const history = useHistory()
  const { groupId } = useParams()

  const [page, setPage] = useState(1)

  // fetches the feed if it does not exist
  groups.getFeed(groupId)
  const numberOfPages = Math.ceil(groups.feed.length/PAGE_SIZE)

  const getGpsData = (relation, training) => {
    if (relation.shareLocation) {
      // ask to fetch location if not already fetched
      groups.fetchTrainingData(groupId, training.id)
    }
  }

  const handleNavigate = (training) => {
    history.push(`/dash/groups/${groupId}/${training.id}`)
  }

  const handleChange = (e, value) => {
    setPage(value)
  }

  return (
    <div
      style={{
        display: 'flex', flexDirection: 'column' 
      }}>
      {numberOfPages > 1 && <Pagination
        style={{
          alignSelf: 'center', marginBottom: 15
        }}
        count={numberOfPages}
        page={page}
        onChange={handleChange} />}
      {
        groups.feed.slice((page-1)*PAGE_SIZE, (page)*PAGE_SIZE).map((item) => {
          const relation = groups.relation(groupId, item.userId)
          getGpsData(relation, item)
          return (
            <TrainingItem
              key={item.id}
              training={item}
              profile={profile}
              name={getName(relation?.patient)}
              onNavigate={handleNavigate} />
          )
        })
      }
      {numberOfPages > 1 && <Pagination
        style={{
          alignSelf: 'center', marginBottom: 15
        }}
        count={numberOfPages}
        page={page}
        onChange={handleChange} />}
    </div>
  )
})))

export default GroupFeed

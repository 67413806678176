import React from 'react'

import {
  Button , AppBar, Toolbar, CssBaseline, Box, makeStyles 
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import LanguageSelect from './LanguageSelect'
import { withNamespaces } from 'react-i18next'
import {
  observer, inject 
} from 'mobx-react'
// import logo from '../logo.svg'
// import '../App.css'


const useStyles = makeStyles((theme) => ({ logo: {
  width: 200,
  height: 50,
  objectFit: 'cover',
  [theme.breakpoints.down('sm')]: {
    width: 125,
    height: 30,
  }
} }))

var UnauthenticatedAppBar = withNamespaces() (inject('auth')(observer( ({
  children, t, auth
}) => {

  const classes = useStyles()
  
  return(
    <div >
      <CssBaseline />
      <AppBar
        position="fixed" 
      >
        <Toolbar>
          <Link to="/">
            <img
              src={require('../assets/mystep_logo/White/Horizontal Logo White.png')}
              className={classes.logo} />
          </Link>
          
          <Box
            style={{ marginLeft: 'auto' }}>
            <LanguageSelect />
            { !auth.isLoggedIn &&
              <Button
                component={Link}
                to="/login"
                style={{ textDecoration: 'none' }}
                variant="contained">
                {t('menu_login')}
              </Button>
            }
            { auth.isLoggedIn &&
              <Button
                component={Link}
                to="/dash/"
                style={{ textDecoration: 'none' }}
                color="secondary"
                variant="contained">
                {t('menu_goToDashboard')}
              </Button>
            }
          </Box>
        </Toolbar>
      </AppBar>
      <main>
        <div />
        {children}
      </main>
    </div>
  )
})))

export default UnauthenticatedAppBar

import React, { useState } from 'react'
import {
  Box, Typography, Button, Collapse,
} from '@material-ui/core'
import {
  inject, observer 
} from 'mobx-react'
// import {  Email } from '@material-ui/icons'
// import { Link } from 'react-router-dom'
// import Alert from '@material-ui/lab/Alert'
import LoginContainer from '../components/LoginContainer'
// import InputField from '../components/InputField'
import { withNamespaces } from 'react-i18next'
import Alert from '@material-ui/lab/Alert'


var VerifyEmail = withNamespaces()( inject('auth')(observer(({
  auth, t 
}) => {

  // const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [success, setSuccess] = useState(false)

  
  // const handleEmailChange = (event) => {
  //   event.persist()
  //   setEmail(event.target.value)
  // }

  const resendEmail = () => {
    // if (event.preventDefault) {
    //   event.preventDefault()
    // }

    // setEmailError('')

    // auth.resetPassword(email).then(() => {

    //   // present success status to user and redirect to login.
    //   setSuccess(true)
      
    // }).catch((e) => {
    //   console.log(e)
    // if (e.code === 'auth/invalid-email') {
    //   setEmailError(t('login_invalidEmail'))
    // } else if (e.code === 'auth/user-not-found') {
    //   setEmailError(t('login_userNotFound'))
    // }
    // })
    auth.sendEmailVerification().then(() => {
      //sent!
      setSuccess(true)
      // console.log('res',res )
    }).catch((e) => {
      // console.log('error',error )
      if (e.code === 'auth/invalid-email') {
        setEmailError(t('login_invalidEmail'))
      } else if (e.code === 'auth/user-not-found') {
        setEmailError(t('login_userNotFound'))
      } else if (e.code === 'auth/too-many-requests') {
        setEmailError(t('login_tooManyTries'))
      }
    })
  }

  return(
    <LoginContainer>
      <Box
        mb={2}
        textAlign="center"
        fontWeight="fontWeightBold">
        <Typography variant="h4" >
          {t('login_verifyEmail')}
        </Typography>
      </Box>
      <Collapse in={success}>
        <Alert
          severity="success"
          style={{ marginBottom: 20 }}>
          {t('login_emailSent')}
        </Alert>
      </Collapse>
      <p style={{ color: 'red' }}>
        {emailError}
      </p>
      <Button
        variant="contained"
        color="primary"
        style={{
          textTransform: 'none',
          marginTop: 20,
          width: '100%' 
        }}
        type="submit"
        onClick={resendEmail}>
        {t('login_resendEmail')}
      </Button>
      {/* <Collapse in={success}>
        <Alert
          severity="success"
          style={{ marginBottom: 20 }}>
          {t('login_passwordHasBeenReset')}
        </Alert>
      </Collapse> */}
      {/* <form onSubmit={resetPassword}>
        <InputField
          label={t('login_email')}
          id="email" 
          type="email" 
          onChange={handleEmailChange} 
          value={email} 
          errorMessage={emailError} 
          placeholder={t('login_emailPlaceholder')}
          icon={<Email  color="disabled"/>}
        />
        
      </form> */}
      {/* <Box
        textAlign="center"
        mt={2}>
        <Typography style={{ color: 'gray' }}>
          {t('login_haveAccount')}
          <Link
            style={{
              color: 'gray',
              fontWeight: 'bold',
              marginLeft: 5 
            }}
            to="/login">
            {t('login_signin')}
          </Link>
        </Typography>
      </Box> */}
    </LoginContainer>
  )
})))

export default VerifyEmail
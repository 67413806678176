import React, { useState } from 'react'
import {
  Typography, Box, Button, Paper, Select, MenuItem, Modal, IconButton, Table, TableBody, TableCell, TableRow, TableHead, TableContainer
} from '@material-ui/core'
import {
  inject, observer
} from 'mobx-react'
import { withNamespaces } from 'react-i18next'
import InputField from '../components/InputField'
import { Delete } from '@material-ui/icons'
import {
  formatSteps, formatDuration, formatDistanceLowPrecision
} from '../helper/formatting'
import { toJS } from 'mobx'


const Goals = withNamespaces()( inject('goal')(observer( ({
  goal, t
}) => {

  const goals = [
    {
      label: t('goal_steps'), value: 'steps', formatter: (value) => formatSteps(value)
    },
    {
      label: t('goal_activeTime'), value: 'activeTime', formatter: (value) => formatDuration(value), multiplier: 60
    },
    {
      label: t('goal_numberOfTrainings'), value: 'numberOfTrainings', formatter: (value) => value
    },
    {
      label: t('goal_DISTANCE'), value: 'DISTANCE', formatter: (value) => formatDistanceLowPrecision(value), multiplier: 1000,
    },
  ]


  const [variable, setVariable] = useState('steps')
  const [level, setLevel] = useState(0)
  const [period, setPeriod] = useState('DAY')
  const [open, setOpen] = useState(false)
  const [alreadyExists, setAlreadyExists] = useState(false)
  const [goalId, setGoalId] = useState(-1)

  goal.fetchGoals().then((res) => {
    console.log(res)
  })

  const addGoal = () => {

    const goal_config = goals.find((item) => item.value === variable)

    if (goalId !== -1) {
      goal.updateGoal({
        id: goalId, period: period, variable
      }, level * (goal_config.multiplier || 1)).then(() => {
        setOpen(false)
      })
    }

    // check if goal already exists.
    const existing = goal.list.find((item) => {
      return item.variable === variable
    })
    if(existing) {
      // console.log('goal already exists')
      if (alreadyExists) {
        // the user is informed that it already exists and want to override the existing goal
        goal.updateGoal(existing, level * (goal_config.multiplier || 1))
        setAlreadyExists(false)
      } else {
        setAlreadyExists(true)
      }
      return
    }

    // console.log('new')

    goal.addGoal(variable, level * (goal_config.multiplier || 1), period).then(() => {
      setOpen(false)
    })
  }

  const handleVariable = (event) => {
    setAlreadyExists(false)
    setVariable(event.target.value)
  }
  const handleLevel = (event) => {
    if (event.target.value === '') {
      setLevel('')
      return
    }
    try{
      setLevel(parseFloat(event.target.value.replace(/[^0-9,.]/g, '')))
    } catch(e) {
      console.log(e)
    }
  }
  const handlePeriod = (event) => {
    setAlreadyExists(false)
    setPeriod(event.target.value)
  }

  const onClose = () => {
    setOpen(false)
  }

  const openModal = () => {
    setOpen(true)
    setGoalId(-1)
    setLevel(0)
  }

  const deleteGoal = (event, item) => {

    event.stopPropagation()

    if (window.confirm(t('goal_deleteConfirm'))) {

      goal.deleteGoal(item).then(() => {
        //success,
      })
    }
  }

  const editRow = (row) => {
    const goal_config = goals.find((item) => item.value === row.variable)

    setOpen(true)
    setVariable(row.variable)
    setLevel(row.level / (goal_config.multiplier || 1))
    setPeriod(row.period)
    setGoalId(row.id)
  }

  const goalsAvailable = () => {
    const list =  goals.filter(({ value }) => {
      const existing = goal.list.find((item) => {
        return item.variable === value
      })
      return !existing
    })

    console.log(list)
    return list
  }

  const renderGoalRow = (row) => {
    console.log(toJS(row))
    let amount = 0
    let variableText = ''
    switch (row.variable) {
    case 'steps':
      amount = formatSteps(row.level)
      variableText = t('goal_steps')
      break
    case 'activeTime':
      amount = formatDuration(row.level)
      variableText = t('goal_activeTime')
      break
    case 'numberOfTrainings':
      amount = row.level
      variableText = t('goal_numberOfTrainings')
      break
    case 'DISTANCE':
      amount = formatDistanceLowPrecision(row.level)
      variableText = t('goal_DISTANCE')
      break

    default:
      break
    }

    return (
      <TableRow
        key={row.id}
        onClick={() => editRow(row)}
        hover
        style={{ cursor: 'pointer' }}>
        <TableCell>
          {variableText}
        </TableCell>
        <TableCell>
          {amount}
        </TableCell>
        <TableCell>
          {t('goal_' + row.period)}
        </TableCell>
        <TableCell align="right">

          <IconButton onClick={(event) => deleteGoal(event, row)}>
            <Delete fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Box>
      <Box
        style={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'
        }}>
        <Box
          style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center'
          }}>
          <h1 style={{ marginRight: 10 }}>
            {t('menu_goals')}
          </h1>
        </Box>
        <Button
          variant="contained"
          disabled={goalsAvailable().length <= 0}
          onClick={openModal}>
          {t('goal_add')}
        </Button>
      </Box>
      <Paper style={{ marginBottom: 20 }}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: 'bold', fontSize: '1.3em'
                  }}>
                  {t('goal_variable')}
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 'bold', fontSize: '1.3em'
                  }}>
                  {t('goal_level')}
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 'bold', fontSize: '1.3em'
                  }}>
                  {t('goal_period')}
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 'bold', fontSize: '1.3em'
                  }}
                  align="right">
                  {t('goal_delete')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                goal.list.map((item) => renderGoalRow(item))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Add goal section */}
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display:'flex',alignItems:'center',justifyContent:'center'
        }}
      >
        <Paper
          style={{ padding: 20 }}
        >
          <Typography variant="h4">
            { goalId !== '-1' ? t('goal_addTitle') : t('goal_editTitle')}
          </Typography>
          {goalId !== -1 && <>
            <Typography style={{ paddingTop: 30 }}>
              {t('goal_variable')}
              :
              {' '}
              {t('goal_' + variable)}
            </Typography>
            <Typography style={{ padding: '30px 0' }}>
              {t('goal_period')}
              :
              {' '}
              {t('goal_' + period)}
            </Typography>
          </>}
          {
            goalId === -1 && <Box py={3}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={variable}
                onChange={handleVariable}
                style={{ display: 'flex' }}
              >
                {goalsAvailable().map((goal) => {
                  return  <MenuItem
                    value={goal.value}
                    key={goal.value}>
                    {goal.label}
                  </MenuItem>
                })}
                {/* <MenuItem value='steps'>
                  {t('goal_steps')}
                </MenuItem>
                <MenuItem value='activeTime'>
                  {t('goal_activeTime')}
                </MenuItem>
                <MenuItem value='numberOfTrainings'>
                  {t('goal_numberOfTrainings')}
                </MenuItem> */}
                {/* <MenuItem value='CADENCE'>
                  {t('goal_cadence')}
                </MenuItem>
                <MenuItem value='DISTANCE'>
                  {t('goal_distance')}
                </MenuItem>
                <MenuItem value='STEP_LENGTH'>
                  {t('goal_stepLength')}
                </MenuItem>
                <MenuItem value='SPEED'>
                  {t('goal_speed')}
                </MenuItem>
                <MenuItem value='SIX_MIN'>
                  {t('goal_sixMin')}
                </MenuItem>
                <MenuItem value='MAX_FORCE'>
                  {t('goal_maxForce')}
                </MenuItem>
                <MenuItem value='PACE'>
                  {t('goal_pace')}
                </MenuItem>
                <MenuItem value='COOPER'>
                  {t('goal_cooper')}
                </MenuItem> */}
                {/* <MenuItem value='numberOfTrainings'>
                {t('goal_numberOfTrainings')}
              </MenuItem> */}
              </Select>
            </Box>
          }
          <InputField
            id="standard-basic"
            label={t('goal_level')}
            small
            placeholder={t('goal_level')}
            onChange={handleLevel}
            value={level}  />
          {
            goalId === -1 && <Box py={3}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={period}
                onChange={handlePeriod}
                style={{ display: 'flex' }}
              >
                {/* <MenuItem value='TRAINING'>
                {t('goal_perTraining')}
              </MenuItem> */}
                <MenuItem value='DAY'>
                  {t('goal_DAY')}
                </MenuItem>
                <MenuItem value='WEEK'>
                  {t('goal_WEEK')}
                </MenuItem>
                <MenuItem value='MONTH'>
                  {t('goal_MONTH')}
                </MenuItem>
              </Select>
            </Box>
          }
          <Button
            variant='contained'
            color="primary"
            style={{ marginRight: 10 }}
            onClick={addGoal}>
            {alreadyExists ? t('goal_overwrite') : (goalId === -1 ? t('goal_add') : t('goal_update'))}
          </Button>
          <Button
            variant='contained'
            onClick={onClose}>
            {t('goal_cancel')}
          </Button>
        </Paper>
      </Modal>
    </Box>
  )
})))

export default Goals

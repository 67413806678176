import {
  inject, observer 
} from 'mobx-react'
import React from 'react'
import {
  useHistory, useParams 
} from 'react-router-dom'
import TrainingComponent from '../../components/Training'

export const GroupTraining = inject('groups')(observer(({ groups }) => {

  let {
    trainingId, groupId 
  } = useParams()
  const history = useHistory()
  const training = groups.training(groupId, trainingId) || {}
  const group = groups.group(groupId)

  const member = group?.group?.members?.find((item) => {
    return item?.patient?.user?.id === training.userId
  })

  groups.fetchTrainingData(groupId, trainingId)

  const goBack = () => {
    history.goBack()
  }

  return (
    <TrainingComponent
      training={training}
      goBack={goBack}
      userId={training.userId}
      groupId={groupId}
      reportEnabled={member?.shareReports} />
  )
}))

import React from 'react'
import DashboardBase from './DashboardBase'
import { Calendar } from '@material-ui/pickers'
import { IconButton } from '@material-ui/core'

const DashboardCalendar = ({
  t, trainings
}) => {
  

  function sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
  }

  function hasTrainedDate(date) {
    if (trainings.find((item) => {
      if (!item || !item.time) {
        return false
      }
      return sameDay(new Date(item.time.substr(0, 10)), date)
    })) {
      return true
    } 
    return false
  }

  return (
    <DashboardBase style={{ overflow: 'hidden' }}>
      <h1>
        {t('dashboard_calendar')}
      </h1>
      <Calendar
        date={new Date()}
        disableFuture
        onChange={() => {}}
        renderDay={(day, selectedDate, dayInCurrentMonth) => {
          // console.log(day.getDay())
          return (
            // <>
            //   <p style={{ padding: 5 }}>
            //     {day.getDate()}
            //   </p>
            // </>
            <div style={{ marginBottom: 5 }}>
              <IconButton
                style={{ 
                  color: 'rgba(0, 0, 0, 0.87)',
                  width: 36,
                  height: 36,
                  margin: '0 2px',
                  padding: 0,
                  fontSize: '0.75rem',
                  fontWeight: 500 
                }} >
                {dayInCurrentMonth ? (
                  <span
                    style={{ 
                      width: '100%',
                      display: 'flex',
                      alignItems: 'inherit',
                      justifyContent: 'inherit',
                      borderRadius: 50,
                      ...(sameDay(selectedDate, day) ? {
                        // backgroundColor: hasTrainedDate(day) ? '#46a667' : '#103cd6', color: 'white' 
                        backgroundColor: '#103cd6', color: 'white' 
                      } : {})
                    }}>
                    <p>
                      {day.getDate()}
                    </p>
                  </span>
                ) : (
                  <span
                    style={{ 
                      width: '100%',
                      display: 'flex',
                      alignItems: 'inherit',
                      justifyContent: 'inherit',
                      color: 'lightgray'
                    }}>
                    <p>
                      {day.getDate()}
                    </p>
                  </span>
                )}
              </IconButton>
              <div
                style={{ 
                  width: 8, height: 8, borderRadius: 5, margin: 'auto', marginTop: -8, ...(hasTrainedDate(day) ? { backgroundColor: 'black' } : {})
                }} />
            </div>
          )
        }} />
      <div
        style={{
          marginTop: 0, display: 'flex', flexDirection: 'row', alignItems: 'center'
        }}>
        <div
          style={{ 
            width: 8, height: 8, borderRadius: 5, backgroundColor: 'black', marginRight: 5
          }} />
        <p>
          {t('dashboard_trainingHasBeenCompleted')}
        </p>
      </div>
     
    </DashboardBase>
  )
}
export default DashboardCalendar
import React from 'react'
import {
  Switch, Route, BrowserRouter, Redirect 
} from 'react-router-dom'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Drawer from './components/Drawer'
import {
  observer, inject 
} from 'mobx-react'
import CreateAccount from './pages/CreateAccount'
import ForgotPassword from './pages/ForgotPassword'
import Home from './pages/Home'
import UnauthenticatedAppBar from './components/UnauthenticatedAppBar'
import Trainings from './pages/Trainings'
import Training from './pages/Training'
import Profile from './pages/Profile'
import FAQ from './pages/FAQ'
import TermsOfService from './pages/TermsOfService'
import PrivacyPolicy from './pages/PrivacyPolicy'
import VerifyEmail from './pages/VerifyEmail'
import LoadingDashboard from './pages/LoadingDashboard'
import Goals from './pages/Goals'
import Friends from './pages/friends'
import { FriendTraining } from './pages/friends/training'
import { GroupTraining } from './pages/groups/training'
import Groups from './pages/groups'
import Friend from './pages/friends/friend'
import Group from './pages/groups/Group'
import AuthActions from './pages/firebase/AuthAction'

// ignore an eslint rule for this file. It is much cleaner with the props on the same row.
/* eslint react/jsx-max-props-per-line: 0 */


function PrivateRoute ({ 
  component: Component, authed, loading, emailVerified, ...rest 
}) {
  // console.log(authed)
  return (
    <Route
      {...rest}
      render={(props) => {
        if (authed) {
          if (!emailVerified) {
            return <Redirect to="/verifyEmail" />
          }
          return <Component {...props} />
        } else if (loading) {
          return <LoadingDashboard {...props} />
        } else {
          // console.log(loading, authed)
          return <Redirect to="/login" />
        }
      }}
    />
  )
}

@inject('auth')
@observer
class Router extends React.Component {
  render() {
    const { auth } = this.props

    // unauthenticated routing
    // if (!auth.isLoggedIn) {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/dash">
            <Drawer>
              {/* <Switch> */}
              <PrivateRoute authed={auth.isLoggedIn} loading={auth.loading} emailVerified={auth.isEmailVerified} exact path={'/dash/trainings'} component={Trainings} />
              <PrivateRoute authed={auth.isLoggedIn} loading={auth.loading} emailVerified={auth.isEmailVerified}  exact path={'/dash/trainings/:trainingId'} component={Training} />
              <PrivateRoute authed={auth.isLoggedIn} loading={auth.loading} emailVerified={auth.isEmailVerified}  exact path={'/dash/goals'} component={Goals} />
              <PrivateRoute authed={auth.isLoggedIn} loading={auth.loading} emailVerified={auth.isEmailVerified}  exact path={'/dash/profile'} component={Profile} />
              <PrivateRoute authed={auth.isLoggedIn} loading={auth.loading} emailVerified={auth.isEmailVerified}  exact path={'/dash/faq'} component={FAQ} />
              <PrivateRoute authed={auth.isLoggedIn} loading={auth.loading} emailVerified={auth.isEmailVerified}  exact path={'/dash/friends'} component={Friends} />
              <PrivateRoute authed={auth.isLoggedIn} loading={auth.loading} emailVerified={auth.isEmailVerified}  exact path={'/dash/friend/:friendId'} component={Friend} />
              <PrivateRoute authed={auth.isLoggedIn} loading={auth.loading} emailVerified={auth.isEmailVerified}  exact path={'/dash/friends/:trainingId'} component={FriendTraining} />
              <PrivateRoute authed={auth.isLoggedIn} loading={auth.loading} emailVerified={auth.isEmailVerified}  exact path={'/dash/groups'} component={Groups} />
              <PrivateRoute authed={auth.isLoggedIn} loading={auth.loading} emailVerified={auth.isEmailVerified}  exact path={'/dash/groups/:groupId'} component={Group} />
              <PrivateRoute authed={auth.isLoggedIn} loading={auth.loading} emailVerified={auth.isEmailVerified}  exact path={'/dash/groups/:groupId/:trainingId'} component={GroupTraining} />
              <PrivateRoute authed={auth.isLoggedIn} loading={auth.loading} emailVerified={auth.isEmailVerified}  exact path={'/dash/'} component={Dashboard} />
              {/* </Switch> */}
            </Drawer>

          </Route>
          <Route exact path={'/authAction'} component={AuthActions} />
          <Route path="/">
            <UnauthenticatedAppBar>
              {/* <Switch> */}
              <Route exact path={'/'} component={Home} />
              <Route exact path={'/login'} component={Login} />
              <Route exact path={'/create'} component={CreateAccount} />
              <Route exact path={'/forgot'} component={ForgotPassword} />
              <Route exact path={'/termsOfService'} component={TermsOfService} />
              <Route exact path={'/privacyPolicy'} component={PrivacyPolicy} />
              <Route exact path={'/verifyEmail'} component={VerifyEmail} />
              {/* <Route exact path="/dash" component={LoadingDashboard} />
              <Route exact path="/trainings" component={LoadingDashboard} />
              <Route exact path="/trainings/:trainingId" component={LoadingDashboard} />
              <Route exact path="/profile" component={LoadingDashboard} />
              <Route exact path="/faq" component={LoadingDashboard} /> */}
              {/* </Switch> */}
            </UnauthenticatedAppBar>
          </Route>
        </Switch>
      </BrowserRouter>
    )
    // }

    // authenticated user
    // return (
    //   <div>
    //     <BrowserRouter>
    //       <Switch>
    //         {/* <Route exact path={'/'} component={Dashboard} /> */}
    //         <Drawer>
    //           <Switch>
    //             <PrivateRoute authed={auth.isLoggedIn} exact path={'/dash'} component={Dashboard} />
    //             <Route exact path={'/trainings'} component={Trainings} />
    //             <Route exact path={'/trainings/:trainingId'} component={Training} />
    //             <Route exact path={'/profile'} component={Profile} />
    //             <Route exact path={'/faq'} component={FAQ} />
    //             <Route exact path={'/login'} >
    //               <Redirect to="/dash" />
    //             </Route>
    //             <Route path="*">
    //               <Redirect to="/dash" />
    //             </Route>
    //           </Switch>
    //         </Drawer>
    //       </Switch>
    //     </BrowserRouter>
    //   </div>
    // )
  }
}

export default Router

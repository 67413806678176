import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell, TableContainer, TableHead, TableRow, TextField 
} from '@material-ui/core'
import {
  Assessment,
  Check, Clear, Delete, List, LocationOn 
} from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import {
  inject, observer 
} from 'mobx-react'
import React, { useState } from 'react'
import { withNamespaces } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ConfirmModal from '../../components/ConfirmModal'
import Permissions from '../../components/Permissions'
import { getName } from '../../stores/friends'

const Users = withNamespaces()( inject('friends', 'auth')(observer(({
  friends,auth, t
}) => {

  const history = useHistory()

  const [email, setemail] = useState('')
  const [acceptModalOpen, setAcceptModalOpen] = useState(false)
  const [selectedRequest, setSelectedRequest] = useState(null)
  const [confirmDecline, setConfirmDecline] = useState(false)
  const [confirmRemoveFriend, setConfirmRemoveFriend] = useState(false)
  const [selectedFriend, setSelectedFriend] = useState(null)
  const [inviteAccepted, setInviteAccepted] = useState(false)
  const [userRemoved, setuserRemoved] = useState(false)
  const [inviteDeclined, setInviteDeclined] = useState(false)
  const [inviteRemoved, setInviteRemoved] = useState(false)
  const [inviteError, setInviteError] = useState('')
  const [inviteSent, setInviteSent] = useState(false)
  const [shareTrainings, setShareTrainings] = useState(true)
  const [sharePosition, setSharePosition] = useState(false)
  const [shareReports, setShareReports] = useState(true)

  const acceptRequest = () => {
    setAcceptModalOpen(false)
    friends.accept(selectedRequest.id, shareTrainings, sharePosition, shareReports).then(() => {
      // Success
      setInviteAccepted(true)
      setTimeout(() => {
        setInviteAccepted(false)
      }, 3000)
    })
  }

  const handleAcceptButton = (request) => {
    setAcceptModalOpen(true)
    setSelectedRequest(request)
  }

  const declineRequest = () => {
    // if (window.confirm(t('decline_request'))) {
    setConfirmDecline(false)
    friends.decline(selectedRequest.id).then(() => {
      if (selectedRequest.toPatient.user.email === auth.email) {
        setInviteDeclined(true)
        setTimeout(() => {
          setInviteDeclined(false)
        }, 3000)
      } else {
        setInviteRemoved(true)
        setTimeout(() => {
          setInviteRemoved(false)
        }, 3000)
      }
    })
    // }
  }

  const deleteFriend = () => {
    setConfirmRemoveFriend(false)
    friends.removeFriend(selectedFriend?.fromPatient?.user?.id).then(() => {
      setuserRemoved(true)
      setTimeout(() => {
        setuserRemoved(false)
      }, 3000)
    })
  }

  const handleEmailChange = (event) => {
    setemail(event.target.value)
  }

  const invite = () => {
    friends.invite(email, shareTrainings, sharePosition, shareReports).then(() => {
      setInviteSent(true)
      setemail('')
      setTimeout(() => {
        setInviteSent(false)
      }, 3000)
    }).catch((e) => {
      // console.log('invite error', e)
      // setInviteError('Could not send invite')
      if (e === 'NOT_FOUND') {
        setInviteError(t('friend_notFound'))
      } else if (e === 'INVITE_SELF') {
        setInviteError(t('friend_inviteSelf'))
        // setInviteError('You cant invite yourself')
      } else if (e === 'ALREADY_FRIENDS') {
        setInviteError(t('friend_alreadyFriends'))
        // setInviteError('You are already friends')
      } else if (e === 'ALREADY_INVITED') {
        setInviteError(t('friend_alreadyInvited'))
        // setInviteError('You have already sent an invite')
      }
    })
  }

  const selectRow = (friend) => {
    history.push(`/dash/friend/${friend.id}`)
  }

  const renderEmpty = () => {
    if (friends.list.length !== 0) {
      return null
    }
    return (
      <>
        <p
          style={{
            alignSelf: 'center', textTransform: 'uppercase', fontWeight: 'bold' , fontSize: '1.5em', color: 'gray', margin: 0,
          }}>
          Du har inte lagt till några vänner än
        </p>
        <img
          style={{
            maxWidth: 400, padding: 50, alignSelf: 'center' 
          }}
          src={require('../../assets/undraw_a_moment_to_relax_bbpa.svg')}/>
      </>
    )
  }

  const renderAcceptModal = () => {
    return <Modal
      open={acceptModalOpen}
      onClose={() => setAcceptModalOpen(false)}
      style={{
        display:'flex',alignItems:'center',justifyContent:'center' 
      }}>

      <Paper
        style={{
          padding: 20, flexDirection: 'column', display: 'flex'
        }}>
        <h2>
          {t('friend_whatShare', { email: getName(selectedRequest?.fromPatient) })}
        </h2>
        <FormControlLabel
          control={
            <Checkbox
              checked={shareTrainings}
              onChange={() => setShareTrainings(!shareTrainings)}
              name="checkedB"
              color="primary"
            />
          }
          label={t('friend_shareTrainings')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={sharePosition}
              onChange={() => setSharePosition(!sharePosition)}
              name="checkedB"
              color="primary"
            />
          }
          label={t('friend_shareLocation')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={shareReports}
              onChange={() => setShareReports(!shareReports)}
              name="checkedB"
              color="primary"
            />
          }
          label={t('friend_shareReports')}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={acceptRequest}>
          {t('friend_acceptInvite')}
        </Button>
      </Paper>

    </Modal>
  }

  const declineLabel = selectedRequest?.toPatient?.user?.email === auth.email 
    ? t('friend_wantDeclineInviteFrom', { email: getName(selectedRequest?.fromPatient) }) 
    : t('friend_wantDeclineInviteFrom', { email: getName(selectedRequest?.toPatient) }) 

  const renderRequests = () => {
    if (friends.requests.length <= 0) {
      return null
    }

    return <>
      {inviteAccepted && <Alert severity="success">
        {t('friend_inviteAccepted')}
      </Alert>}
      {inviteDeclined && <Alert severity="success">
        {t('friend_inviteDeclined')}
      </Alert>}
      {inviteRemoved && <Alert severity="success">
        {t('friend_inviteRemoved')}
      </Alert>}
      <ConfirmModal
        isOpen={confirmDecline}
        onClose={() => setConfirmDecline(false)}
        onAccept={() => declineRequest()}
        acceptLabel={t('friend_confirm')}
        label={declineLabel} />
      {
        friends.requestRecieved.length > 0 &&
        <>
          <h2>
            {t('friend_requestsRecieved')}
          </h2>
          <TableContainer style={{ marginBottom: 20 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('friend_from')}
                  </TableCell>
                  <TableCell
                    align="right">
                    {t('friend_accept')}
                  </TableCell>
                </TableRow>

              </TableHead>
              <TableBody>
                {friends.requestRecieved.map((request) => {
                  return (
                    <TableRow
                      key={request.id}>
                      <TableCell>
                        {getName(request.fromPatient)}
                      </TableCell>
                      <TableCell
                        style={{ 
                          fontWeight: 'bold', fontSize: '1.3em' 
                        }}
                        align="right">
                        { request.fromPatient.user.email !== auth.email &&
                        <IconButton onClick={() => handleAcceptButton(request)}>
                          <Check fontSize="small" />
                        </IconButton>
                        }
                        <IconButton
                          onClick={() => {
                            setConfirmDecline(true)
                            setSelectedRequest(request)
                            // declineRequest(request)
                          }}>
                          <Clear fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
      {
        friends.requestSent.length > 0 &&
        <>
          <h2>
            {t('friend_requestsSent')}
          </h2>
          <TableContainer style={{ marginBottom: 20 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('friend_to')}
                  </TableCell>
                  <TableCell
                    align="right">
                    {t('friend_accept')}
                  </TableCell>
                </TableRow>

              </TableHead>
              <TableBody>
                {friends.requestSent.map((request) => {
                  return (
                    <TableRow
                      key={request.id}>
                      <TableCell>
                        {getName(request.toPatient)}
                      </TableCell>
                      <TableCell
                        style={{ 
                          fontWeight: 'bold', fontSize: '1.3em' 
                        }}
                        align="right">
                        { request.fromPatient.user.email !== auth.email &&
                        <IconButton onClick={() => handleAcceptButton(request)}>
                          <Check fontSize="small" />
                        </IconButton>
                        }
                        <IconButton
                          onClick={() => {
                            setConfirmDecline(true)
                            setSelectedRequest(request)
                            // declineRequest(request)
                          }}>
                          <Clear fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
    </>
  }

  return (
    <>
      <Paper
        style={{
          padding: 20,
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 20
        }}>
        <h1 style={{ margin: 0 }}>
          {t('friend_list')}
        </h1>
        {userRemoved && <Alert severity="success">
          {t('friend_userHasBeenRemoved')}
        
        </Alert>}
        <ConfirmModal
          isOpen={confirmRemoveFriend}
          onClose={() => setConfirmRemoveFriend(false)}
          onAccept={() => deleteFriend()}
          acceptLabel={t('friend_confirm')}
          label={t('friend_wantRemoveFriend', { email: getName(selectedFriend?.fromPatient) })}/>
        {friends.list.length > 0 && <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('friend_name')}
                </TableCell>
                <TableCell >
                  {t('friend_sharedWithMe')}
                </TableCell>
                <TableCell >
                  {t('friend_iShare')}
                </TableCell>
                <TableCell
                  align="right">
                  {t('friend_remove')}
                </TableCell>
              </TableRow>

            </TableHead>
            <TableBody>
              {friends.list.map((friend) => {
                return (
                  <TableRow
                    key={friend.id}
                    hover
                    style={{ cursor: 'pointer' }}
                    onClick={() => {selectRow(friend)}}>
                    <TableCell>
                      {getName(friend.fromPatient)}
                    </TableCell>
                    <TableCell>
                      <Permissions data={friend} />
                    </TableCell>
                    <TableCell>
                      <Permissions
                        data={
                          friends.whatIShare(friend?.fromPatient?.user?.id)} />
                    </TableCell>
                    <TableCell
                      style={{ 
                        fontWeight: 'bold', fontSize: '1.3em' 
                      }}
                      align="right">
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation()
                          setConfirmRemoveFriend(true)
                          setSelectedFriend(friend)
                          // deleteFriend(friend)
                        }}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>}
        {renderEmpty()}
      </Paper>
      <Paper
        style={{
          padding: 20,
          display: 'flex',
          flexDirection: 'column' 
        }}>
        {renderRequests()}
        <h2 style={{ margin: 0 }}>
          {t('friend_add')}
        </h2>
        {inviteError && <Alert severity="error">
          {inviteError}
        </Alert>}
        {inviteSent && <Alert severity="success">
          {t('friend_inviteSent')}
        </Alert>}
        <div
          style={{ 
            alignItems: 'center', display: 'flex', flexWrap: 'wrap'
          }}>
          <TextField
            label={t('friend_email')}
            value={email}
            onChange={handleEmailChange}
            style={{ flex: 1 }} />
        </div>
        <div>
          <FormControlLabel
            control={
              <>
                <Checkbox
                  checked={shareTrainings}
                  onChange={() => setShareTrainings(!shareTrainings)}
                  name="checkedB"
                  color="primary"
                />
                <List />
              </>
            }
            label={t('friend_shareTrainings')}
          />
          <FormControlLabel
            control={
              <>
                <Checkbox
                  checked={sharePosition}
                  onChange={() => setSharePosition(!sharePosition)}
                  name="checkedB"
                  color="primary"
                />
                <LocationOn />
              </>
            }
            label={t('friend_shareLocation')}
          />
          <FormControlLabel
            control={
              <>
                <Checkbox
                  checked={shareReports}
                  onChange={() => setShareReports(!shareReports)}
                  name="checkedB"
                  color="primary"
                />
                <Assessment />
              </>
            }
            label={t('friend_shareReports')}
          />
          
          <Button
            variant="contained"
            color="primary"
            onClick={invite}>
            {t('friend_sendInvite')}
          </Button>
        </div>
        {renderAcceptModal()}
      
      </Paper>
    </>
  )
})))

export default Users
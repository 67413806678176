import {
  Button, Modal, Paper 
} from '@material-ui/core'
import React from 'react'
import { withNamespaces } from 'react-i18next'

const ConfirmModal = withNamespaces() ( ({ 
  isOpen, onClose, label, acceptLabel, onAccept, t
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={{
        display:'flex',alignItems:'center',justifyContent:'center' 
      }}>

      <Paper
        style={{
          padding: 20, flexDirection: 'column', display: 'flex', maxWidth: 400
        }}>
        <h2>
          {label}
        </h2>
        <div
          style={{ 
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between' 
          }}>
          <Button
            variant="contained"
            // color="primary"
            onClick={onClose}>
            {t('profile_cancel')}
          </Button>
          <Button
            variant="contained"
            // color="primary"
            onClick={onAccept}>
            {acceptLabel || 'Confirm'}
          </Button>
        </div>
      </Paper>

    </Modal>
  )
})

export default ConfirmModal

import { Tooltip } from '@material-ui/core'
import {
  Assessment,
  List, LocationOn 
} from '@material-ui/icons'
import React from 'react'
import { withNamespaces } from 'react-i18next'

const Permissions = withNamespaces()(({
  data, t 
}) => {
  return (
    <>
      <Tooltip title={t('friend_shareTrainings')}>
        <List
          color={data.shareTrainings ? 'inherit' : 'disabled'}
          style={data.shareTrainings ? {} : { cursor: 'not-allowed' }} />
      </Tooltip>
      <Tooltip title={t('friend_shareLocation')}>
        <LocationOn
          color={data.shareLocation ? 'inherit' : 'disabled'}
          style={data.shareLocation ? {} : { cursor: 'not-allowed' }} />
      </Tooltip>
      <Tooltip title={t('friend_shareReports')}>
        <Assessment
          color={data.shareReports ? 'inherit' : 'disabled'}
          style={data.shareReports ? {} : { cursor: 'not-allowed' }} />
      </Tooltip>
    </>
  )
})

export default Permissions

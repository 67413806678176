
import {
  inject, observer 
} from 'mobx-react'
import React, { useState } from 'react'
import 'leaflet/dist/leaflet.css'
import TrainingItem from '../../components/TrainingItem'
import { getName } from '../../stores/friends'
import { Pagination } from '@material-ui/lab'
import { useHistory } from 'react-router-dom'

const PAGE_SIZE = 25

export const Feed = inject('friends', 'profile')(observer(({
  friends, profile 
}) => {

  const [page, setPage] = useState(1)
  const numberOfPages = Math.ceil(friends.feed.length/PAGE_SIZE)
  const history = useHistory()

  const getGpsData = (training) => {
    const friend = friends.friendByUserId(training.userId)
    if (friend) {
      if (friend.shareLocation) {
      // ask to fetch location if not already fetched
        friends.getTraining(training.id)
      }
    } else {
      // it is the current user, fetch the training
      friends.getTraining(training.id)
    }
  }

  const calculateName = (training) => {
    const friend = friends.friendByUserId(training.userId)
    return getName(friend?.fromPatient) || profile.name || profile.authStore.email
  }


  const handleNavigate = (training) => {
    history.push(`/dash/friends/${training.id}`)
  }

  const handleChange = (e, value) => {
    setPage(value)
  }

  return (
    <>
      {numberOfPages > 1 && <Pagination
        style={{
          alignSelf: 'center', marginBottom: 15
        }}
        count={numberOfPages}
        page={page}
        onChange={handleChange} />}
      {
        friends.feed.slice((page-1)*PAGE_SIZE, (page)*PAGE_SIZE).map((item) => {
          const training = friends.training(item.id)
          getGpsData(item)
          {/* console.log(toJS(item)) */}
          return (
            <TrainingItem
              key={item.id}
              training={training}
              profile={profile}
              name={calculateName(item)}
              onNavigate={handleNavigate} />
          )
        })
      }
      {numberOfPages > 1 && <Pagination
        style={{
          alignSelf: 'center', marginBottom: 15
        }}
        count={numberOfPages}
        page={page}
        onChange={handleChange} />}
    </>
  )
}))

import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper, Tabs, Typography 
} from '@material-ui/core'
import {
  ChevronLeft, Delete, Edit, Clear 
} from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import {
  inject, observer 
} from 'mobx-react'
import React, {
  useEffect, useState 
} from 'react'
import { withNamespaces } from 'react-i18next'
import {
  useHistory, useParams 
} from 'react-router-dom'
import ConfirmModal from '../../components/ConfirmModal'
import InputField from '../../components/InputField'
// import { useParams } from 'react-router-dom'
import StyledTab from '../../components/StyledTab'
import TabPanel from '../../components/TabPanel'
import GroupFeed from './GroupFeed'
import Users from './users'

const Group = withNamespaces()( inject('groups')(observer(({
  groups, t 
}) => {
  const history = useHistory()
  const { groupId } = useParams()
  const groupRelation = groups.group(groupId)
  const group = groupRelation?.group
  const isAdmin = groupRelation.isAdmin

  const [confirmRemoveGroup, setConfirmRemoveGroup] = useState(false)
  const [groupRenamed, setGroupRenamed] = useState(false)
  const [editNameModalOpen, setEditNameModalOpen] = useState(false)
  const [groupName, setGroupName] = useState(group?.name)
  const [confirmLeaveOpen, setConfirmLeaveOpen] = useState(false)
  
  useEffect(() => {
    setGroupName(group?.name)
  }, [group?.name])



  const deleteGroup = () => {
    setConfirmRemoveGroup(false)
    groups.delete(groupId).then(() => {
      history.push('/dash/groups')
    })
  }

  const editGroupName = () => {
    setEditNameModalOpen(false)
    groups.rename(groupId, groupName).then(() => {
      setGroupRenamed(true)
      setTimeout(() => {
        setGroupRenamed(false)
      }, 3000)
    })
  }

  const leaveGroup = () => {
    setConfirmLeaveOpen(false)
    groups.leave(groupId).then(() => {
      history.push('/dash/groups')
    })
  }

  const goBack = () => {
    history.goBack()
  }

  const handleTabChange = (event, value) => {
    groups.selectedTab = value
  }
  const handleName = (event) => {
    let newName = event.target.value
    setGroupName(newName)
  }


  const renderEditGroupModal = () => {
    return <Modal
      open={editNameModalOpen}
      onClose={() => setEditNameModalOpen(false)}
      style={{
        display:'flex',alignItems:'center',justifyContent:'center' 
      }}>

      <Paper
        style={{
          padding: 20, flexDirection: 'column', display: 'flex'
        }}>
        <Typography variant="h5">
          {t('friend_editGroup')}
        </Typography>
        <InputField
          id="standard-basic"
          label={t('friend_groupName')}
          small
          placeholder={t('friend_groupName')}
          onChange={handleName}
          value={groupName}  />
        <Button
          variant="contained"
          color="primary"
          onClick={editGroupName}>
          {t('friend_save')}
        </Button>
      </Paper>

    </Modal>
  }

  return(
    <>
      <Box
        style={{ 
          display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
        }}>
        <Box
          style={{ 
            display: 'flex', flexDirection: 'row', alignItems: 'center' 
          }}>
          <IconButton
            onClick={goBack}
            // variant="contained"
            size="medium"
            style={{
              position: 'relative', top: 0, left: 0 
            }}>
            <ChevronLeft />
          </IconButton>
          <h1 style={{ marginRight: 10 }}>
            {group?.name}
          </h1>
        </Box>
        
        
        <div>
          { isAdmin &&
          <>
            <IconButton onClick={() => setEditNameModalOpen(true)}>
              <Edit  />
            </IconButton>
            <IconButton onClick={() => setConfirmRemoveGroup(true)}>
              <Delete  />
            </IconButton>
          </>
          }
          {!isAdmin && <IconButton onClick={() => setConfirmLeaveOpen(true)}>
            <Clear />
          </IconButton>}
        </div>
      </Box>
      <ConfirmModal
        isOpen={confirmRemoveGroup}
        onClose={() => setConfirmRemoveGroup(false)}
        onAccept={() => deleteGroup()}
        acceptLabel={t('friend_confirm')}
        label={t('friend_wantRemoveGroup', { group: group?.name }) } />
      <ConfirmModal
        isOpen={confirmLeaveOpen}
        onClose={() => setConfirmLeaveOpen(false)}
        onAccept={() => leaveGroup()}
        acceptLabel={t('friend_confirm')}
        label={t('friend_leaveGroup', { name: group?.name })}/>
      {groupRenamed && <Alert severity="success">
        {t('friend_groupRenamed')}
      </Alert>}
      
      <Paper
        style={{
          marginTop: 20, marginBottom: 20 
        }}>
        <Tabs
          value={groups.selectedTab }
          onChange={handleTabChange}  
          indicatorColor="primary"
          aria-label="simple tabs example">
          <StyledTab
            label={t('friend_feed')}/>
          <StyledTab
            label={t('friend_members')}/>
        </Tabs>
      </Paper>
      <TabPanel
        value={groups.selectedTab}
        index={0}>
        <GroupFeed />

      </TabPanel>
      <TabPanel 
        value={groups.selectedTab}
        index={1}>
        <Users />
          
      </TabPanel>
      {renderEditGroupModal()}
    </>
  )
})))

export default Group

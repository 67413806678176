
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'

var firebaseConfig = {
  apiKey: 'AIzaSyCjOOeyh1Mdv15RQeaeTWgpv4gtE5L6XSM',
  authDomain: 'medosport.firebaseapp.com',
  databaseURL: 'https://medosport.firebaseio.com',
  projectId: 'medosport',
  storageBucket: 'medosport.appspot.com',
  messagingSenderId: '64838398141',
  appId: '1:64838398141:web:554182fae407cc5eaf87d4',
  measurementId: 'G-N7ZC3MQMP1',
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()
export default firebase.auth
import React, { useState, } from 'react'
import {
  Modal, Paper, Button, Box, CircularProgress 
} from '@material-ui/core'
import { withNamespaces } from 'react-i18next'
import SelectButtons from './SelectButtons'
import { inject } from 'mobx-react'
import * as Sentry from '@sentry/browser'


const GenerateReportModal = inject('profile')(withNamespaces()( ({ 
  open, onClose, t, profile, userId='', groupId=''
}) => {
  const [period, setperiod] = useState('week')
  const [generating, setgenerating] = useState(false)
  const [completed, setcompleted] = useState(false)
  const [error, seterror] = useState(false)

  const handleGenerate = () => {
    setgenerating(true)
    seterror(false)
    setcompleted(false)

    profile.generateReport(period, userId, groupId).then(() => {
      setgenerating(false)
      setcompleted(true)
    }).catch((err) => {
      if (err.status === 401) {
        // present error for permission denied
        seterror(t('report_error_permission_denied'))
      }
      Sentry.captureException(err)
      setgenerating(false)
      seterror(t('report_error'))
    })
  }
  
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ 
        display:'flex',alignItems:'center',justifyContent:'center',
      }}
    >
      <Paper
        style={{
          padding: 20, flexDirection: 'column', display: 'flex' 
        }}
        
      >
        <h1>
          {t('report_title')}
        </h1>
        <Box  pb={3}>
          <SelectButtons
            data={[{
              label: t('report_duration_day'), key: 'day' 
            }, {
              label: t('report_duration_week'), key: 'week'
            }, {
              label: t('report_duration_month'), key: 'month' 
            }, {
              label: t('report_duration_3months'), key: 'quarter' 
            }, {
              label: t('report_duration_6months'), key: 'halfYear'
            }]}
            onChange={(period) => setperiod(period)}
            selected={period} />
        </Box>
        {generating &&  <div
          style={{ 
            justifyContent: 'center', alignItems: 'center', display: 'flex' 
          }}>
          <CircularProgress />
        </div>}
        {completed && <p>
          {t('report_generated')}
        </p>}
        {error && <p>
          {error}
        </p>}
        <Button
          variant='contained'
          color="primary"
          onClick={handleGenerate}>
          {t('report_generate')}
        </Button>
        {/*         
        <InputField
          id="standard-basic"
          label={t('profile_length')}
          small
          placeholder={t('profile_length')}
          onChange={handleLength}
          value={length}  />
        <InputField
          id="standard-basic"
          label={t('profile_weight')}
          small
          placeholder={t('profile_weight')}
          onChange={handleWeight}
          value={weight}  />
        <div>
          <KeyboardDatePicker
            style={{ display: 'flex' }}
            clearable
            value={birthday}
            placeholder="10/10/2018"
            onChange={date => handleBirthday(date)}
            format="yyyy-MM-dd"
          />
        </div>
        <Box py={3}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={gender}
            onChange={handleGender}
            style={{ display: 'flex' }}
          >
            <MenuItem value='M'>
              {t('profile_male')}
            </MenuItem>
            <MenuItem value='F'>
              {t('profile_female')}
            </MenuItem>
            <MenuItem value='N'>
              {t('profile_otherGender')}
            </MenuItem>
          </Select>
        </Box>
        <h3 style={{ marginBottom: 5 }}>
          {t('profile_units')}
        </h3>
        <Box  pb={1}>
          <SelectButtons
            data={[{
              label: t('profile_metric'), key: 'METRIC' 
            }, {
              label: t('profile_imperial'), key: 'IMPERIAL' 
            }]}
            onChange={(units) => setUnits(units)}
            selected={units} />
        </Box>
        <h3 style={{ marginBottom: 5 }}>
          {t('profile_timeFormat')}
        </h3>
        <Box  pb={1}>
          <SelectButtons
            data={[{
              label: t('profile_24hour'), key: 'HOUR24' 
            }, {
              label: t('profile_12hour'), key: 'HOUR12' 
            }]}
            onChange={(format) => setTimeFormat(format)}
            selected={timeFormat} />
        </Box>
        <h3 style={{ marginBottom: 5 }}>
          {t('profile_dateFormat')}
        </h3>
        <Box  pb={3}>
          <SelectButtons
            data={[{
              label: '2020-12-31', key: 'YMD' 
            }, {
              label: '31-12-2020', key: 'DMY' 
            }, {
              label: '12-31-2020', key: 'MDY' 
            }]}
            onChange={(format) => setDateFormat(format)}
            selected={dateFormat} />
        </Box>
        <Button
          variant='contained'
          color="primary"
          style={{ marginRight: 10 }}
          onClick={handleSave}>
          {t('profile_save')}
        </Button>
        <Button
          variant='contained'
          onClick={onClose}>
          {t('profile_cancel')}
        </Button> */}
      </Paper>
    </Modal>
  )
}))

export default GenerateReportModal
import React from 'react'

var ActivityTypeIcon = ({
  type, size=50, disabled=false
}) => {
  let icon = null
  let color = ''
  if (type === 'WALK') {
    color = '#B9937C'
    icon =  <img
      style={{
        width: size, height: size*0.8, objectFit: 'contain' 
      }}
      src={require('../assets/icons/walk_brown.png')} />
  } else {
    color = '#905449'
    icon = <img
      style={{
        width: size, height: size*0.8, objectFit: 'contain' 
      }}
      src={require('../assets/icons/run_brown.png')} />
  }

  return (
    <div
      style={{
        // padding: 5, backgroundColor: color, borderRadius: 5, paddingBottom: 1,
      }}>
      {icon}
    </div>
  )
}

export default ActivityTypeIcon

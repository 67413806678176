import {
  Box,
  Button,
  Checkbox, FormControlLabel, IconButton, Paper 
} from '@material-ui/core'
import {
  Assessment,
  ChevronLeft, Delete, List, LocationOn 
} from '@material-ui/icons'
import {
  inject, observer 
} from 'mobx-react'
import React, { useState } from 'react'
import { withNamespaces } from 'react-i18next'
import {
  useHistory, useParams 
} from 'react-router-dom'
import ConfirmModal from '../../components/ConfirmModal'
import GenerateReportModal from '../../components/GenerateReportModal'
import TrainingItem from '../../components/TrainingItem'
import { getName } from '../../stores/friends'

const Friend = withNamespaces()( inject('friends', 'profile')(observer(({
  friends, t, profile
}) => {

  let { friendId } = useParams()
  const history = useHistory()
  const [confirmRemoveFriend, setConfirmRemoveFriend] = useState(false)
  const [showReport, setShowReport] = useState(false)

  const friend = friends.friend(friendId)
  const whatIShare = friends.whatIShare(friend?.fromPatient?.user?.id)
  const friendFeed = friends.friendFeed(friend.fromPatient?.user?.id)

  const handleLocationChange = () => {
    friends.updateWhatIShare(friend?.fromPatient?.user?.id, whatIShare.shareTrainings, !whatIShare.shareLocation, whatIShare.shareReports)
  }

  const handleTriningsChange = () => {
    friends.updateWhatIShare(friend?.fromPatient?.user?.id, !whatIShare.shareTrainings, whatIShare.shareLocation, whatIShare.shareReports)
  }
  const handleReportChange = () => {
    friends.updateWhatIShare(friend?.fromPatient?.user?.id, whatIShare.shareTrainings, whatIShare.shareLocation, !whatIShare.shareReports)
  }

  const goBack = () => {
    history.goBack()
  }

  const removeFriend = () => {
    setConfirmRemoveFriend(false)
    friends.removeFriend(friend?.fromPatient?.user?.id).then(() => {
      this.goBack()
    })
  }

  const generateReport = () => {
    setShowReport(true)
  }

  // const getWhatIShare = async () => {
  //   if (!friend) {
  //     return
  //   }
  //   const meFriend = await friends.getWhatIShare(friend?.toPatient?.user?.id)
  //   console.log('meFriend', meFriend)
  // }

  const renderTrainings = () => {
    if (friendFeed.length <= 0) {
      return null
    }

    return (
      <>
        <h2>
          {t('trainings_title')}
        </h2>
        {
          friendFeed.map((item) => {
            const training = friends.training(item.id)
            return (
            
              <TrainingItem
                key={item.id}
                training={training}
                profile={profile}
                friend={friends.friendByUserId(item.userId)} />
            )
          })
        }
      </>
    )
  }

  return (
    <>
      <Box
        style={{ 
          display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
        }}>
        <Box
          style={{ 
            display: 'flex', flexDirection: 'row', alignItems: 'center' 
          }}>
          <IconButton
            onClick={goBack}
            // variant="contained"
            size="medium"
            style={{
              position: 'relative', top: 0, left: 0 
            }}>
            <ChevronLeft />
          </IconButton>
          <h1 style={{ marginRight: 10 }}>
            {getName( friend?.fromPatient)}
          </h1>
        </Box>
          
        <div>
          <Button
            onClick={generateReport}
            variant="contained"
            disabled={!friend?.shareReports}
            color="primary">
            {t('report_button')}
          </Button>
          <IconButton onClick={() => setConfirmRemoveFriend(true)}>
            <Delete fontSize="large" />
          </IconButton>
        </div>
      </Box>
      <ConfirmModal
        isOpen={confirmRemoveFriend}
        onClose={() => setConfirmRemoveFriend(false)}
        onAccept={() => removeFriend()}
        acceptLabel={t('friend_confirm')}
        label={t('friend_wantRemoveFriend', { email: getName( friend?.fromPatient) })}/>
      <Paper style={{ padding: 20 }}>
        <h3>
          {t('friend_iShare')}
        </h3>
        <FormControlLabel
          control={
            <>
              <Checkbox
                checked={whatIShare.shareTrainings || false}
                onChange={handleTriningsChange}
                name="checkedB"
                color="primary"
              />
              <List />
            </>
          }
          label=""
        />
        <FormControlLabel
          control={
            <>
              <Checkbox
                checked={whatIShare.shareLocation || false}
                onChange={handleLocationChange}
                name="checkedB"
                color="primary"
              />
              <LocationOn />
            </>
          }
          label=""
        />
        <FormControlLabel
          control={
            <>
              <Checkbox
                checked={whatIShare.shareReports || false}
                onChange={handleReportChange}
                name="checkedB"
                color="primary"
              />
              <Assessment />
            </>
          }
          label=""
        />
        <h3>
          {t('friend_sharedByXToMe', { email: getName(friend?.fromPatient) })}
        </h3>
        <FormControlLabel
          disabled
          control={
            <>
              <Checkbox
                disabled
                checked={friend?.shareTrainings || false}
                // onChange={() => setShareTrainings(!shareTrainings)}
                name="checkedB"
                color="primary"
              />

              <List />
            </>
          }
          label=""
        />
        <FormControlLabel
          disabled
          control={
            <>
              <Checkbox
                disabled
                checked={friend?.shareLocation || false}
                // onChange={() => setSharePosition(!sharePosition)}
                name="checkedB"
                color="primary"
              />

              <LocationOn />
            </>
          }
          label=""
        />
        <FormControlLabel
          control={
            <>
              <Checkbox
                disabled
                checked={friend?.shareReports || false}
                name="checkedB"
                color="primary"
              />
              <Assessment />
            </>
          }
          label=""
        />
        <div
          style={{ 
            flexDirection: 'row', display: 'flex', alignItems: 'center'
          }}>
          <p style={{ marginRight: 10 }}>
            {t('friend_trainings')}
            :
          </p>
          <List  style={{ marginRight: 10 }} />
          <p style={{ marginRight: 10 }}>
            {t('friend_locationData')}
            :
          </p>
          <LocationOn  style={{ marginRight: 10 }} />
          <p style={{ marginRight: 10 }}>
            {t('friend_shareReports')}
            :
          </p>
          <Assessment  style={{ marginRight: 10 }} />
        </div>
      
      </Paper>
      {renderTrainings()}
      <GenerateReportModal
        open={showReport}
        onClose={() => setShowReport(false)}
        userId={friend.fromPatient?.user?.id}/>
    </>
  )
})))

export default Friend

import {
  inject, observer 
} from 'mobx-react'
import React from 'react'
import {
  useHistory, useParams 
} from 'react-router-dom'
import TrainingComponent from '../../components/Training'

export const FriendTraining = inject('friends')(observer(({ friends }) => {

  let { trainingId } = useParams()
  const history = useHistory()
  const training = friends.training(trainingId)
  const friend = friends.friendByUserId(training.userId)

  const goBack = () => {
    history.goBack()
  }

  return (
    <TrainingComponent
      training={training}
      goBack={goBack}
      userId={training.userId}
      reportEnabled={friend?.shareReports} />
  )
}))

import React from 'react'
import {
  inject, observer
} from 'mobx-react'
import {
  Grid, Box
} from '@material-ui/core'
import {
  formatDuration, formatSpeed, formatPercentage, formatSteps
} from '../helper/formatting'
import { SpeedFormats } from '../helper/constants'
import DashboardReport from '../components/Dashboard/DashboardReport'
import { withNamespaces } from 'react-i18next'
import DashboardBase from '../components/Dashboard/DashboardBase'
import DashboardTrainings from '../components/Dashboard/DashboardTrainings'
import DashboardCalendar from '../components/Dashboard/DashboardCalendar'
import {
  activeTimeReducer, speedReducer, stanceTimeLeftReducer, swingTimeLeftReducer, stepsReducer, asymmetryReducer, best6Reducer, trainingCountReducer
} from '../helper/reducer'

var Dashboard = withNamespaces()( inject('auth', 'trainings', 'profile', 'goal')(observer((props) => {
  const {
    trainings,
    profile,
    t,
    goal
  } = props

  trainings.fetchTrainings()
  goal.fetchGoals()

  return(
    <div>
      <Grid
        container
        spacing={3}>
        <DashboardTrainings
          t={t}
          trainings={trainings.trainingsByDaysAgo}
          timeFormat={profile.timeFormat}
          dateFormat={profile.dateFormat}
          numberOfTrainingsGoal={goal.getGoal('numberOfTrainings', 'DAY')}
          activeTimeGoal={goal.getGoal('activeTime', 'DAY')}
          distanceGoal={goal.getGoal('DISTANCE', 'DAY')}/>
        <DashboardReport
          label={t('training_activeTime')}
          dateFormat={profile.timeFormat}
          t={t}
          formatter={(val) => formatDuration(val/1000)}
          datetime
          reducer={activeTimeReducer}
          trainingsByDaysAgo={trainings.trainingsByDaysAgo}
          showAverage
          goals={goal.getGoals('activeTime').map((item) => ({
            ...item, level: item.level*1000
          }))}
        />
        <DashboardReport
          label={t('training_steps')}
          dateFormat={profile.timeFormat}
          t={t}
          formatter={(val) => formatSteps(val)}
          datetime
          reducer={stepsReducer}
          trainingsByDaysAgo={trainings.trainingsByDaysAgo}
          showAverage
          goals={goal.getGoals('steps')}
        />
        <DashboardReport
          label={t('graph_title_speed')}
          timeFormat={profile.timeFormat}
          dateFormat={profile.dateFormat}
          t={t}
          formatter={(val) => formatSpeed(val/3.6, SpeedFormats.KILOMETER_HOUR, profile.units)}
          reducer={speedReducer}
          trainingsByDaysAgo={trainings.trainingsByDaysAgo}
          type="line"
        />
        <DashboardReport
          label={t('graph_title_count')}
          timeFormat={profile.timeFormat}
          dateFormat={profile.dateFormat}
          t={t}
          formatter={(val) => val.toString()}
          allowDecimals={false}
          reducer={trainingCountReducer}
          trainingsByDaysAgo={trainings.trainingsByDaysAgo}
          goals={goal.getGoals('numberOfTrainings').map((item) => ({
            ...item, level: item.level
          }))}
        />
        <DashboardReport
          label={t('graph_title_asymmetry')}
          timeFormat={profile.timeFormat}
          dateFormat={profile.dateFormat}
          t={t}
          formatter={(val) => formatPercentage(val, true)}
          reducer={asymmetryReducer}
          trainingsByDaysAgo={trainings.trainingsByDaysAgo}
          type="line"
          walkOnly
        />
        <DashboardReport
          label={t('graph_title_best6')}
          timeFormat={profile.timeFormat}
          dateFormat={profile.dateFormat}
          t={t}
          formatter={(val) => val.toFixed(0) + 'm'}
          reducer={best6Reducer}
          trainingsByDaysAgo={trainings.trainingsByDaysAgo}
          type="line"
          walkOnly
        />
        <DashboardCalendar
          t={t}
          trainings={trainings.list} />
      </Grid>
    </div>
  )
})))

export default Dashboard

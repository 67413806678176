import React from 'react'
import './App.scss'
import Router from './Router'
import { Provider } from 'mobx-react'
import stores from './stores'
// import 'firebase'
import * as Sentry from '@sentry/browser'
import dotenv from 'dotenv'
import {
  MuiThemeProvider, unstable_createMuiStrictModeTheme
} from '@material-ui/core'
import 'mobx-react-lite/batchingForReactDom'
// import { green } from '@material-ui/core/colors'
import { MuiPickersUtilsProvider, } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import svLocale from 'date-fns/locale/sv'
import enLocale from 'date-fns/locale/en-US'
import { hot } from 'react-hot-loader/root'
import i18n from './i18n'


const localeMap = {
  en: enLocale,
  sv: svLocale,
}

dotenv.config()

// sentry error tracking
/* eslint no-undef: 0 */
if (process.env.NODE_ENV !== 'development') {
  console.log('Sentry enabled')
  
  Sentry.init({ dsn: 'https://9a14ca7e9d644af8a1c36cec94eed3f7@o413748.ingest.sentry.io/5301557' })
}

const theme = unstable_createMuiStrictModeTheme({
  palette: {
    primary: { main: '#1a1e31' },
    secondary: { main:'#FCDCBD' }, 
  },
  typography: { fontFamily: [
    'Noto Sans',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(',') }
})

function App() {
  

  return (
    <Provider {...stores}>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={localeMap[i18n.language]}>
          <Router />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </Provider>
  )
}

export default hot(App)
